import { createSelector } from '@reduxjs/toolkit'
import { EXTERNAL_IDENTIFIER_SOURCE } from '../../libs/utils'
import { RootState } from '../../model/model'
import { Practice, PracticesStore } from '../../model/practice'
import { selectStoreId } from '../app/selectors'
import { selectExam } from '../exams/selectors'

export const selectPractices = (state: RootState): Practice[] =>
	state.practices || []

export const selectPractice =
	(practiceId: string) =>
	(state: RootState): Practice | undefined => {
		return state.practices?.find(({ _id }) => _id === practiceId)
	}

export const selectPracticeByExternalId =
	(externalId: string) =>
	(state: RootState): Practice | undefined => {
		return state.practices?.find(({ stores }) =>
			stores.some(
				s =>
					s.externalIds &&
					(s.externalIds.find(id => id.source === EXTERNAL_IDENTIFIER_SOURCE)
						?.code ||
						'' === externalId),
			),
		)
	}

export const selectStorePractice =
	(storeId: string) =>
	(state: RootState): Practice[] => {
		return (
			state.practices?.filter(p => p.stores.find(s => s._id === storeId)) || []
		)
	}

export const selectPracticesStoreByStoreId =
	(storeId: string) =>
	(state: RootState): PracticesStore | undefined => {
		const practice = state.practices?.find(p =>
			p.stores.find(s => s._id === storeId),
		)
		return practice && practice.stores.find(s => s._id === storeId)
	}

export const selectPracticeByStoreId =
	(storeId: string) =>
	(state: RootState): Practice | undefined => {
		return state.practices?.find(p => p.stores.find(s => s._id === storeId))
	}

export const selectPracticeById =
	(practiceId: string) =>
	(state: RootState): Practice | undefined => {
		return state.practices?.find(p => p._id === practiceId)
	}

export const selectPracticeByStoresIds =
	(storeIds: string[]) =>
	(state: RootState): Practice[] | undefined => {
		return state.practices?.filter(p =>
			p.stores.find(s => storeIds.includes(s._id)),
		)
	}

export const selectPracticeByExamId =
	(examId: string) =>
	(state: RootState): Practice | undefined => {
		const exam = selectExam(examId)(state)
		if (!exam) return undefined
		return state.practices?.find(p =>
			p.stores.find(s => s._id === exam.store._id),
		)
	}

export const selectAllowEditExamsWithinPractice = (examId: string) =>
	createSelector(
		selectPracticeByExamId(examId),
		practice => practice?.modifyExamsWithinPractice,
	)

export const selectCurrentPractice = (state: RootState) => {
	const storeId = selectStoreId(state)

	const practice = selectPracticeByStoreId(storeId)(state)

	return practice
}
