import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '../../styleguide/buttons/IconButton'
import CloseIcon from '../../styleguide/icons/CloseIcon'
import Snackbar from '../../styleguide/Snackbar'
import { Notification as NotificationModel } from '../../model/model'
import { playDing } from './utils/play-ding'

interface NotificationProps {
	notification: NotificationModel
	onClose: () => void
}

const Notification: React.FC<NotificationProps> = ({
	notification: {
		_id,
		colored,
		playSound,
		soundOnly,
		messageIsLabelKey,
		message,
	},
	onClose,
}) => {
	const { t } = useTranslation()

	useEffect(() => {
		if (playSound) {
			playDing()
		}
	}, [playSound])

	// Use soundOnly flag to conditionally opt-out message box
	if (soundOnly) {
		return null
	}

	return (
		<Snackbar
			message={messageIsLabelKey ? t(message) : message}
			$colored={colored}
			action={
				_id !== 'offline-notification' ? (
					<>
						<IconButton
							size="small"
							aria-label="close"
							data-testid="close-button"
							color="inherit"
							onClick={onClose}
						>
							<CloseIcon />
						</IconButton>
					</>
				) : undefined
			}
			key={_id}
			open={true}
		/>
	)
}

export default Notification
