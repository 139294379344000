import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'

import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import { CDADownloadQueryArg, CDAFilterParams } from './model'
import { CDAResponse } from '../model/model'
import {
	CCDValidator,
	CDAModel,
} from '../components/ExamSummary/ExamSummaryCDA/model'

export const cdaApi = createApi({
	reducerPath: ServicesReducerPath.CdaApi,
	tagTypes: [ServicesApiTag.Cda],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getExamData: builder.query<{}, CDAFilterParams>({
			query: ({ examId, type, sections }: CDAFilterParams) => ({
				url: `${ServiceBaseUrl.ExamsV3}/${examId}/cda?sections=${sections.join(
					',',
				)}&type=${type}`,
			}),
			extraOptions: { maxRetries: 0 },
		}),
		getAllCDAs: builder.query<CDAResponse, string>({
			query: examId => ({
				url: `${ServiceBaseUrl.CDAV3}/${examId}`,
			}),
			extraOptions: { maxRetries: 0 },
			providesTags: [ServicesApiTag.Cda],
		}),
		addCDA: builder.mutation<CDAModel | CCDValidator, CDAModel>({
			query: body => ({
				url: `${ServiceBaseUrl.CDAV3}`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				if (result && (result as CCDValidator).resultsMetaData) {
					return []
				}
				return [ServicesApiTag.Cda]
			},
		}),
		downloadCda: builder.query<string, CDADownloadQueryArg>({
			query: ({ cdaId }: CDADownloadQueryArg) => ({
				url: `${ServiceBaseUrl.CDAV3}/download/${cdaId}`,
				responseHandler: async (res: Response) =>
					window.URL.createObjectURL(await res.blob()),
			}),
			extraOptions: { maxRetries: 0 },
		}),
	}),
})

export const {
	useLazyGetExamDataQuery,
	useGetAllCDAsQuery,
	useAddCDAMutation,
	useLazyDownloadCdaQuery,
} = cdaApi
