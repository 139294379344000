import React from 'react'

import { SvgProps } from '../../model/types'

const CollapseIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18.575"
			height="17.021"
			fill="currentColor"
			viewBox="0 0 4.915 4.503"
			className={props.flip ? 'invert' : ''}
		>
			<path
				className={'up'}
				d="M.189 4.53a.092.092 0 00.069-.025l1.638-1.499v.715c0 .046.037.084.083.084h.144a.083.083 0 00.082-.084V2.709c0-.007-.002-.012-.003-.018a.103.103 0 00-.023-.075l-.074-.09a.09.09 0 00-.074-.034.068.068 0 00-.023-.005h-.994a.083.083 0 00-.082.085v.146c0 .047.037.084.082.084h.644L.06 4.265a.103.103 0 00-.01.14l.074.09c.017.022.04.033.065.035z"
				fillRule="evenodd"
			/>
			<path
				className={'down'}
				d="M4.75 0a.094.094 0 00-.069.025L3.022 1.504V.799A.083.083 0 002.94.716h-.146a.083.083 0 00-.083.083v.999c0 .006.002.012.003.017a.1.1 0 00.023.075l.075.089c.02.023.047.033.075.033a.07.07 0 00.024.004h1.005a.083.083 0 00.083-.083v-.145a.083.083 0 00-.083-.083h-.651L4.88.262a.1.1 0 00.01-.138l-.075-.09A.094.094 0 004.751 0z"
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default CollapseIcon
