import React, { useMemo } from 'react'
import { selectStoreId } from '../features/app/selectors'
import { selectUsername, selectUserRole } from '../features/auth/selectors'
import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import examsActions from '../features/exams/actions'
import { selectExam } from '../features/exams/selectors'
import prescriptionsActions from '../features/prescriptions/actions'
import { selectPrescriptionByExamId } from '../features/prescriptions/selectors'
import { selectContactLensesSignature } from '../features/signRequests/selectors'
import { Dialog, QuitWithTrialType } from '../model/dialog'
import { useTeloNavigate } from '../routing/teloNavigate'
import { useTeloDispatch, useTeloSelector } from '../store'
import { getUserDashboard } from './../libs/auth'
import QuitExamDialog from './Prescription/RX/QuitExamDialog'

const isQuitWithTrialDialog = (dialog: Dialog): dialog is QuitWithTrialType =>
	typeof dialog === 'object' && 'examId' in dialog

const QuitWithPrescribedDialog = () => {
	const dispatch = useTeloDispatch()
	const navigate = useTeloNavigate()
	const dialog = useTeloSelector(selectDialog)
	const storeId = useTeloSelector(selectStoreId)
	const username = useTeloSelector(selectUsername)
	const open = isQuitWithTrialDialog(dialog)
	const exam = useTeloSelector(
		selectExam(isQuitWithTrialDialog(dialog) ? dialog.examId : ''),
	)
	const examId = (dialog as QuitWithTrialType).examId
	const userRole = useTeloSelector(selectUserRole)
	const prescription = useTeloSelector(selectPrescriptionByExamId(examId))
	const contactLensesSignature = useTeloSelector(
		selectContactLensesSignature(examId),
	)

	const redirectUri = useMemo(() => {
		const uri = getUserDashboard(userRole, storeId)
		return uri
	}, [userRole, storeId])

	const handleSubmitPrescribedCl = async (generateChart?: boolean) => {
		if (!isQuitWithTrialDialog(dialog) || !exam) {
			return
		}

		await dispatch(
			examsActions.setExamStatus({
				id: exam._id,
				status: 'LensTrialQuit',
				username,
			}),
		)

		prescription &&
			(await dispatch(
				prescriptionsActions.updatePrescription({
					examId,
					prescriptionId: prescription._id,
					prescriptionData: prescription?.data,
					contactLensesSignature: contactLensesSignature?.data,
					send: true,
				}),
			))

		generateChart && (await dispatch(examsActions.generateChart(exam._id)))

		dispatch(dialogActions.closeDialog())
		navigate(redirectUri)
	}

	return !isQuitWithTrialDialog(dialog) ? null : (
		<QuitExamDialog
			examId={dialog.examId}
			open={open}
			closeDialog={() => dispatch(dialogActions.closeDialog())}
			confirmDialog={handleSubmitPrescribedCl}
			isPrescribedCl
		/>
	)
}

export default QuitWithPrescribedDialog
