import { pxToRem } from '../libs/style'
import theme from './theme'
import { css } from 'styled-components'

export const defaultInputStyles = css<{ value?: unknown }>`
	.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
		border-color: ${({ value }) =>
			value ? theme.palette.primary.main : theme.palette.grey[600]};
	}

	// add extra border for focused input with error
	.MuiInputBase-root.Mui-error.Mui-focused::before {
		content: ' ';
		position: absolute;
		inset: -4px;
		border: 2px solid ${theme.palette.secondary.light};
		border-radius: ${pxToRem(14)}rem;
	}

	.MuiOutlinedInput-root:hover fieldset {
		border-color: ${theme.palette.violet[800]};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.secondary.light};
	}

	.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.error.light};
	}

	.MuiFormHelperText-root {
		margin-left: 0;
		margin-right: 0;
	}

	.MuiFormLabel-root.MuiInputLabel-root.Mui-error {
		margin: 2px -5px -2px;
		padding: 0 10px;
	}

	.MuiInputBase-input {
		z-index: 1;
	}

	.MuiInputBase-input.MuiOutlinedInput-input {
		&::placeholder {
			color: ${theme.palette.greyCustom.main};
			opacity: 1;
			&::first-letter {
				text-transform: uppercase;
			}
		}

		&.Mui-disabled {
			-webkit-text-fill-color: ${theme.palette.greyCustom.main};
		}
	}
`
