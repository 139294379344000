import { Dispatch } from '@reduxjs/toolkit'
import i18n from 'i18next'
import { differenceWith, isEqual } from 'lodash'

import {
	changeExamStatusApi,
	ChangeExamStatusApiParams,
	deleteExamAttachmentApi,
	deleteExamLinkApi,
	deleteExamProcedureImageApi,
	deleteExamScreeningProcedureAttachmentApi,
	deleteExamScreeningProcedureImageApi,
	deleteOrderResultApi,
	downloadExamAttachmentApi,
	downloadOrderResultAttachmentApi,
	exportFromExamApi,
	getAppointmentApi,
	getDashboardExams,
	getExam,
	getExamCodesApi,
	getExamDocumentsFromReferenceApi,
	getExams,
	getExamWarningsApi,
	getInstrumentDataApi,
	getOtherTestImages,
	refreshDocumentsApi,
	removeProcedureApi,
	removeScreeningProcedureApi,
	retrieveEHRExport,
	saveCLTrialDataApi,
	saveExamLinkApi,
	saveInstrumentDataApi,
	saveManualDataApi,
	saveOrderResultApi,
	sendReferralDocumentApi,
	updateAttachmentApi,
	updateDif,
	updateDoctorNotes,
	updateExamApi,
	updateEyeHealthDocApi,
	updateLinkApi,
	uploadExamAttachmentApi,
	uploadExamProcedureImageApi,
	uploadExamScreeningProcedureAttachmentApi,
	uploadExamScreeningProcedureImageApi,
	uploadOrderAttachmentApi,
	uploadOrderResultImageApi,
	upsertMedicalReportApi,
	upsertOrderReferraApi,
	upsertOrderResultApi,
	upsertReferralFormApi,
	upsertSurgeryDocumentApi,
} from '../../apiCalls'
import {
	AssessmentPlanV2,
	Goal,
} from '../../components/AssessmentAndPlan/types'
import { DifForm } from '../../components/DigitalIntakeForm/types'
import { setMedicationsAPI } from '../../components/Prescription/NewCrop/api'
import { decodeInstrumentData } from '../../decoders/instrumentData'
import {
	encodeAutorefractionData,
	encodeKeratometerData,
	encodeLensometerData,
	encodeOCTData,
	encodePhoropterData,
	encodeRetinalImageData,
	encodeSlitLampData,
	encodeTonometerData,
	encodeVisualFieldsData,
} from '../../encoders/instrumentData'
import {
	examEndedStatus,
	getBlankPhoropterData,
	isExam,
} from '../../libs/exams'
import { exportSucceededNotification } from '../../libs/exportData'
import { formatName } from '../../libs/localization'
import { covertClDataForLensData } from '../../libs/prescriptions'
import { generateUID } from '../../libs/uuid'
import { AppointmentType } from '../../model/appointment'
import { ChiefComplaint } from '../../model/chiefComplaint'
import { Code } from '../../model/coding'
import {
	AuxiliaryData,
	ContactLenses,
	ContactLensesHistory,
	DoctorNotes,
	DoctorNotesKeys,
	ExamApi,
	ExamBase,
	ExamDocument,
	ExamLink,
	ExamMedicalReport,
	ExamPages,
	ExamQuery,
	ExamSurgery,
	ExportToolData,
	EyeHealth,
	ImplantableDevice,
	NextEyeExam,
	Order,
	OrderResult,
	OtherTest,
	Procedure,
	ReferralFormData,
	ScreeningProcedure,
	UpdateLocalPretestDataType,
	VisionTherapy,
} from '../../model/exam'
import { HealthConcern } from '../../model/healthConcern'
import {
	AutorefractionData,
	KeratometerData,
	LensmeterData,
	OCTData,
	PhoropterData,
	RetinalImageData,
	SlitLampData,
	TonometerData,
} from '../../model/instruments'
import {
	InstrumentApiType,
	InstrumentDataApi,
	InstrumentErrorApi,
	InstrumentType,
	SaveInstrumentDataResponse,
} from '../../model/instrumentsApi'
import { ManualDataType, SingleManualData } from '../../model/manual'
import { Id } from '../../model/model'
import { PrescriptionContactLenses } from '../../model/prescription'
import { Signature } from '../../model/signRequests'
import { worklistApi, WORKLIST_CACHE_TAG } from '../../services/worklist'
import {
	AppThunk,
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
} from '../../store'
import { selectUsername } from '../auth/selectors'
import azureCommunicationActions from '../azureCommunication/actions'
import { selectAzureCommunicationExam } from '../azureCommunication/selectors'
import notificationsActions from '../notifications/actions'
import prescriptionsActions from '../prescriptions/actions'
import { selectPrescriptionByExamId } from '../prescriptions/selectors'
import signRequestsActions from '../signRequests/actions'
import storesActions from '../stores/actions'
import { selectIsLocalTechnician } from '../users/selectors'

import chatbotActions from '../chatbot/actions'
import { getSaveDoctorVisualFieldsDataPayload } from './helpers'
import { SaveDoctorVisualFieldsDataPayload } from './model'
import { selectExam, selectExamStatus } from './selectors'
import { slice } from './slice'

const fetchInstrumentData =
	(examId: string, instrumentType: InstrumentType): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) =>
		getInstrumentDataApi(examId, instrumentType).then(res => {
			if (!res || !res.examData) {
				return
			}
			const state = getState()
			const exam = selectExam(examId)(state)
			const instrumentHasError = (
				examData: InstrumentDataApi,
			): examData is InstrumentErrorApi =>
				'message' in examData && 'code' in examData
			if (instrumentHasError(res.examData)) {
				const mapInstrumentTypeToInstrumentName = {
					LM: i18n.t('intruments.LM'),
					AR: i18n.t('intruments.AR'),
					KM: i18n.t('intruments.KM'),
					NT: i18n.t('intruments.NT'),
					PH: i18n.t('intruments.PH'),
					OCT: i18n.t('intruments.OCT'),
					VF: i18n.t('intruments.VF'),
					SL: i18n.t('intruments.SL'),
					RI: i18n.t('intruments.RI'),
				}
				return dispatch(
					notificationsActions.addNotification({
						type: 'error',
						message: i18n
							.t('errors.instrumentError')
							.replace('%%1', mapInstrumentTypeToInstrumentName[instrumentType])
							.replace('%%2', res.examData.message)
							.replace('%%3', res.examData.code),
						autoClose: false,
					}),
				)
			}

			if (instrumentType === 'PH') {
				const oldPhData = exam?.preTest.instruments.phoropter
				const prescription = selectPrescriptionByExamId(examId)(state)

				if (
					exam &&
					prescription &&
					!examEndedStatus.includes(exam.status) &&
					!isEqual(oldPhData, decodeInstrumentData(res, 'PH'))
				) {
					dispatch(
						prescriptionsActions.updatePrescription({
							examId,
							prescriptionData: prescription.data,
							prescriptionId: prescription._id,
							send: false,
						}),
					)
				}
			}

			dispatch(
				slice.actions._setInstrumentData({
					examId,
					instrumentData: res.examData,
					instrumentType,
					examStatus: res.examStatus,
				}),
			)
		})

const fetchExams =
	(params?: ExamQuery): AppThunk =>
	(dispatch, _getState) =>
		getExams({
			...(params || {}),
		}).then(exams => {
			dispatch(examsActions._loadExams(exams || []))
		})

const fetchDashboardExams =
	(params?: ExamQuery): AppThunk =>
	(dispatch: TeloDispatch) =>
		getDashboardExams({
			...(params || {}),
		}).then(exams => {
			dispatch(examsActions._loadExams(exams || []))
		})

const fetchExam =
	(
		examId: Id,
		notBlockingError403 = false,
	): AppThunkPromise<ExamApi | undefined> =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		return getExam(examId, notBlockingError403).then(exam => {
			if (!exam) {
				dispatch(examsActions._removeExam(examId))
				return
			}

			const state = getState()
			const isLocalTechnician = selectIsLocalTechnician(state)
			const prevStatus = selectExamStatus(examId)(state)
			const examNotPickedUpByDoctor =
				prevStatus === 'DoctorModeSelected' && exam.status === 'Waiting'
			const azureCallExam = selectAzureCommunicationExam(state)

			if (isLocalTechnician) {
				//If exam
				if (examNotPickedUpByDoctor) {
					getAppointmentApi(exam.appointmentId).then(appointment => {
						dispatch(
							notificationsActions.addNotification({
								id: `exam-gone-in-waiting-${examId}`,
								type: 'info',
								message: appointment
									? i18n.t('exam.examNotPicketUpByDoctorAlert', {
											patientName: formatName(appointment.patient),
									  })
									: i18n.t('exam.examNotPicketUpByDoctorAlertGeneric'),
								autoClose: false,
							}),
						)
					})
				}

				// These are the only states that doctor can select from DDL status select (with pause) OR exam is finished
				if (
					(['Waiting', 'Interrupted'].includes(exam.status) &&
						azureCallExam === examId) ||
					(azureCallExam &&
						azureCallExam === examId &&
						examEndedStatus.includes(exam.status))
				) {
					dispatch(azureCommunicationActions.resetAzureCommunication())
					dispatch(storesActions.getStoreAction(exam.store._id))
				}
			}

			dispatch(examsActions._loadExam(exam))
			isExam(exam) &&
				exam.store &&
				dispatch(storesActions._setStore(exam.store))

			return exam
		})
	}

const fetchExamByAppointmentId =
	(appointmentId: Id, dateFrom: string): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		getExams({ appointmentId, dateFrom }).then(exams => {
			exams && exams[0] && dispatch(examsActions._loadExam(exams[0]))
		})

const fetchPatientExams =
	(internalPatientId: Id, storeId?: Id): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return getExams({ internalPatientId, storeId }).then(exams => {
			exams && dispatch(examsActions._loadExams(exams || []))
		})
	}

const setExamStatus =
	(
		params: ChangeExamStatusApiParams,
		userRole?: string,
		updateState: boolean = true,
	): AppThunkPromise<boolean> =>
	(dispatch: TeloDispatch) => {
		// It's wrong place this control here, setExamStatus should do only one thing, call changeExamStatusApi
		// TODO: refactor clearAzureData control outside this method (I blame myself for this 🙂)
		let clearAzureData = false
		const isTechnician =
			(userRole !== undefined && userRole === 'Technician') ||
			(params.interruptedByRole !== undefined &&
				params.interruptedByRole === 'Technician')
		if (['Waiting', 'Interrupted'].includes(params.status) && isTechnician) {
			clearAzureData = true
		}

		return changeExamStatusApi(params).then(result => {
			if (result) {
				if (updateState === true) {
					dispatch(examsActions._loadExam(result.exam))
					dispatch(storesActions._setStore(result.store))
				}

				if (clearAzureData) {
					dispatch(azureCommunicationActions.resetAzureCommunication())
				}

				return true
			} else {
				return false
			}
		})
	}

const updateInstrumentDataInRedux =
	(dispatch: Dispatch) => (res: SaveInstrumentDataResponse | void) =>
		res &&
		dispatch(
			slice.actions._setInstrumentData({
				examId: res.examId,
				instrumentData: res.examData,
				instrumentType: res.examType,
			}),
		)

const saveRetinalImagingData =
	(examId: Id, data: RetinalImageData): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		const retinalImageData = exam?.preTest.instruments.retinalImaging
		data.OS.data = data.OS.data.concat(
			differenceWith(retinalImageData?.OS.data || [], data.OS.data, (a, b) => {
				return a.path === b.path
			}),
		)
		data.OD.data = data.OD.data.concat(
			differenceWith(retinalImageData?.OD.data || [], data.OD.data, (a, b) => {
				return a.path === b.path
			}),
		)

		saveInstrumentDataApi(
			examId,
			encodeRetinalImageData(data),
			InstrumentApiType.RI,
		).then(updateInstrumentDataInRedux(dispatch))
	}

const saveOCTData =
	(examId: Id, data: OCTData): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		const octData = exam?.preTest.instruments.oct
		data.OS.media = data.OS.media.concat(
			differenceWith(octData?.OS?.media || [], data.OS.media, (a, b) => {
				return a.path === b.path
			}),
		)
		data.OD.media = data.OD.media.concat(
			differenceWith(octData?.OD?.media || [], data.OD.media, (a, b) => {
				return a.path === b.path
			}),
		)

		saveInstrumentDataApi(
			examId,
			encodeOCTData(data),
			InstrumentApiType.OCT,
		).then(updateInstrumentDataInRedux(dispatch))
	}

const saveLensometryData =
	(examId: Id, data: LensmeterData): AppThunk =>
	(dispatch: TeloDispatch) =>
		saveInstrumentDataApi(
			examId,
			encodeLensometerData(data),
			InstrumentApiType.LM,
		).then(updateInstrumentDataInRedux(dispatch))

const saveAutorefractionData =
	(examId: Id, data: AutorefractionData): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(
			chatbotActions.setAISectionData({
				autorefraction: data,
				type: 'autorefraction',
			}),
		)
		return saveInstrumentDataApi(
			examId,
			encodeAutorefractionData(data),
			InstrumentApiType.AR,
		).then(() => {
			chatbotActions.setAISectionData(undefined)

			return updateInstrumentDataInRedux(dispatch)
		})
	}

const saveKeratometryData =
	(examId: Id, data: KeratometerData): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(
			chatbotActions.setAISectionData({
				keratometry: data,
				type: 'autorefraction',
			}),
		)
		return saveInstrumentDataApi(
			examId,
			encodeKeratometerData(data),
			InstrumentApiType.KM,
		).then(() => {
			chatbotActions.setAISectionData(undefined)
			return updateInstrumentDataInRedux(dispatch)
		})
	}

const saveTonometerData =
	(examId: Id, data: TonometerData): AppThunkPromise<any> =>
	(dispatch: TeloDispatch) => {
		dispatch(
			chatbotActions.setAISectionData({
				page: '',
				section: 'tonometry',
				data,
			}),
		)
		return saveInstrumentDataApi(
			examId,
			encodeTonometerData(data),
			InstrumentApiType.NT,
		).then(() => {
			chatbotActions.setAISectionData(undefined)
			return updateInstrumentDataInRedux(dispatch)
		})
	}

const saveSlitLampData =
	(examId: Id, data: SlitLampData): AppThunk =>
	(dispatch: TeloDispatch) =>
		saveInstrumentDataApi(
			examId,
			encodeSlitLampData(data),
			InstrumentApiType.SL,
		).then(updateInstrumentDataInRedux(dispatch))

const savePhoropterData =
	(examId: Id, data: PhoropterData): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(
			chatbotActions.setAISectionData({
				page: 'classicExam',
				section: '',
				data,
			}),
		)
		return saveInstrumentDataApi(
			examId,
			encodePhoropterData(data),
			InstrumentApiType.PH,
		)
			.then(updateInstrumentDataInRedux(dispatch))
			.then(() => {
				chatbotActions.setAISectionData(undefined)
			})
	}

const saveManualData =
	({
		examId,
		data,
		dataType,
		setExam = true,
	}: {
		examId: Id
		data: SingleManualData
		dataType: ManualDataType
		setExam?: boolean
	}): AppThunkPromise =>
	dispatch => {
		dispatch(
			chatbotActions.setAISectionData({ page: '', section: dataType, data }),
		)

		return saveManualDataApi(examId, data, dataType).then(exam => {
			if (!setExam) {
				return
			}
			if (exam) {
				dispatch(slice.actions._loadExam(exam))
			}
			dispatch(chatbotActions.setAISectionData(undefined))
		})
	}

const saveCLTrialData =
	(
		examId: Id,
		data: ContactLenses[],
		clTempData?: PrescriptionContactLenses[] | undefined,
	): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		dispatch(
			chatbotActions.setAISectionData({
				page: 'contactLenses ',
				section: 'productSelection',
				data,
			}),
		)
		return saveCLTrialDataApi(
			examId,
			data.map(lens => ({
				...lens,
				catalogueId: { L: lens.catalogueId.OS, R: lens.catalogueId.OD },
			})),
		).then(exam => {
			exam &&
				dispatch(slice.actions._loadExam(exam)) &&
				dispatch(
					prescriptionsActions.updateClDataTemp(
						covertClDataForLensData(
							data.filter(cl => cl.status === 'PRESCRIBED'),
							clTempData,
						),
						exam._id,
					),
				)
			dispatch(chatbotActions.setAISectionData(undefined))
		})
	}

const resetPhoropter =
	(examId: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(
			slice.actions._setInstrumentData({
				examId,
				instrumentData: encodePhoropterData(getBlankPhoropterData()),
				instrumentType: 'PH',
			}),
		)
		dispatch(slice.actions._setPhoropterIsResetting(examId))
	}

const changeExamType =
	(examId: Id, newExamType: AppointmentType): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		updateExamApi(examId, { examType: newExamType }).then(exam => {
			exam && dispatch(slice.actions._setNewExam(exam))
		})

// Deprecated: use saveBatchDoctorNote
const saveDoctorNote =
	(examId: Id, instrument: DoctorNotesKeys, text: string): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveDoctorNote] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[saveDoctorNote] missing username')
		}
		if (exam.doctorNotes[instrument]?.text === text) {
			return Promise.resolve()
		}
		return updateExamApi(examId, {
			doctorNotes: {
				...exam.doctorNotes,
				[instrument]: {
					text,
					authorUsername: username,
					timestamp: new Date().toISOString(),
				},
			},
		}).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const saveBatchDoctorNote =
	(
		examId: Id,
		notes: {
			instrument: DoctorNotesKeys
			text: string
		}[],
		setExam: boolean = false,
	): AppThunkPromise =>
	async (dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveDoctorNote] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[saveDoctorNote] missing username')
		}
		if (notes.length === 0) {
			return Promise.resolve()
		}
		const notesToUpdate = notes.reduce(
			(prev, acc) => {
				prev[acc.instrument] = {
					text: acc.text,
					authorUsername: username,
					timestamp: new Date(),
				}
				return prev
			},
			{
				...exam.doctorNotes,
			} as DoctorNotes,
		)

		const examUpdated = await updateDoctorNotes(examId, notesToUpdate)

		if (setExam === true) {
			dispatch(slice.actions._loadExam(examUpdated))
		}

		return
	}

// OBSOLETE
const saveAssessmentPlan =
	(
		examId: Id,
		data: {
			diagnosis?: string[]
			assessment?: string
			plan?: string
			nextEyeExam?: NextEyeExam
			recommendations?: string
		},
		eyeHealthData?: EyeHealth,
	): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveDoctorNote] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[saveDoctorNote] missing username')
		}

		const body: Partial<ExamApi> = {
			// assessmentPlan: {
			// 	...data,
			// },
		}

		if (eyeHealthData) body.eyeHealth = eyeHealthData

		return updateExamApi(examId, body)
			.then(exam => {
				if (exam && exam?.eyeHealth?.document)
					return updateEyeHealthDocApi(examId)
				else return exam
			})
			.then(exam => {
				exam && dispatch(slice.actions._loadExam(exam))
			})
	}

const saveAssessmentPlanV2 =
	({
		examId,
		assessmentPlan,
		eyeHealth,
	}: {
		examId: Id
		assessmentPlan: AssessmentPlanV2
		eyeHealth?: EyeHealth
	}): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const body = {
			assessmentPlan,
			...(eyeHealth !== undefined ? { eyeHealth } : {}),
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'assessmentPlan',
				section: 'diagnosisCreated',
				data: assessmentPlan,
			}),
		)

		return updateExamApi(examId, body)
			.then(exam => {
				if (exam && exam?.eyeHealth?.document)
					return updateEyeHealthDocApi(examId)
				else return exam
			})
			.then(exam => {
				exam && dispatch(slice.actions._loadExam(exam))
				dispatch(chatbotActions.setAISectionData(undefined))
			})
	}

const saveAssessmentGoals =
	({ examId, goals }: { examId: Id; goals: Goal[] }): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const body = {
			goals,
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'goals',
				section: 'goals',
				data: body,
			}),
		)

		return updateExamApi(examId, body)
			.then(exam => {
				if (exam && exam?.eyeHealth?.document)
					return updateEyeHealthDocApi(examId)
				else return exam
			})
			.then(exam => {
				exam && dispatch(slice.actions._loadExam(exam))
				dispatch(chatbotActions.setAISectionData(undefined))
			})
	}

const saveImplantableDevices =
	({
		examId,
		implantableDevices,
	}: {
		examId: Id
		implantableDevices: ImplantableDevice[]
	}): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const body = {
			implantableDevices,
		}

		return updateExamApi(examId, body)
			.then(exam => {
				if (exam && exam?.eyeHealth?.document)
					return updateEyeHealthDocApi(examId)
				else return exam
			})
			.then(exam => {
				exam && dispatch(slice.actions._loadExam(exam))
			})
	}

const saveDoctorVisualFieldsData =
	({
		examId,
		confrontational,
		interpretation,
		visualFields,
	}: SaveDoctorVisualFieldsDataPayload): AppThunkPromise =>
	async (dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)

		if (!exam) {
			throw new Error('[saveDoctorNote] missing exam')
		}

		const username = selectUsername(state)

		if (!username) {
			throw new Error('[saveDoctorNote] missing username')
		}

		const payload = getSaveDoctorVisualFieldsDataPayload(
			username,
			interpretation,
			confrontational,
		)

		dispatch(
			chatbotActions.setAISectionData({
				page: '',
				section: 'visualField',
				data: { ...payload, visualFields },
			}),
		)

		const updatedExam = await updateExamApi(examId, payload)

		const savedInstrument = await saveInstrumentDataApi(
			examId,
			encodeVisualFieldsData(visualFields),
			InstrumentApiType.VF,
		)

		updateInstrumentDataInRedux(dispatch)(savedInstrument)

		if (updatedExam) {
			dispatch(slice.actions._loadExam(updatedExam))
		}

		dispatch(chatbotActions.setAISectionData(undefined))
	}

const saveAuxiliary =
	(examId: Id, data: AuxiliaryData): AppThunkPromise =>
	async (dispatch, getState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveDoctorNote] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[saveDoctorNote] missing username')
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'auxiliary',
				section: 'auxiliary',
				data,
			}),
		)

		const updatedExam = await updateExamApi(examId, { auxiliary: data })
		if (updatedExam) {
			dispatch(slice.actions._loadExam(updatedExam))
			dispatch(chatbotActions.setAISectionData(undefined))
		}
	}

const fetchExamWarnings =
	(examId: string): AppThunk =>
	(dispatch: TeloDispatch) =>
		getExamWarningsApi(examId).then(res => {
			if (!res) {
				return
			}

			dispatch(
				notificationsActions.addNotification({
					type: ['Error', 'Warning'].includes(res.level) ? 'error' : 'info',
					message: res.message,
					autoClose: false,
					messageIsLabelKey: false,
				}),
			)
		})

const saveExamLanguage =
	(examId: Id, language: string): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveExamLanguage] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[saveExamLanguage] missing username')
		}

		const body: Partial<ExamApi> = {
			language,
		}

		return updateExamApi(examId, body).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const saveReferralForm =
	(examId: Id, referralFormData: ReferralFormData): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveReferralForm] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[saveReferralForm] missing username')
		}

		const body: Partial<ExamApi> = {
			referralForm: {
				...referralFormData,
			},
		}

		return updateExamApi(examId, body).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const upsertOrderReferral =
	(
		examId: Id,
		orderId: Id,
		signature?: Signature,
		send: boolean = false,
	): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[upsertOrderReferral] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[upsertOrderReferral] missing username')
		}
		const order = exam.orders?.find(o => o.id === orderId)
		if (!order) {
			throw new Error('[upsertOrderReferral] missing order')
		}

		return upsertOrderReferraApi(examId, orderId, {
			...signature,
			doctorSignature: true,
			send,
		}).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const upsertOrder =
	(
		examId: Id,
		orders: Order[],
		orderId: Id,
		signature?: Signature,
		send: boolean = false,
	): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[upsertOrder] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[upsertOrder] missing username')
		}
		const order = orders?.find(o => o.id === orderId)
		if (!order) {
			throw new Error('[upsertOrder] missing order')
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'ordersPage',
				section: 'orderCreated',
				orders: [order],
			}),
		)

		return updateExamApi(examId, { orders })
			.then(exam => {
				if (exam) {
					return upsertOrderReferraApi(examId, orderId, {
						...signature,
						doctorSignature: true,
						send,
					})
				}
			})
			.then(exam => {
				exam && dispatch(slice.actions._loadExam(exam))
				dispatch(chatbotActions.setAISectionData(undefined))
			})
	}

const upsertReferralForm =
	(examId: Id, signature?: Signature, send: boolean = false): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveDoctorNote] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[saveDoctorNote] missing username')
		}
		return upsertReferralFormApi(examId, {
			...signature,
			doctorSignature: true,
			send,
		}).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const getReferralFormPdf =
	(examId: string, fileRef: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		getExamDocumentsFromReferenceApi(fileRef, 'ppd').then(doc => {
			dispatch(
				signRequestsActions._savePdfDocument({
					type: 'referralForm',
					examId,
					doc: window.URL.createObjectURL(doc),
				}),
			)
		})
	}

const getOrderReferralPdf =
	(examId: string, orderId: string, fileRef: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		getExamDocumentsFromReferenceApi(fileRef, 'orders').then(doc => {
			dispatch(
				signRequestsActions._savePdfDocument({
					type: 'orderReferral',
					examId,
					orderId,
					doc: window.URL.createObjectURL(doc),
				}),
			)
		})
	}

const sendReferralDocument =
	(examId: Id, send: boolean): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveDoctorNote] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[saveDoctorNote] missing username')
		}
		return sendReferralDocumentApi(examId, {
			send,
		}).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const saveCLHistory =
	(examId: Id, CLHistory: ContactLensesHistory[]): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveCLHistory] missing exam')
		}

		return updateExamApi(examId, {
			contactLensesHistory: CLHistory,
		}).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const uploadExamAttachmentAction =
	({
		examId,
		documentType,
		fileContent,
		fileName,
		uploadedBy,
	}: {
		examId: string
		documentType: string
		fileContent: string
		fileName: string
		uploadedBy: string
	}): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		uploadExamAttachmentApi({
			examId,
			documentType,
			fileContent,
			fileName,
			uploadedBy,
		}).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})

const deleteExamAttachmentAction =
	(examId: string = '', documentRef: string): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		deleteExamAttachmentApi(examId, documentRef).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})

const saveExamLinkAction =
	({
		examId,
		url,
		description,
		linkTextOptional,
		uploadedBy,
	}: {
		examId: string
		url: string
		description: string
		linkTextOptional: string
		uploadedBy: string
	}): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		saveExamLinkApi({
			examId,
			url,
			description,
			linkTextOptional,
			uploadedBy,
		}).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})

const deleteExamLinkAction =
	(examId: string = '', id: string): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		deleteExamLinkApi(examId, id).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})

const downloadExamAttachmentAction =
	(examId: string = '', documents: ExamDocument[]): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return downloadExamAttachmentApi(examId, documents).then((blob: any) => {
			var binaryData = []
			binaryData.push(blob)
			var url = window.URL.createObjectURL(
				new Blob(binaryData, { type: 'application/zip' }),
			)
			//var url = window.URL.createObjectURL(blob)
			let a = document.createElement('a')
			a.download = `AttachmentExport_` + new Date()
			a.href = url
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
			dispatch(fetchExam(examId))
		})
	}

const fetchExamAttachmentBlobAction =
	(examId: string = '', documents: ExamDocument[]): AppThunkPromise =>
	async () => {
		return downloadExamAttachmentApi(examId, documents).then((blob: any) => {
			return blob
		})
	}

const updateAttachment =
	(examId: string, attachments: ExamDocument[]): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		updateAttachmentApi(examId, attachments).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})

const updateLink =
	(examId: string, links: ExamLink[]): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		updateLinkApi(examId, links).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})

const saveOrderResult =
	(examId: string, orderId: string, result: OrderResult): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return saveOrderResultApi(examId, orderId, result).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})
	}

const updateOrderResult =
	(examId: string, orderId: string, result: OrderResult): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return upsertOrderResultApi(examId, orderId, result).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})
	}

const deleteOrderResult =
	(examId: string, orderId: string, resultId: string): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return deleteOrderResultApi(examId, orderId, resultId).then(exam => {
			dispatch(examsActions._loadExam(exam))
		})
	}

const downloadOrderAttachment =
	(
		examId: string,
		orderId: string,
		result: OrderResult,
		attachment: ExamDocument,
	): AppThunkPromise =>
	() => {
		return downloadOrderResultAttachmentApi(
			examId,
			orderId,
			result,
			attachment,
		).then((blob: any) => {
			if (blob) {
				var binaryData = []
				binaryData.push(blob)

				var url = window.URL.createObjectURL(new Blob(binaryData))

				let a = document.createElement('a')

				a.download = attachment!.documentName
				a.href = url
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			}
		})
	}

const generateChart =
	(examId: Id): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[generateChart] missing exam')
		}

		const body: Partial<ExamApi> = {
			chartAvailable: true,
		}

		return updateExamApi(examId, body).then(exam => {
			if (exam) {
				dispatch(examsActions._loadExam(exam))
				dispatch(
					notificationsActions.addNotification({
						message: 'Chart created',
						autoClose: true,
						type: 'success',
					}),
				)
			}
		})
	}

const generateWorklistCharts =
	(examIds: string[]): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		const body: Partial<ExamApi> = {
			chartAvailable: true,
		}
		return Promise.all(examIds.map(id => updateExamApi(id, body)))
			.then(() => {
				dispatch(
					notificationsActions.addNotification({
						message: 'Charts created',
						autoClose: true,
						type: 'success',
					}),
				)

				dispatch(worklistApi.util.invalidateTags([WORKLIST_CACHE_TAG]))
			})
			.catch(_ => {
				dispatch(
					notificationsActions.addNotification({
						message: 'Error - Charts not created',
						autoClose: true,
						type: 'error',
					}),
				)
			})
	}

const updateMedicalReport =
	(examId: Id, medicalReportNote: string): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[saveEyeHealthForm] missing exam')
		}

		const body: Partial<ExamApi> = {
			medicalReportNote,
		}

		return updateExamApi(examId, body).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const upsertMedicalReport =
	(
		examId: Id,
		medicalReport: ExamMedicalReport,
		action: () => void,
	): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[upsertMedicalReport] missing exam')
		}

		return upsertMedicalReportApi(examId, medicalReport.id, medicalReport).then(
			exam => {
				exam && dispatch(slice.actions._loadExam(exam))
				action()
			},
		)
	}

const updateExamDif =
	(examId: Id, dif: DifForm, showNotification?: boolean): AppThunkPromise =>
	async (dispatch: TeloDispatch) => {
		const exam = await updateDif(examId, dif)

		if (!exam) {
			dispatch(examsActions._removeExam(examId))
			return
		}

		if (isExam(exam) && exam.store) {
			dispatch(storesActions._setStore(exam.store))
			dispatch(examsActions._loadExam(exam))
		}

		if (showNotification) {
			dispatch(
				notificationsActions.addNotification(exportSucceededNotification),
			)
		}
	}

const exportDataFromExam =
	(
		fromExamId: Id,
		toExamId: Id,
		data: ExportToolData,
		showNotification: boolean = false,
	): AppThunk =>
	(dispatch: TeloDispatch) => {
		return exportFromExamApi(fromExamId, toExamId, data).then(exam => {
			if (!exam) {
				dispatch(examsActions._removeExam(toExamId))
				return
			}
			dispatch(examsActions._loadExam(exam))
			isExam(exam) &&
				exam.store &&
				dispatch(storesActions._setStore(exam.store))
			if (showNotification === true) {
				dispatch(
					notificationsActions.addNotification(exportSucceededNotification),
				)
			}
		})
	}

const setMedications =
	(toExamId: Id, showExportNotification: boolean): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return setMedicationsAPI(toExamId).then(exam => {
			if (!exam) {
				dispatch(examsActions._removeExam(toExamId))
				return
			}
			dispatch(examsActions._loadExam(exam))
			if (showExportNotification === true) {
				dispatch(
					notificationsActions.addNotification(exportSucceededNotification),
				)
			}
		})
	}

const saveHealthConcern =
	(examId: Id, healthConcern: string): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const data = {
			healthConcerns: [...(exam.healthConcerns ?? []), healthConcern],
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'prescriptions',
				section: 'healthConcern',
				data,
			}),
		)

		return updateExamApi(examId, data).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
			dispatch(chatbotActions.setAISectionData(undefined))
		})
	}

const updateHealthConcern =
	(
		examId: Id,
		healthConcernIdx: number,
		healthConcern: string,
	): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const data = {
			healthConcerns: [
				...(exam.healthConcerns ?? []).map((h, i) =>
					i === healthConcernIdx ? healthConcern : h,
				),
			],
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'prescriptions',
				section: 'healthConcern',
				data,
			}),
		)

		return updateExamApi(examId, data).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
			dispatch(chatbotActions.setAISectionData(undefined))
		})
	}

const deleteHealthConcern =
	(examId: Id, selectedHealthConcerns: HealthConcern[]): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const data = {
			healthConcerns: [
				...(exam.healthConcerns ?? []).filter(
					(_, i) =>
						selectedHealthConcerns.findIndex(({ id }) => id === i) === -1,
				),
			],
		}

		return updateExamApi(examId, data).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const updateExam =
	(examId: Id, data: Partial<ExamBase>): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		return updateExamApi(examId, data).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const saveExamEndedWithoutRxNotes =
	({ examId, noRxNotes }: { examId: Id; noRxNotes: string }): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const body = {
			noRxNotes,
		}

		return updateExamApi(examId, body, true).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const saveChiefComplaintCategory =
	({
		examId,
		chiefComplaintCategories,
	}: {
		examId: Id
		chiefComplaintCategories: ChiefComplaint[]
	}): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const body = {
			chiefComplaintCategories,
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'chiefComplaint',
				section: 'chiefComplaint',
				data: body,
			}),
		)

		return updateExamApi(examId, body).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
			dispatch(chatbotActions.setAISectionData(undefined))
		})
	}

const saveProcedures =
	({
		examId,
		procedures,
	}: {
		examId: Id
		procedures: Procedure[]
	}): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const body = {
			procedures,
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'procedure',
				section: 'procedures',
				data: procedures,
			}),
		)

		return updateExamApi(examId, body)
			.then(exam => {
				if (exam && exam?.eyeHealth?.document)
					return updateEyeHealthDocApi(examId)
				else return exam
			})
			.then(exam => {
				exam && dispatch(slice.actions._loadExam(exam))
				dispatch(chatbotActions.setAISectionData(undefined))
			})
	}

const removeProcedure =
	({
		examId,
		procedureCode,
	}: {
		examId: string
		procedureCode: string
	}): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		removeProcedureApi({ examId, procedureCode }).then(exam => {
			exam && dispatch(examsActions._loadExam(exam))
		})

const uploadExamProcedureImageAction =
	({
		examId,
		procedureCode,
		documentType,
		fileContent,
		fileName,
		uploadedBy,
	}: {
		examId: string
		procedureCode: string
		documentType: string
		fileContent: string
		fileName: string
		uploadedBy: string
	}): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		uploadExamProcedureImageApi({
			examId,
			procedureCode,
			documentType,
			fileContent,
			fileName,
			uploadedBy,
		}).then(exam => {
			exam && dispatch(examsActions._loadExam(exam))
		})

const deleteExamProcedureImageAction =
	(
		examId: string = '',
		procedureCode: string,
		documentRef: string,
	): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		deleteExamProcedureImageApi(examId, procedureCode, documentRef).then(
			exam => {
				exam && dispatch(examsActions._loadExam(exam))
			},
		)

const updateExamScreeningProcedures =
	({
		examId,
		screeningProcedures,
	}: {
		examId: Id
		screeningProcedures: ScreeningProcedure[]
	}): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('Missing exam')
		}

		const body = {
			screeningProcedures,
		}

		return updateExamApi(examId, body)
			.then(exam => {
				if (exam && exam?.eyeHealth?.document)
					return updateEyeHealthDocApi(examId)
				else return exam
			})
			.then(exam => {
				exam && dispatch(slice.actions._loadExam(exam))
			})
	}

const removeScreeningProcedure =
	({
		examId,
		screeningProcedureId,
	}: {
		examId: string
		screeningProcedureId: string
	}): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		removeScreeningProcedureApi({ examId, screeningProcedureId }).then(exam => {
			exam && dispatch(examsActions._loadExam(exam))
		})

const uploadExamScreeningProcedureAttachmentAction = ({
	examId,
	screeningProcedureId,
	fileContent,
	fileName,
	fileSize,
	documentType,
	uploadedBy,
}: {
	examId: string
	screeningProcedureId: string
	fileContent: string
	fileName: string
	fileSize: number
	documentType: string
	uploadedBy: string
}) =>
	uploadExamScreeningProcedureAttachmentApi({
		examId,
		screeningProcedureId,
		fileContent,
		fileName,
		fileSize,
		documentType,
		uploadedBy,
	})

const uploadOrderAttachmentAction =
	({
		examId,
		orderId,
		fileContent,
		fileName,
		documentType,
		uploadedBy,
	}: {
		examId: string
		orderId: string
		fileContent: string
		fileName: string
		documentType: string
		uploadedBy: string
	}): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		uploadOrderAttachmentApi({
			examId,
			orderId,
			fileContent,
			fileName,
			documentType,
			uploadedBy,
		}).then(exam => {
			exam && dispatch(examsActions._loadExam(exam))
		})

const uploadOrderResultImageAction = ({
	examId,
	orderId,
	resultId,
	fileContent,
	fileName,
	documentType,
	uploadedBy,
}: {
	examId: string
	orderId: string
	resultId: string
	fileContent: string
	fileName: string
	documentType: string
	uploadedBy: string
}) =>
	uploadOrderResultImageApi({
		examId,
		orderId,
		resultId,
		fileContent,
		fileName,
		documentType,
		uploadedBy,
	})

const uploadExamScreeningProcedureImageAction = ({
	examId,
	screeningProcedureId,
	fileContent,
	fileName,
	documentType,
	uploadedBy,
}: {
	examId: string
	screeningProcedureId: string
	fileContent: string
	fileName: string
	documentType: string
	uploadedBy: string
}) =>
	uploadExamScreeningProcedureImageApi({
		examId,
		screeningProcedureId,
		fileContent,
		fileName,
		documentType,
		uploadedBy,
	})

const deleteExamScreeningProcedureImageAction = (
	examId: string = '',
	screeningProcedureId: string,
	documentRef: string,
) =>
	deleteExamScreeningProcedureImageApi(
		examId,
		screeningProcedureId,
		documentRef,
	)

const deleteExamScreeningProcedureAttachmentAction = (
	examId: string = '',
	screeningProcedureId: string,
	documentRef: string,
) =>
	deleteExamScreeningProcedureAttachmentApi(
		examId,
		screeningProcedureId,
		documentRef,
	)

const saveOtherTest =
	(examId: string, otherTestData: OtherTest): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)

		if (!exam) {
			throw new Error('Missing exam')
		}

		const otherTestIndex = exam.otherTest?.findIndex(
			e => e.code === otherTestData.code,
		)
		const isEdit = otherTestIndex !== -1

		let payload: OtherTest[] = []

		if (
			isEdit &&
			exam.otherTest &&
			otherTestIndex !== -1 &&
			otherTestIndex !== undefined
		) {
			payload = payload.concat(exam.otherTest)
			payload[otherTestIndex] = otherTestData
		} else {
			payload = (exam.otherTest || []).concat([otherTestData])
		}

		return updateExamApi(examId, { otherTest: payload }).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const deleteOtherTest =
	(examId: string, ids: string[]): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)

		if (!exam) {
			throw new Error('Missing exam')
		}

		const payload = exam.otherTest?.filter(e => !ids.includes(e.code))

		return updateExamApi(examId, { otherTest: payload }).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const downloadOtherTestImage =
	(image: ExamDocument): AppThunk =>
	() => {
		return getOtherTestImages(image.ref).then(img => {
			let a = document.createElement('a')
			a.href = img
			a.download = image.documentName
			a.click()
		})
	}

const refreshDocumentsAction =
	(examId: string): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)

		if (!exam) {
			return Promise.reject()
		}

		return refreshDocumentsApi(exam).then(result => {
			if (result.exam) {
				dispatch(examsActions._loadExam(result.exam))
				dispatch(
					notificationsActions.addNotification({
						message: 'doctor.documentsUpdated',
						messageIsLabelKey: true,
						type: 'success',
						autoClose: true,
					}),
				)
			}
		})
	}

const updateLocalPretestData =
	(updateData: UpdateLocalPretestDataType[]): AppThunk =>
	(dispatch: TeloDispatch) => {
		const lastExam = updateData[updateData.length - 1].exam
		const pretestReduced = updateData.reduce(
			(ac, c) => ({ ...ac, ...c.updatedData }),
			{},
		)
		const updatedExam = {
			...lastExam,
			pretest: { ...lastExam.preTest, ...pretestReduced },
		}
		return dispatch(examsActions._loadExam(updatedExam))
	}

const saveExamTamplate =
	(examId: Id, pages: ExamPages): AppThunk =>
	(dispatch: TeloDispatch) => {
		const body = {
			pages,
		}

		return updateExamApi(examId, body).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
		})
	}

const saveExamVisionTherapy =
	(examId: Id, visionTherapy: VisionTherapy): AppThunk =>
	(dispatch: TeloDispatch) => {
		const body = {
			visionTherapy,
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'visionTherapy',
				section: 'vtRecommendation',
				data: body,
			}),
		)

		return updateExamApi(examId, body).then(exam => {
			exam && dispatch(slice.actions._loadExam(exam))
			dispatch(chatbotActions.setAISectionData(undefined))
		})
	}

const downloadEHRDocument =
	(examId: string, patientName: string): AppThunkPromise =>
	async (dispatch, getState) => {
		dispatch(
			notificationsActions.addNotification({
				id: 'export-ehr-started',
				message: i18n.t('doctor.EHRExportStartedMessage'),
				autoClose: true,
				type: 'success',
			}),
		)
		try {
			const blob = await retrieveEHRExport(examId)
			if (!blob) return
			var url = window.URL.createObjectURL(blob)
			let a = document.createElement('a')
			a.download = `EHRExport_${patientName}.zip`
			a.href = url
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
		} catch (e) {
			console.error(e)
			dispatch(notificationsActions.removeNotification('export-ehr-started'))
			dispatch(
				notificationsActions.addNotification({
					message: i18n.t('errors.connection'),
					autoClose: true,
					type: 'error',
				}),
			)
		}
	}

const fetchExamCodes =
	(storeId: string, examId: Id): AppThunkPromise =>
	async (dispatch: TeloDispatch) => {
		const codes = await getExamCodesApi(storeId, examId)
		const enhancedCodes: Code[] = codes.map(
			({ id, quantity, problemCode, modifierCodes, ...rest }: Code) => ({
				id: id ?? generateUID(),
				quantity: quantity ?? 1,
				problemCode: problemCode ?? [],
				modifierCodes: modifierCodes ?? [],
				...rest,
			}),
		)
		const updatedExam = await updateExamApi(examId, {
			coding: enhancedCodes,
		})

		dispatch(slice.actions._loadExam(updatedExam))
	}

const updateExamCodes =
	(examId: Id, codes: Code[]): AppThunk =>
	(dispatch: TeloDispatch, getState) =>
		updateExamApi(examId, {
			coding: codes,
		}).then(exam => exam && dispatch(slice.actions._loadExam(exam)))

const upsertSurgery =
	(examId: Id, surgeries: ExamSurgery[], surgeryId: Id): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const exam = selectExam(examId)(state)
		if (!exam) {
			throw new Error('[upsertSurgery] missing exam')
		}
		const username = selectUsername(state)
		if (!username) {
			throw new Error('[upsertSurgery] missing username')
		}
		const surgery = surgeries?.find(o => o.id === surgeryId)
		if (!surgery) {
			throw new Error('[upsertSurgery] missing surgery')
		}

		dispatch(
			chatbotActions.setAISectionData({
				page: 'surgery ',
				section: 'procedureCreated',
				data: surgeries,
			}),
		)

		return updateExamApi(examId, { surgeries })
			.then(exam => {
				if (exam) {
					return upsertSurgeryDocumentApi(examId, surgeryId)
				}
			})
			.then(exam => {
				exam && dispatch(slice.actions._loadExam(exam))
				dispatch(chatbotActions.setAISectionData(undefined))
			})
	}

const examsActions = {
	...slice.actions,
	changeExamType,
	downloadEHRDocument,
	deleteExamAttachmentAction,
	downloadExamAttachmentAction,
	fetchExamAttachmentBlobAction,
	downloadOrderAttachment,
	downloadOtherTestImage,
	exportDataFromExam,
	setMedications,
	fetchExam,
	fetchExamByAppointmentId,
	fetchExamCodes,
	fetchExams,
	fetchExamWarnings,
	fetchInstrumentData,
	fetchPatientExams,
	generateChart,
	generateWorklistCharts,
	getReferralFormPdf,
	getOrderReferralPdf,
	resetPhoropter,
	saveAssessmentPlan,
	saveAssessmentPlanV2,
	saveAssessmentGoals,
	saveAutorefractionData,
	saveBatchDoctorNote,
	saveChiefComplaintCategory,
	saveCLHistory,
	saveCLTrialData,
	saveDoctorNote,
	saveAuxiliary,
	saveDoctorVisualFieldsData,
	saveExamLanguage,
	saveHealthConcern,
	updateHealthConcern,
	deleteHealthConcern,
	saveKeratometryData,
	saveLensometryData,
	saveManualData,
	savePhoropterData,
	saveReferralForm,
	saveRetinalImagingData,
	saveSlitLampData,
	saveTonometerData,
	sendReferralDocument,
	setExamStatus,
	updateAttachment,
	updateLink,
	updateExam,
	updateExamDif,
	updateMedicalReport,
	updateOrderResult,
	updateLocalPretestData,
	uploadExamAttachmentAction,
	upsertReferralForm,
	upsertOrderReferral,
	saveExamEndedWithoutRxNotes,
	saveProcedures,
	removeProcedure,
	uploadExamProcedureImageAction,
	deleteExamProcedureImageAction,
	saveImplantableDevices,
	updateExamScreeningProcedures,
	uploadExamScreeningProcedureAttachmentAction,
	uploadExamScreeningProcedureImageAction,
	deleteExamScreeningProcedureImageAction,
	uploadOrderAttachmentAction,
	uploadOrderResultImageAction,
	removeScreeningProcedure,
	deleteExamScreeningProcedureAttachmentAction,
	saveOtherTest,
	deleteOtherTest,
	refreshDocumentsAction,
	saveOrderResult,
	deleteOrderResult,
	upsertOrder,
	saveOCTData,
	saveExamTamplate,
	upsertMedicalReport,
	saveExamVisionTherapy,
	updateExamCodes,
	upsertSurgery,
	saveExamLinkAction,
	deleteExamLinkAction,
}

export default examsActions
