import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SignDocumentsState, SignedDocuments } from '../../model/model'
import { SetSignDocumentByExamPayload } from './model'
import { signDocumentsSliceName } from './constants'

export const signDocumentsInitialState: SignDocumentsState = {
	signedDocuments: {} as SignedDocuments,
}

export const signDocumentsSlice = createSlice({
	name: signDocumentsSliceName,
	initialState: signDocumentsInitialState,
	reducers: {
		setSignDocumentByExam: (
			{ signedDocuments },
			{
				payload: { examId, document },
			}: PayloadAction<SetSignDocumentByExamPayload>,
		) => {
			if (!signedDocuments[examId]) {
				signedDocuments[examId] = [document]
			}

			const isExistingExamAndNewDoc = !signedDocuments[examId].find(
				oldDoc => oldDoc.documentId === document.documentId,
			)

			if (isExistingExamAndNewDoc) {
				signedDocuments[examId] = [...signedDocuments[examId], document]
			}
		},
	},
})

export default signDocumentsSlice.reducer
