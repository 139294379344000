import { AppConfig } from './model/model'

function appendToURL(base: string, toAppend: string): string {
	const separator = base.endsWith('/') ? '' : '/'
	return `${base}${separator}${toAppend}`
}

const safeEnv = (key: string, defaultValue?: string): string => {
	const value: string = process.env[key] || ''
	const result: any = value || defaultValue

	// strict check because empty string must be evaluated as true
	if (result == null) {
		throw new Error(`Missing key in in .env file: ${key}`)
	}
	return result
}

const appConfig: AppConfig = {
	app: {
		name: safeEnv('REACT_APP_NAME', ''),
		version: safeEnv('REACT_APP_VERSION', ''),
	},
	apiUrl: appendToURL(safeEnv('REACT_APP_API_URL', ''), 'v1'),
	apiUrlV2: appendToURL(safeEnv('REACT_APP_API_URL', ''), 'v2'),
	apiUrlV3: appendToURL(safeEnv('REACT_APP_API_URL', ''), 'v3'),
	socketUrl: safeEnv('REACT_APP_SOCKET_URL', ''),
	env: safeEnv('NODE_ENV', 'development'),
	environment: safeEnv('REACT_APP_ENV', ''),
	logout: safeEnv(
		'REACT_APP_LOGOUT_URL',
		'https://mytestlogin.luxottica.com/nidp/app/logout',
	),
	login: {
		clientId: safeEnv(
			'REACT_APP_LOGIN_CLIENT_ID',
			'b9ab1456-7475-424d-a6c9-21fda2b6a929',
		),
		url: safeEnv(
			'REACT_APP_LOGIN_URL',
			'https://mydevlogin.luxottica.com/nidp/oauth/nam/authz',
		),
		redirectUri: safeEnv(
			'REACT_APP_LOGIN_REDIRECT_URI',
			'http://localhost:3000/',
		),
		redirectUriEclips: safeEnv(
			'REACT_APP_LOGIN_REDIRECT_URI_ECLIPS',
			'https://qa-telemedicine.essilorluxottica.com/eclips/app-code',
		),
		bypassLogin: safeEnv('REACT_APP_BYPASS_LOGIN', 'false') === 'true',
		fakeUserRole: safeEnv('REACT_APP_FAKE_USER_ROLES', ''),
	},
	languages: ['en-US', 'sp-US'],
	digitalIntakeForm: {
		url: safeEnv(
			'REACT_DIF_URL',
			'https://if-fe-dev-01.azurewebsites.net/node_modules/@abstract/intake-form/dist/index.umd.js',
		),
		subscriptionKey: safeEnv(
			'REACT_APP_DIF_SUB_KEY',
			'7eda059f871d4b22a9ac63807080b922',
		),
	},
	newCrop: {
		url: safeEnv(
			'REACT_APP_NEW_CROP_BASE_URL',
			'https://preproduction.newcropaccounts.com',
		),
	},

	region: safeEnv('REACT_APP_REGION', 'NA'),
	featuresSwitch: {
		ai: safeEnv('REACT_APP_AI_ENABLED', 'true') === 'true',
		directAccess: 'fhir',
		dif: safeEnv('REACT_APP_DIF_ENABLED', 'true') === 'true',
	},
	ui: {
		muiPro: safeEnv('REACT_APP_MUI_X_PRO_LICENSE', ''),
	},
	mips: {
		url: safeEnv('REACT_APP_MIPS_URL', ''),
	},
}

export default appConfig
