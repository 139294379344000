import { useEffect } from 'react'
import { selectAppLoginStatus } from '../../features/auth/selectors'
import { useTeloNavigate } from '../../routing/teloNavigate'
import { useTeloSelector } from '../../store'

export const useLogoutNavigation = () => {
	const navigate = useTeloNavigate()
	const loginStatus = useTeloSelector(selectAppLoginStatus)
	useEffect(() => {
		if (loginStatus === 'logging out') {
			navigate('/logout')
		}
	}, [loginStatus, navigate])
}
