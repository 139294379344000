import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import { UnlockDialogType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'
import storesActions from '../features/stores/actions'

const StyledActions = styled(DialogActions)`
	justify-content: flex-end;
`

const isUnlockDialog = (dialog: any): dialog is UnlockDialogType =>
	typeof dialog === 'object'

const UnlockDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)

	return !isUnlockDialog(dialog) ? null : (
		<>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t('exam.unlockDialogTitle')}
					<br />
					{t('exam.unlockDialogProceedQuestion')}
				</DialogContentText>
			</DialogContent>
			<StyledActions>
				<Button
					onClick={() => {
						dispatch(dialogActions.closeDialog())
					}}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					onClick={() => {
						dispatch(
							storesActions.unlockRoomAction({
								storeId: dialog.storeId,
								stageId: dialog.stageId,
								roomId: dialog.roomId,
							}),
						).then(() => {
							dispatch(dialogActions.closeDialog())
						})
					}}
					variant="contained"
					data-testid="proceed-button"
				>
					{t('exam.unlockDialogProceed')}
				</Button>
			</StyledActions>
		</>
	)
}

export default UnlockDialog
