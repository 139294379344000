import { NavigateFunction } from 'react-router-dom'
import {
	addRoomApi,
	addStageApi,
	addStoreDocumentApi,
	createStoreApi,
	deleteRoomApi,
	deleteStageApi,
	deleteStoreApi,
	deleteStoreDocumentApi,
	editRoomApi,
	getAllStoresApi,
	getPrivacyPolicyPdfApi,
	getStagesApi,
	getStoreApi,
	removeInstrumentFromRoomApi,
	renameStageApi,
	setLockDoctorRoomApi,
	setLockInstrumentRoomApi,
	SetLockInstrumentRoomApiParams,
	setUnlockRoomApi,
	updateStoreApi,
	updateStoreDocumentApi,
	updateStoreExternalIdsApi,
	updateStoreStaffAPI,
} from '../../apiCalls'
import { isNewRoomNameUnique, isNewStageNameUnique } from '../../libs/stages'
import { keys } from '../../libs/utils'
import { InstrumentType } from '../../model/instrumentsApi'
import { Id } from '../../model/model'
import {
	CreateDocumentPayload,
	CreateStorePayload,
	Room,
	Store,
	UpdateDocumentPayload,
	UpdateStoreStaffBody,
} from '../../model/store'
import {
	AppThunk,
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
} from '../../store'
import { selectStoreId } from '../app/selectors'
import notificationsActions from '../notifications/actions'
import practicesActions from '../practices/actions'
import { selectInstrumentsStages, selectRoom } from './selectors'
import { slice } from './slice'

const getStoreAction =
	(storeId?: string): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const validStoreId = storeId || selectStoreId(state)
		validStoreId &&
			getStoreApi(validStoreId).then(
				store => store && dispatch(slice.actions._setStore(store)),
			)
	}

const updateStoreAction =
	(store: Store): AppThunk =>
	(dispatch: TeloDispatch) => {
		updateStoreApi(store).then(
			store => store && dispatch(slice.actions._setStore(store)),
		)
	}

const createStoreAction =
	(
		storeData: CreateStorePayload,
		practices: Id[],
		navigate: NavigateFunction,
	): AppThunk =>
	(dispatch: TeloDispatch) => {
		createStoreApi(storeData).then(store => {
			store && dispatch(slice.actions._setStore(store))
			store &&
				practices.map(practice =>
					dispatch(
						practicesActions.addStoreToPractice({
							practiceId: practice,
							storeId: store._id,
						}),
					),
				)
			store && navigate(`/admin?selectedTab=stores`)
		})
	}

const getAllStoresAction =
	(storeIds?: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		getAllStoresApi(storeIds).then(
			stores => stores && dispatch(slice.actions._setStores(stores)),
		)
	}

const lockInstrumentRoomAction =
	({
		stageId,
		roomId,
		examId,
		storeId,
	}: SetLockInstrumentRoomApiParams): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return setLockInstrumentRoomApi({
			storeId,
			stageId,
			roomId,
			examId,
		}).then(stages => {
			stages &&
				dispatch(
					slice.actions._setStages({
						storeId,
						stages,
					}),
				)
		})
	}

const lockDoctorRoomAction =
	({
		stageId,
		roomId,
		examId,
		storeId,
	}: SetLockInstrumentRoomApiParams): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return setLockDoctorRoomApi({
			storeId,
			stageId,
			roomId,
			examId,
		}).then(stages => {
			stages &&
				dispatch(
					slice.actions._setStages({
						storeId,
						stages,
					}),
				)
		})
	}

const unlockRoomAction =
	({
		stageId,
		roomId,
		storeId,
	}: Omit<SetLockInstrumentRoomApiParams, 'examId'>): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return setUnlockRoomApi({
			storeId,
			stageId,
			roomId,
		}).then(stages => {
			stages &&
				dispatch(
					slice.actions._setStages({
						storeId,
						stages,
					}),
				)
		})
	}

const loadInstrumentsRoomsAction =
	(storeId?: string): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const safeStoreid = storeId || selectStoreId(state)
		if (safeStoreid) {
			getStagesApi(safeStoreid).then(
				stages =>
					stages &&
					dispatch(slice.actions._setStages({ storeId: safeStoreid, stages })),
			)
		}
	}

const addStageAction =
	(storeId: string, name: string): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		addStageApi(storeId, name).then(stage => {
			stage && dispatch(slice.actions._addStage({ storeId, stage }))
		})

const deleteStageAction =
	(storeId: string, id: Id): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		deleteStageApi(storeId, id).then(stages => {
			stages && dispatch(slice.actions._setStages({ storeId, stages }))
		})

const renameStageAction =
	(storeId: Id, id: Id, name: string): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const stages = selectInstrumentsStages(state, storeId)
		return isNewStageNameUnique(stages, name)
			? renameStageApi(storeId, id, name).then(stage => {
					stage && dispatch(slice.actions._replaceStage({ storeId, stage }))
			  })
			: Promise.resolve(
					dispatch(
						notificationsActions.addNotification({
							type: 'error',
							message: 'admin.stageNameNotUnique',
							autoClose: false,
							messageIsLabelKey: true,
						}),
					),
			  )
	}

const addRoomAction =
	(storeId: Id, stageId: Id, name: string): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		return addRoomApi(storeId, stageId, name).then(room => {
			room && dispatch(slice.actions._addRoom({ storeId, room }))
		})
	}

const deleteRoomAction =
	(storeId: string, stageId: Id, roomId: Id): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		deleteRoomApi(storeId, stageId, roomId).then(stages => {
			stages && dispatch(slice.actions._setStages({ storeId, stages }))
		})

const renameRoomAction =
	(storeId: Id, roomId: Id, name: string): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const room = selectRoom(roomId, storeId)(state)
		if (!room) {
			return Promise.resolve()
		}
		const stages = selectInstrumentsStages(state)

		return isNewRoomNameUnique(stages, room?.stageId, name)
			? editRoomApi(storeId, { ...room, name }).then(room => {
					room && dispatch(slice.actions._replaceRoom({ storeId, room }))
			  })
			: Promise.resolve(
					dispatch(
						notificationsActions.addNotification({
							type: 'error',
							message: 'admin.roomNameNotUnique',
							autoClose: false,
							messageIsLabelKey: true,
						}),
					),
			  )
	}

interface RoomUpdatePayload extends Partial<Room> {
	_id: Id
}
const updateRoomAction =
	(storeId: Id, roomUpdate: RoomUpdatePayload): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const room = selectRoom(roomUpdate._id, storeId)(state)
		if (!room) {
			return Promise.resolve()
		}

		const encodedRoom = {
			...room,
			...roomUpdate,
		}
		return editRoomApi(storeId, encodedRoom).then(room => {
			room && dispatch(slice.actions._replaceRoom({ storeId, room }))
		})
	}

const checklNewInstrumentsInRoom =
	(storeId: string, stageId: Id, roomId: Id): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) =>
		getStagesApi(storeId).then(stages => {
			const state = getState()
			const room = selectRoom(roomId, storeId)(state)
			const roomKeys = keys(room)

			const updatedRoom =
				(stages &&
					stages
						.find(({ _id }) => _id === stageId)
						?.rooms.find(({ _id }) => _id === roomId)) ||
				undefined
			const updatedRoomKeys = keys(updatedRoom || {})

			const sameInstruments =
				updatedRoomKeys.length === roomKeys.length &&
				updatedRoomKeys.every(updatedKey => roomKeys.includes(updatedKey))

			if (updatedRoom && !sameInstruments) {
				dispatch(storesActions._replaceRoom({ storeId, room: updatedRoom }))
			}
		})

const deleteDocumentAction =
	(storeId: string, id: Id): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		deleteStoreDocumentApi(storeId, id).then(store => {
			store && dispatch(slice.actions._setStore(store))
		})

const removeInstrumentFromRoomAction =
	({
		storeId,
		room,
		instrumentsToRemove,
	}: {
		storeId: Id
		room: Room
		instrumentsToRemove: InstrumentType[]
	}): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		removeInstrumentFromRoomApi(storeId, room, instrumentsToRemove).then(
			room => {
				room && dispatch(slice.actions._replaceRoom({ storeId, room }))
			},
		)

const addStoreDocumentAction =
	(storeId: string, document: CreateDocumentPayload): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		addStoreDocumentApi(storeId, document).then(store => {
			store && dispatch(slice.actions._setStore(store))
		})

const updateStoreDocumentAction =
	(
		storeId: string,
		documentId: string,
		documentData: UpdateDocumentPayload,
	): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		updateStoreDocumentApi(storeId, documentId, documentData).then(store => {
			store && dispatch(slice.actions._setStore(store))
		})

export const getPrivacyPolicyPdf =
	(name: string, fileRef: string): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const safeStoreid = selectStoreId(state)
		getPrivacyPolicyPdfApi(safeStoreid, fileRef).then(doc => {
			dispatch(
				slice.actions._savePdfDocument({ name, storeId: safeStoreid, doc }),
			)
		})
	}

export const clearPrivacyPolicyFileUrl =
	(): AppThunk => (dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const storeId = selectStoreId(state)
		dispatch(slice.actions._clearFileUrl({ storeId }))
	}

export const deleteStore =
	(store: Store): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		deleteStoreApi(store._id).then(() => {
			dispatch(slice.actions._removeStore(store))
		})

export const updateStaff =
	(storeId: Id, body: UpdateStoreStaffBody): AppThunk =>
	(dispatch: TeloDispatch) =>
		updateStoreStaffAPI(storeId, body).then(store => {
			if (store) {
				dispatch(
					notificationsActions.addNotification({
						type: 'info',
						message: 'stores.updateStaffOK',
						autoClose: true,
						messageIsLabelKey: true,
					}),
				)
			} else {
				dispatch(
					notificationsActions.addNotification({
						type: 'info',
						message: 'errors.staff.update',
						autoClose: true,
						messageIsLabelKey: true,
					}),
				)
			}
			return store
		})

export const updateStoreExternalIds =
	(storeId: Id): AppThunk =>
	(dispatch: TeloDispatch) =>
		updateStoreExternalIdsApi(storeId).then(store => {
			dispatch(slice.actions._setStore(store))
		})

const storesActions = {
	...slice.actions,
	getStoreAction,
	createStoreAction,
	updateStoreAction,
	getAllStoresAction,
	lockDoctorRoomAction,
	lockInstrumentRoomAction,
	unlockRoomAction,
	loadInstrumentsRoomsAction,
	addStageAction,
	deleteStageAction,
	renameStageAction,
	addRoomAction,
	deleteRoomAction,
	renameRoomAction,
	updateRoomAction,
	checklNewInstrumentsInRoom,
	deleteDocumentAction,
	removeInstrumentFromRoomAction,
	addStoreDocumentAction,
	updateStoreDocumentAction,
	getPrivacyPolicyPdf,
	clearPrivacyPolicyFileUrl,
	deleteStore,
	updateStaff,
	updateStoreExternalIds,
}

export default storesActions
