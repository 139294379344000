import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { ICD10 } from '../model/icd10'
import { FilterParams, unversionedApiUrl } from './common'

const TAG = 'icd10-system-api'
const TAG_FAV = 'icd10-system-fav-api'

type Icd10Code = string

export const icd10Api = createApi({
	reducerPath: 'icd10Api',
	tagTypes: [TAG, TAG_FAV],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getIcd10ByCode: builder.query<ICD10, Icd10Code>({
			query: code => ({
				url: `/system/v2/icd10/find/Code/${code}`,
			}),
			providesTags: [TAG],
		}),
		getMultipleIcd10ByCode: builder.query<ICD10[], Icd10Code>({
			query: codes => ({
				url: `/system/v2/icd10?codes=${codes}`,
			}),
			providesTags: [TAG],
		}),
		getFilteredIcd10: builder.query<ICD10[], FilterParams>({
			query: ({
				filter,
				propNames = ['Code', 'Description'],
				favourites,
			}: FilterParams) => ({
				url: !favourites
					? `/system/v2/icd10/paginated?q=${encodeURIComponent(
							filter,
					  )}&propNames=${propNames.join(',')}`
					: '/system/v2/icd10/favourites',
			}),
			providesTags: (result, error, query) => [
				query.favourites ? TAG_FAV : TAG,
			],
		}),
		setIcd10Favourite: builder.mutation<
			ICD10,
			{ code: string; favourite: boolean }
		>({
			query: ({ code, favourite }) => ({
				url: `/v1/users/icd10/${code}/favourite`,
				method: 'PUT',
				body: {
					favourite,
				},
			}),
			invalidatesTags: [TAG, TAG_FAV],
		}),
	}),
})

export const {
	useGetIcd10ByCodeQuery,
	useGetFilteredIcd10Query,
	useSetIcd10FavouriteMutation,
	useGetMultipleIcd10ByCodeQuery,
} = icd10Api
