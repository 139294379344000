import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id } from '../../model/model'
import {
	Signature,
	SignRequest,
	SignRequestsState,
	SignRequestType,
} from '../../model/signRequests'

const initialState: SignRequestsState = []

export const slice = createSlice({
	name: 'signRequests',
	initialState,
	reducers: {
		setRequestStatus: (state, { payload }: PayloadAction<SignRequest>) => {
			const infoInStateIndex = state.findIndex(
				({ examId, type, orderId }) =>
					examId === payload.examId &&
					type === payload.type &&
					orderId === payload.orderId,
			)

			if (infoInStateIndex !== -1) {
				state[infoInStateIndex].active = payload.active
			} else {
				state.push(payload)
			}
		},
		setSignature: (
			state,
			{
				payload,
			}: PayloadAction<{
				type: SignRequestType
				examId: Id
				signature: Signature
				orderId?: Id
			}>,
		) => {
			const infoInStateIndex = state.findIndex(
				({ examId, type, orderId }) =>
					examId === payload.examId &&
					type === payload.type &&
					orderId === payload.orderId,
			)

			if (infoInStateIndex !== -1) {
				state[infoInStateIndex].signature = payload.signature
				state[infoInStateIndex].active = false
			} else {
				throw new Error(
					`No SignRequestStatus data of type ${
						payload.type
					} if found for exam ${payload.examId} and order ${
						payload.orderId ?? ''
					}. Are you sure that the "setRequestStatus" action was dispatched?`,
				)
			}
		},
		setLocalSignature: (
			state,
			{
				payload,
			}: PayloadAction<{
				type: SignRequestType
				examId: Id
				signature: Signature
				orderId?: Id
			}>,
		) => {
			const infoInStateIndex = state.findIndex(
				({ examId, type, orderId }) =>
					examId === payload.examId &&
					type === payload.type &&
					orderId === payload.orderId,
			)
			const { type, examId, signature, orderId } = payload
			const newState = {
				type,
				examId,
				signature,
				active: false,
				orderId: orderId,
			}

			if (infoInStateIndex !== -1) {
				state[infoInStateIndex] = newState
			} else {
				state.push(newState)
			}
		},
		clear: (
			state,
			{
				payload,
			}: PayloadAction<{ type: SignRequestType; examId: Id; orderId?: Id }>,
		) =>
			(state = state.filter(
				({ examId, type, orderId }) =>
					examId !== payload.examId &&
					(!payload.orderId || orderId !== payload.orderId) &&
					type !== payload.type,
			)),
		_savePdfDocument: (
			state,
			{
				payload,
			}: PayloadAction<{
				type: SignRequestType
				examId: Id
				doc: Blob | string
				orderId?: Id
			}>,
		) => {
			const infoInStateIndex = state.findIndex(
				({ examId, type, orderId }) =>
					examId === payload.examId &&
					type === payload.type &&
					orderId === payload.orderId,
			)

			if (infoInStateIndex !== -1) {
				state[infoInStateIndex].blobDocument = payload.doc
			} else {
				state.push({
					examId: payload.examId,
					active: false,
					blobDocument: payload.doc,
					type: payload.type,
					orderId: payload.orderId,
				})
			}
		},
	},
})

export default slice.reducer
