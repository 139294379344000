import { createContext, useCallback, useContext } from 'react'
import {
	NavigateFunction,
	NavigateOptions,
	Path,
	To,
	useNavigate,
} from 'react-router-dom'

export const TeloNavigationContext = createContext<NavigateFunction | null>(
	null,
)

export type OnNavigationRequestFn = (
	navigate: NavigateFunction,
	toPath: string,
) => void

export const useCreateTeloNavigate = (
	onNavigationRequest?: OnNavigationRequestFn,
): NavigateFunction => {
	// this is where useNavigate is wrapped by useTeloNavigate, so we must use useNavigate
	// eslint-disable-next-line telo/no-use-navigate
	const navigate = useNavigate()

	return useCallback(
		(to: To | number, options: NavigateOptions = {}) => {
			if (typeof to === 'number') {
				navigate(to)
			} else if (onNavigationRequest) {
				onNavigationRequest(navigate, toPath(to))
			} else {
				navigate(to, options)
			}
		},
		[navigate, onNavigationRequest],
	)
}

export const useTeloNavigate = (): NavigateFunction => {
	const teloNavigate = useContext(TeloNavigationContext)
	if (!teloNavigate) {
		throw new Error('Cannot use telo navigation outside a context')
	}

	return teloNavigate
}

function toPath(to: To): string {
	if (typeof to === 'string') {
		return to
	}
	const asPath = to as Partial<Path>
	if (!asPath?.pathname) {
		throw new Error('Cannot navigate to an empty pathname')
	}
	return asPath.pathname
}
