import { NotificationType } from '../../model/model'

export const notificationType: Record<NotificationType, NotificationType> = {
	error: 'error',
	success: 'success',
	info: 'info',
} as const

export const queueSnackbarClasses = {
	containerAnchorOriginTopRight: `queue-containerAnchorOriginTopRight`,
}
