import styled from 'styled-components'

import { DialogActions as MDialogActions } from '@mui/material'

import theme from '../theme'

const DialogActions = styled(MDialogActions)<{
	justifycontent?: 'space-around' | 'flex-end' | 'center' | 'space-between'
}>`
	justify-content: ${({ justifycontent }) =>
		justifycontent !== undefined ? justifycontent : `space-around`};
	text-transform: uppercase;

	.MuiButton-text * {
		color: ${theme.palette.violet[900]};
		font-weight: bold;
	}

	.MuiButton-text.Mui-disabled * {
		opacity: 0.5;
		color: ${theme.palette.grey[500]};
	}
`

export default DialogActions
