import { TeloDispatch, TeloGetState } from '../../../store'
import notificationsActions, {
	AddNotificationPayload,
} from '../../notifications/actions'
import { selectStore } from '../../stores/selectors'
import {
	selectIsDoctor,
	selectIsLocalTechnician,
	selectUser,
} from '../../users/selectors'
import { AddNotificationFn } from '../teloSocketTypes'
import {
	connectToSocketNotifications,
	disconnectFromSocketNotifications,
	getNotificationsSender,
} from './notificationsSocket'

export const connectToNotifications =
	() => (dispatch: TeloDispatch, getState: TeloGetState) => {
		const sessionUsername = selectUser(getState())?.username
		const isDoctor = selectIsDoctor(getState())
		const isLocalTechnician = selectIsLocalTechnician(getState())
		const currentStore = selectStore(getState())

		const addNotification: AddNotificationFn = (
			notification: AddNotificationPayload,
		) => {
			dispatch(notificationsActions.addNotification(notification))
		}

		connectToSocketNotifications({
			addNotification,
			currentStore: currentStore ?? null,
			isDoctor,
			isLocalTechnician,
			sessionUsername: sessionUsername ?? null,
		})
	}

export const disconnectFromNotifications = () => () => {
	disconnectFromSocketNotifications()
}

export const sendDoctorStartedNotification = (username: string) => () => {
	const sender = getNotificationsSender()
	sender?.sendDoctorStartedNotification(username)
}

export const sendNewExamNotification =
	(username: string, isRemote: boolean) => () => {
		const sender = getNotificationsSender()
		sender?.sendNewExamNotification(username, isRemote)
	}
