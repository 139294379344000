import { Id, RootState } from '../../model/model'

export const selectPrescription = (prescriptionId: Id) => (state: RootState) =>
	state.prescriptions.list[prescriptionId]

export const selectPrescriptionByExamId = (examId?: Id) => (state: RootState) =>
	Object.values(state.prescriptions.list).find(
		prescription => prescription.examId === examId,
	)

export const selectPrescriptionDataTemp =
	(examId: string) => (state: RootState) =>
		state.prescriptions.dataTemp?.[examId] ?? undefined

export const selectPrescriptionClDataTemp =
	(examId: string) => (state: RootState) =>
		state.prescriptions.dataTemp?.[examId]?.ContactLenses ?? undefined

export const selectIsSharing =
	(key: string, index: number) => (state: RootState) =>
		state.prescriptions.inShare === `${key}${index}` &&
		state.cockpit.sharingOn === key

export const selectIsDirty = () => (state: RootState) =>
	!!state.prescriptions.isDirty

export const selectHasErrors = () => (state: RootState) =>
	!!state.prescriptions.hasErrors

export const selectIsDemographicChanged = (state: RootState) =>
	!!state.prescriptions.demographicChanged

export const selectFollowUpData = (examId: string) => (state: RootState) =>
	state.prescriptions.followUpData?.[examId] || undefined
