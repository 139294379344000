import { createApi, FetchArgs } from '@reduxjs/toolkit/dist/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { Modifier } from '../model/exam'

const TAG = 'modifier'

const getModifierByValueQuery = (value: string): FetchArgs => ({
	url: `/v1/modifiers?q=${value}`,
})

export const modifierApi = createApi({
	reducerPath: 'modifierApi',
	tagTypes: [TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getModifiers: builder.query<Modifier[], string>({
			query: getModifierByValueQuery,
			providesTags: [TAG],
		}),
	}),
})

export const { useGetModifiersQuery } = modifierApi
