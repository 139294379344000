import { generateUID } from '../../libs/uuid'
import { Notification } from '../../model/model'
import { TeloDispatch, TeloGetState } from '../../store'
import errorsActions from '../errors/actions'
import { selectNotification } from './selectors'
import { slice } from './slice'

const AUTOCLOSE_DELAY = 5000

export interface AddNotificationPayload extends Omit<Notification, '_id'> {
	id?: string
}

const addNotification =
	(notification: AddNotificationPayload) => (dispatch: TeloDispatch) => {
		// Clear out colored notifications first
		// To avoid dense shadow effect
		if (notification.colored) {
			dispatch(slice.actions._removeColoredNotifications())
		}

		const _id = notification.id || generateUID()

		dispatch(slice.actions._addNotification({ _id, ...notification }))
		if (notification.autoClose) {
			setTimeout(() => {
				dispatch(slice.actions._removeNotification(_id))
			}, notification.autoCloseDelay || AUTOCLOSE_DELAY)
		}
	}

const removeNotification =
	(notificationId: string) =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const notification = selectNotification(notificationId)(state)
		notification?.errorId &&
			notification.errorType === 'ui' &&
			dispatch(errorsActions.removeUiError(notification.errorId))
		notification?.errorId &&
			notification.errorType === 'http' &&
			dispatch(errorsActions.removeHttpError(notification.errorId))

		dispatch(slice.actions._removeNotification(notificationId))
	}

const notificationsActions = {
	removeNotification,
	addNotification,
}

export default notificationsActions
