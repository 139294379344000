import React, { useState } from 'react'
import { selectUsername, selectUserRole } from '../features/auth/selectors'
import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import examsActions from '../features/exams/actions'
import { getUserDashboard } from '../libs/auth'
import { AbortDialogType } from '../model/dialog'
import Input from './../styleguide/Input'
import styled from 'styled-components'
import theme from '../styleguide/theme'
import QuitExamDialog from './Prescription/RX/QuitExamDialog'
import { selectStoreId } from '../features/app/selectors'
import { useTeloDispatch, useTeloSelector } from '../store'
import { useTeloNavigate } from '../routing/teloNavigate'

const StyledInput = styled(Input)`
	margin-bottom: ${theme.spacing(4)};
`

const isAbortDialog = (dialog: any): dialog is AbortDialogType =>
	typeof dialog === 'object' && dialog.examId

const AbortDialog = () => {
	const dispatch = useTeloDispatch()
	const navigate = useTeloNavigate()
	const dialog = useTeloSelector(selectDialog)
	const userRole = useTeloSelector(selectUserRole)
	const username = useTeloSelector(selectUsername)
	const open = isAbortDialog(dialog)
	const examId = isAbortDialog(dialog) ? dialog.examId : ''
	const [reason, setReason] = useState('')
	const storeId = useTeloSelector(selectStoreId)

	const handleAbortExam = async (generateChart?: boolean) => {
		if (!examId || !isAbortDialog(dialog) || !reason) {
			return
		}

		await dispatch(
			examsActions.setExamStatus({
				id: examId,
				status: 'Interrupted',
				username: username,
				reason: `${reason}`,
				interruptedByRole: `${userRole}`,
			}),
		)

		generateChart && (await dispatch(examsActions.generateChart(dialog.examId)))
		dispatch(dialogActions.closeDialog())
		navigate(getUserDashboard(userRole, storeId))
	}

	const abortReasonInput = (
		<StyledInput
			multiline
			rows={4}
			fullWidth
			id="reason"
			required
			onChange={e => {
				setReason(e.currentTarget.value)
			}}
		/>
	)

	return !isAbortDialog(dialog) ? null : (
		<QuitExamDialog
			closeDialog={() => dispatch(dialogActions.closeDialog())}
			open={open}
			confirmDialog={handleAbortExam}
			examId={examId}
			isAbort
			abortReason={abortReasonInput}
		/>
	)
}

export default AbortDialog
