import jwt_decode from 'jwt-decode'
import { NavigateFunction } from 'react-router-dom'
import { doLogoutApi, getLoginTokenApi } from '../../apiCalls'
import appConfig from '../../config'
import { decodeRole } from '../../decoders/role'
import { redirectToNam } from '../../libs/auth'
import { isHttpError } from '../../libs/fetchJson'
import {
	deleteEclipsMetadata,
	delIntersessionInfo,
	getEclipsMetadata,
	setIntersessionAppLoginData,
} from '../../libs/intersession'
import { HttpError, LoginResponse, LoginToken } from '../../model/model'
import { AppThunkPromise, TeloDispatch } from '../../store'
import azureCommunicationActions from '../azureCommunication/actions'
import errorsActions from '../errors/actions'
import { deleteIntersessionRoomDevice } from './../../libs/intersession'
import { slice } from './slice'

const convertErrorToText = (error: unknown): string => {
	if (typeof error === 'string') {
		return error
	}

	if (error instanceof Error) {
		return error.message
	}

	return 'Unknown error'
}

const getLoginTokenAction =
	(
		code: string,
		redirectUri: string,
		navigate: NavigateFunction,
		eclipsUsername?: string,
	) =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions.setAppLoginStatus('logging in'))
		getLoginTokenApi(code, redirectUri, eclipsUsername)
			.then((decodedRes: LoginResponse | HttpError) => {
				if (isHttpError(decodedRes)) {
					if (eclipsUsername !== undefined) {
						navigate('/logout')
						return
					}
					const isMissingUserError =
						decodedRes.status === 403 &&
						'message' in decodedRes &&
						typeof decodedRes.message === 'string' &&
						decodedRes.message.includes('is not a Connect User')
					return isMissingUserError
						? dispatch(authActions.setMissingUserError(decodedRes.message))
						: dispatch(errorsActions.setHttpError(decodedRes))
				}

				dispatch(errorsActions.resetLoginError())
				const token = decodedRes.token
				const decodedToken = jwt_decode(token) as LoginToken
				const expirationDate = new Date(decodedToken.exp * 1000).toString()
				const role = decodeRole(decodedToken.role)
				const appLoginData = {
					token,
					expirationDate,
					role,
					eclipsUserVerified: eclipsUsername !== undefined ? true : false,
					authenticationError: false,
					missingUserError: '',
				}
				dispatch(slice.actions.setAppLoginData(appLoginData))
				setIntersessionAppLoginData(appLoginData)
			})
			.catch((error: unknown) => {
				dispatch(
					errorsActions.setLoginError({
						message: 'unauthorized',
						details: convertErrorToText(error),
					}),
				)
			})
	}

const logoutAction =
	(preserveEclipsData: boolean = false): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		const eclipsMetadata = getEclipsMetadata()
		const isAppInsideEclips = !!eclipsMetadata

		console.log(
			'logoutAction, doing logout. preserveEclipsData:',
			preserveEclipsData,
			'. isAppInsideEclips:',
			isAppInsideEclips,
		)
		const logoutPromise =
			isAppInsideEclips !== true ? doLogoutApi() : Promise.resolve()

		return logoutPromise
			.catch(() => {
				// if token is expired the logout will fail with 401, just ignore it
			})
			.finally(() => {
				if (preserveEclipsData === false) {
					deleteEclipsMetadata()
				}
				console.log(
					'logoutAction, calling deleteIntersessionRoomDevice. preserveEclipsData:',
					preserveEclipsData,
				)
				delIntersessionInfo()
				deleteIntersessionRoomDevice()
				dispatch(azureCommunicationActions.resetAzureCommunication())
				if (isAppInsideEclips !== true) {
					window.location.href = appConfig.logout
				} else {
					redirectToNam()
				}
			})
	}

const authActions = {
	...slice.actions,
	getLoginTokenAction,
	logoutAction,
}

export default authActions
