import { ActiveAlerts } from "../../model/model";

export const initialActiveAlerts: ActiveAlerts = {
	LOW: {
		enabled: false,
		list: [],
	},
	MEDIUM: {
		enabled: false,
		list: [],
	},
	HIGH: {
		enabled: false,
		list: [],
	},
}

export const alertsSliceName = 'alerts'
