import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { forceRefreshSelector } from '../features/appointments/selectors'
import { selectWorklistFilters } from '../features/worklist/selectors'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { FIVE_MINUTES } from '../libs/time'
import { parseWorklistFiltersToQuery } from '../libs/worklist'
import { WorklistExam, WorklistExamsQuery } from '../model/exam'
import { RootState } from '../model/model'
import { useTeloSelector } from '../store'
import { unversionedApiUrl } from './common'

type WorklistExamsQueryResult = {
	exams: WorklistExam[]
	page: number
	pageSize: number
	totalResults: number
}

export const WORKLIST_CACHE_TAG = 'worklist'

export const worklistApi = createApi({
	reducerPath: 'worklistApi',
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}/v3`),
	refetchOnMountOrArgChange: true,
	tagTypes: [WORKLIST_CACHE_TAG],
	endpoints: builder => ({
		getWorklist: builder.query<WorklistExamsQueryResult, WorklistExamsQuery>({
			queryFn(arg, queryApi, extraOptions, baseQuery) {
				const state = queryApi.getState() as RootState
				const forceRefresh = forceRefreshSelector(state)

				return baseQuery({
					url: `/worklist?${qs.stringify({
						...arg,
						pageSize: 10,
						forceRefresh,
					})}`,
					headers: {
						'x-store': arg.storeId,
					},
				}) as any
			},
			extraOptions: { maxRetries: 1 },
			providesTags: [WORKLIST_CACHE_TAG],
		}),
	}),
})

export const useGetWorklistQuery = (
	storeId: string | undefined,
	page: number,
) => {
	const worklistFilters = useTeloSelector(selectWorklistFilters)

	const filtersToApply: WorklistExamsQuery = {
		...parseWorklistFiltersToQuery(worklistFilters),
		storeId,
		page,
	}

	return worklistApi.useGetWorklistQuery(filtersToApply, {
		pollingInterval: FIVE_MINUTES,
	})
}
