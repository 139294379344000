import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Optional } from '../../utils/utilityTypes'

export type Params = Record<string, Optional<string>>
const params: Params = {}
const initialState = {
	location: {
		pathname: window.location.pathname,
		search: window.location.search,
		hash: window.location.hash,
		key: '',
	},
	params,
}

export const slice = createSlice({
	name: 'router',
	initialState,
	reducers: {
		setLocation: (
			state,
			{
				payload,
			}: PayloadAction<{
				pathname: string
				search: string
				hash: string
				key: string
			}>,
		) => {
			state.location = payload
		},
		setParams: (state, { payload }: PayloadAction<Params>) => {
			state.params = payload
		},
	},
})

export default slice.reducer
