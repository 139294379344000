import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { selectContactLensesCatalog } from '../features/contactLenses/selectors'
import { pxToRem } from '../libs/style'
import { convertDigitToWord } from '../libs/utils'
import { CatalogueId } from '../model/exam'
import theme from '../styleguide/theme'
import FirstLetterCapital from '../styleguide/typography/FirstLetterCapital'
import React from 'react'
import { useTeloSelector } from '../store'

const FirstParagraph = styled(FirstLetterCapital)`
	margin-bottom: ${theme.spacing(4)};
	display: block;
`

const Products = styled.ul`
	list-style-type: none;
	padding-left: 0;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: ${theme.spacing(4)};
`

const StyledFirstCapital = styled(FirstLetterCapital)`
	margin-bottom: ${theme.spacing(4)};
`

type Props = {
	products: CatalogueId[]
}

const QuitWithPrescribedCL: React.FC<Props> = ({ products }) => {
	const { t } = useTranslation()
	const selectedLenses = useTeloSelector(selectContactLensesCatalog)

	return (
		<>
			<FirstParagraph>
				{t('doctor.productInPrescribedState').replace(
					'%%1',
					`${convertDigitToWord(products.length)} ${
						products.length > 1 ? t('doctor.products') : t('doctor.product')
					}`,
				)}
			</FirstParagraph>
			<Products>
				{products.map((product, index) => {
					const productData = selectedLenses.find(
						lensData => lensData._id === product.OD,
					)
					if (!productData) {
						return null
					}
					return (
						<li key={`${product}_${index}`}>
							{productData.vendor} - {productData.brand}
						</li>
					)
				})}
			</Products>
			<StyledFirstCapital>{t('app.abortDialogTextRow2')}</StyledFirstCapital>
		</>
	)
}

export default QuitWithPrescribedCL
