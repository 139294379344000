import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { decodeRole } from '../../decoders/role'
import { UsersState } from '../../model/model'
import { ApiUser } from '../../model/users'

const initialState: UsersState = {}

export const slice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		_loadUser: (state, { payload }: PayloadAction<ApiUser>) => {
			state[payload.username] = {
				...payload,
				role: decodeRole(payload.role),
			}
		},
		_loadUsers: (_, { payload }: PayloadAction<ApiUser[]>) =>
			payload.reduce((result, user) => {
				result[user.username] = { ...user, role: decodeRole(user.role) }
				return result
			}, {} as UsersState),
		_removeUser: (state, { payload }: PayloadAction<string>) => {
			delete state[payload]
		},
	},
})

export default slice.reducer
