export enum FileExtension {
	pdf = 'pdf',
	png = 'png',
	jpg = 'jpg',
	jpeg = 'jpeg',
	webp = 'webp',
	mp4 = 'mp4',
	dcm = 'dcm',
}

export const imageFileExtensions: Extract<
	FileExtension,
	'jpg' | 'jpeg' | 'png' | 'webp'
>[] = [
	FileExtension.jpg,
	FileExtension.jpeg,
	FileExtension.png,
	FileExtension.webp,
]
