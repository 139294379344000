import { Id } from '../../../model/model'
import { TeloDispatch } from '../../../store'
import notificationsActions, {
	AddNotificationPayload,
} from '../../notifications/actions'
import visualSimulationActions from '../../visualSimulation/actions'
import { AddNotificationFn } from '../teloSocketTypes'
import {
	connectToSocketVisualSimulation,
	getVisualSimulationEmitter,
} from './visualSimulationSocket'
import { ShareFn } from './visualSimulationTypes'

export const connectToVisualSimulation =
	(examId: Id) => (dispatch: TeloDispatch) => {
		if (!examId || !examId.length) {
			return
		}

		const addNotification: AddNotificationFn = (
			notification: AddNotificationPayload,
		) => {
			dispatch(notificationsActions.addNotification(notification))
		}

		const share: ShareFn = (active: boolean) => {
			dispatch(
				visualSimulationActions.share({
					examId,
					active,
				}),
			)
		}

		connectToSocketVisualSimulation({
			addNotification,
			examId,
			share,
		})
	}

export const emitVisualSimulationShare = (active: boolean) => () => {
	const emitter = getVisualSimulationEmitter()
	emitter?.emitShare(active)
}
