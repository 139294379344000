import { AddNotificationPayload } from '../features/notifications/actions'

export const socketCommonOptions = {
	transports: ['websocket'],
	reconnectionAttempts: 5,
	reconnectionDelay: 1000,
	reconnectionDelayMax: 20000,
}

export const socketReconnectionFailedNotification: AddNotificationPayload = {
	type: 'error',
	message:
		"The real time communication with the server is not working. Please try to reload the application. If that doesn't work contact the support service",
	autoClose: false,
	id: 'socket-reconnection-failed',
}
