import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { convertDateForQueryString } from '../libs/time'
import {
	AppointmentType,
	CreateFhirAppointmentPayload,
} from '../model/appointment'
import { unversionedApiUrl } from '../services/common'

const AppointmentType_TAG = 'appointmentTypeApi'

export const appointmentTypeApi = createApi({
	reducerPath: 'appointmentTypeApi',
	tagTypes: [AppointmentType_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getAppointmentList: builder.query<AppointmentType[], string>({
			query: id => ({
				url: `/v1/practices/${id}/appointmenttypes`,
			}),
			providesTags: [AppointmentType_TAG],
			extraOptions: { maxRetries: 1 },
		}),
		createAppointment: builder.mutation<unknown, CreateFhirAppointmentPayload>({
			query: payload => ({
				url: '/v1/fhir-appointments',
				method: 'POST',
				body: JSON.stringify({
					...payload,
					date: convertDateForQueryString(new Date()),
				}),
			}),
		}),
	}),
})

export const { useGetAppointmentListQuery, useCreateAppointmentMutation } =
	appointmentTypeApi
