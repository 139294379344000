import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { Id } from '../model/model'
import { ApiUser, Role, User } from '../model/users'
import { unversionedApiUrl } from './common'

const STORE_USERS = 'storeStaff'

export type TechniciansResult = {
	username: string
	fullName: string
	role: Role
}

export const usersApi = createApi({
	reducerPath: 'usersApi',
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	tagTypes: [STORE_USERS],
	endpoints: builder => ({
		getUserByUsername: builder.query<
			{ name: string; surname: string; role: Role },
			{ username: string }
		>({
			query: ({ username }) => ({
				url: `/v1/users/find-light/${username}`,
			}),
		}),
		getUsersByStore: builder.query<
			TechniciansResult[],
			{ storeId: Id; roles: Role[] }
		>({
			query: ({ storeId, roles }) => ({
				url: `/v1/users?storeId=${storeId}&roles=${roles.join(',')}`,
			}),
			providesTags: [STORE_USERS],
			transformResponse: (res: ApiUser[]) => {
				return res.map(u => ({
					username: u.username,
					fullName: `${u.name} ${u.surname}`,
					role: u.role,
				}))
			},
		}),
	}),
})

export const { useGetUserByUsernameQuery, useGetUsersByStoreQuery } = usersApi
