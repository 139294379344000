import { DiagnosiPlan } from '../model/diagnosiPlans'
import { OcularHealthData } from '../model/manual'
import {
	OcularHealthAnteriorSegment,
	OcularHealthCondition,
	OcularHealthFundus,
	OcularHealthOpticNerve,
} from '../model/ocularHealthCondition'

export const formatConditionsDropdownOptions = (
	conditions?: OcularHealthCondition[],
) => {
	const conditionsFormatted = conditions
		? conditions.map(condition => ({
				label: condition.name,
				value: condition._id!,
		  }))
		: []

	return conditionsFormatted
}

export const formatConditionValues = (
	opticalNerv: OcularHealthOpticNerve | undefined,
	fundus: OcularHealthFundus | undefined,
	videoSliltLamp: OcularHealthAnteriorSegment | undefined,
	replace: boolean,
	formValues: OcularHealthData & { replaceValues: string },
) => {
	const filledValues = {
		...formValues,
		opticalNerv: fillValues(
			formValues['opticalNerv'],
			opticalNerv,
			'opticalNerv',
			replace,
		) as OcularHealthData['opticalNerv'],
		fundus: fillValues(
			formValues['fundus'],
			fundus,
			'fundus',
			replace,
		) as OcularHealthData['fundus'],
		videoSliltLamp: fillValues(
			formValues['videoSliltLamp'],
			videoSliltLamp,
			'videoSliltLamp',
			replace,
		) as OcularHealthData['videoSliltLamp'],
	}
	return filledValues
}

export const shouldShowDialog = (
	fundus: OcularHealthData['fundus'],
	opticalNerv: OcularHealthData['opticalNerv'],
	videoSlitLamp: OcularHealthData['videoSliltLamp'],
) => {
	const filledFundus = checkFilledFields(fundus)
	const filledOpticalNerve = checkFilledFields(opticalNerv)
	const filledSlitLamp = checkFilledFields(videoSlitLamp)

	return filledFundus || filledOpticalNerve || filledSlitLamp
}

export const checkFilledFields = (ocularHealthValues: {
	OD: { [key: string]: string | undefined }
	OS: { [key: string]: string | undefined }
}) => {
	const filledOD = Object.values(ocularHealthValues.OD).some(value => !!value)
	const filledOS = Object.values(ocularHealthValues.OS).some(value => !!value)

	return filledOD || filledOS
}

const fillValues = (
	formValues: OcularHealthData['opticalNerv' | 'videoSliltLamp' | 'fundus'],
	conditionValues:
		| OcularHealthOpticNerve
		| OcularHealthFundus
		| OcularHealthAnteriorSegment
		| undefined,
	name: 'opticalNerv' | 'videoSliltLamp' | 'fundus',
	replace: boolean,
) => {
	if (!conditionValues) {
		return { ...formValues }
	}

	const filledOD = Object.keys(formValues.OD).reduce((prev, key) => {
		const conditionValue = conditionValues.OD
			? conditionValues.OD[key as keyof typeof conditionValues.OD]
			: ''
		const formValue = formValues.OD[key as keyof typeof formValues.OD]

		if ((replace || !formValue) && key !== 'note') {
			return { ...prev, [key]: conditionValue ?? '' }
		}

		return { ...prev, [key]: formValue }
	}, {})

	const filledOS = Object.keys(formValues.OS).reduce((prev, key) => {
		const conditionValue = conditionValues.OS
			? conditionValues.OS[key as keyof typeof conditionValues.OD]
			: ''
		const formValue = formValues.OS[key as keyof typeof formValues.OS]

		if ((replace || !formValue) && key !== 'note') {
			return { ...prev, [key]: conditionValue ?? '' }
		}

		return { ...prev, [key]: formValue }
	}, {})

	return {
		...formValues,
		OD: filledOD,
		OS: filledOS,
	} as OcularHealthData[typeof name]
}

export const getPlanFromCondition = (
	diagnosiPlans: DiagnosiPlan[],
	code: string,
	username?: string,
) => {
	if (!username) {
		return ''
	}
	const customPlan = diagnosiPlans.find(d =>
		d.codes.some(dc => dc.code === code),
	)
	return customPlan === undefined
		? ''
		: customPlan.doctorHistory[username]?.description || customPlan.description
}
