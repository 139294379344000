import React from 'react'

import { Dialog as MDialog, DialogProps } from '@mui/material'
import styled from 'styled-components'
import theme from '../theme'

const MDialogStyled = styled(MDialog)`
	& .MuiPaper-root:not(.MuiPaper-white) {
		background-color: transparent;
	}

	& .MuiDialogTitle-root {
		background-color: #20233d;
		border-radius: 1rem 1rem 0 0;
		color: #fff;
		font-size: 1.125rem;
		font-style: normal;
		font-weight: 500;
		line-height: 120%; /* 1.35rem */
		letter-spacing: -0.01125rem;
	}

	& .MuiDialogContent-root {
		background-color: ${theme.palette.common.white};
		padding: 1.5rem;
	}

	& .MuiDialogActions-root {
		border-top: 1px solid ${theme.palette.grey[300]};
		background-color: ${theme.palette.common.white};
		padding: 1.5rem;
		justify-content: flex-end;
	}
`

const Dialog: React.FC<DialogProps> = ({ children, ...props }) => (
	<MDialogStyled
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		maxWidth="md"
		{...props}
	>
		{children}
	</MDialogStyled>
)
export default Dialog
