import React, { lazy } from 'react'
import { AdminStoreReferralDoctorPageProps } from '../pages/AdminStoreReferralDoctorPage'
import { LoginPageProps } from '../pages/LoginPage'
import { WaitRemote } from './WaitRemote'

const RemoteEclipsLoginPage = lazy(() => import('../pages/EclipsLoginPage'))
export const LazyEclipsLoginPage = () => {
	return (
		<WaitRemote>
			<RemoteEclipsLoginPage />
		</WaitRemote>
	)
}

const RemoteEclipsTokenCodePage = lazy(
	() => import('../pages/EclipsTokenCodePage'),
)
export const LazyEclipsTokenCodePage = () => {
	return (
		<WaitRemote>
			<RemoteEclipsTokenCodePage />
		</WaitRemote>
	)
}

const RemoteExamAttributionPage = lazy(
	() => import('../pages/ExamAttributionPage'),
)
export const LazyExamAttributionPage = () => {
	return (
		<WaitRemote>
			<RemoteExamAttributionPage />
		</WaitRemote>
	)
}

const RemoteAppointmentPage = lazy(() => import('../pages/AppointmentPage'))
export const LazyAppointmentPage = () => {
	return (
		<WaitRemote>
			<RemoteAppointmentPage />
		</WaitRemote>
	)
}

const RemoteLoginPage = lazy(() => import('../pages/LoginPage'))
export const LazyLoginPage = (props: LoginPageProps) => {
	return (
		<WaitRemote>
			<RemoteLoginPage {...props} />
		</WaitRemote>
	)
}

const RemoteExamPage = lazy(() => import('./ExamPageAsDefault'))
export const LazyExamPage = () => {
	return (
		<WaitRemote>
			<RemoteExamPage
				showLogo={true}
				shouldShowDoctorCard={true}
				showPageTitle={true}
				shouldShowHomeButton={true}
				showProfileMenu={true}
			/>
		</WaitRemote>
	)
}

const RemoteCallWidgetWrapper = lazy(
	() => import('../features/ui/CallWidgetWrapper'),
)
export const LazyCallWidgetWrapper = () => {
	return (
		<WaitRemote>
			<RemoteCallWidgetWrapper />
		</WaitRemote>
	)
}

const RemoteLogoutPage = lazy(() => import('../pages/LogoutPage'))
export const LazyLogoutPage = () => {
	return (
		<WaitRemote>
			<RemoteLogoutPage />
		</WaitRemote>
	)
}

const RemotePostLogoutPage = lazy(() => import('../pages/PostLogoutPage'))
export const LazyPostLogoutPage = () => {
	return (
		<WaitRemote>
			<RemotePostLogoutPage />
		</WaitRemote>
	)
}

const RemoteExamSearchedPage = lazy(
	() => import('../pages/ExamPages/ExamSearchedPage'),
)
export const LazyExamSearchedPage = () => {
	return (
		<WaitRemote>
			<RemoteExamSearchedPage />
		</WaitRemote>
	)
}

const RemoteRoomDeviceSelectionPage = lazy(
	() => import('../pages/RoomDeviceSelectionPage'),
)
export const LazyRoomDeviceSelectionPage = () => {
	return (
		<WaitRemote>
			<RemoteRoomDeviceSelectionPage />
		</WaitRemote>
	)
}

const RemoteStoreSelectionPage = lazy(
	() => import('../pages/StoreSelectionPage'),
)
export const LazyStoreSelectionPage = () => {
	return (
		<WaitRemote>
			<RemoteStoreSelectionPage />
		</WaitRemote>
	)
}

const RemoteWorklistPage = lazy(
	() => import('../pages/WorklistPage/WorklistPage'),
)
export const LazyWorklistPage = () => {
	return (
		<WaitRemote>
			<RemoteWorklistPage />
		</WaitRemote>
	)
}

const RemoteSearchPage = lazy(() => import('../pages/SearchPageFromRoute'))
export const LazySearchPage = () => {
	return (
		<WaitRemote>
			<RemoteSearchPage />
		</WaitRemote>
	)
}

const RemoteCheckInPageV2 = lazy(
	() => import('../pages/CheckInPageV2ParamsFromRoute'),
)
export const LazyCheckInPageV2 = () => {
	return (
		<WaitRemote>
			<RemoteCheckInPageV2 showHeader={true} />
		</WaitRemote>
	)
}

const RemotePatientList = lazy(() => import('../pages/PatientListFromRoute'))
export const LazyPatientList = () => {
	return (
		<WaitRemote>
			<RemotePatientList showHeader={true} />
		</WaitRemote>
	)
}

const RemoteNewAppointmentPage = lazy(
	() => import('../pages/NewFhirAppointmentPage/NewAppointmentPage'),
)
export const LazyNewAppointmentPage = () => {
	return (
		<WaitRemote>
			<RemoteNewAppointmentPage />
		</WaitRemote>
	)
}

const RemoteRescheduleAppointmentPage = lazy(
	() => import('../pages/RescheduleAppointmentPage'),
)
export const LazyRescheduleAppointmentPage = () => {
	return (
		<WaitRemote>
			<RemoteRescheduleAppointmentPage />
		</WaitRemote>
	)
}

const RemoteRefractionistDashboardPage = lazy(
	() => import('../pages/RefractionistDashboardPage'),
)
export const LazyRefractionistDashboardPage = () => {
	return (
		<WaitRemote>
			<RemoteRefractionistDashboardPage />
		</WaitRemote>
	)
}

const RemoteRenewalDashboardPage = lazy(
	() => import('../pages/PrescriptionRenewal/RenewalDashboardPage'),
)
export const LazyRenewalDashboardPage = () => {
	return (
		<WaitRemote>
			<RemoteRenewalDashboardPage />
		</WaitRemote>
	)
}

const RemoteRenewalSingleTestPage = lazy(
	() => import('../pages/PrescriptionRenewal/RenewalSingleTestPage'),
)
export const LazyRenewalSingleTestPage = () => {
	return (
		<WaitRemote>
			<RemoteRenewalSingleTestPage />
		</WaitRemote>
	)
}

const RemoteTokenCodePage = lazy(() => import('../pages/TokenCodePage'))
export const LazyTokenCodePage = () => {
	return (
		<WaitRemote>
			<RemoteTokenCodePage />
		</WaitRemote>
	)
}

const RemoteGrantsPage = lazy(() => import('../pages/GrantsPage'))
export const LazyGrantsPage = () => {
	return (
		<WaitRemote>
			<RemoteGrantsPage />
		</WaitRemote>
	)
}

const RemoteAdminInstrumentsInRoomPage = lazy(
	() => import('../pages/AdminInstrumentsInRoomPage'),
)
export const LazyAdminInstrumentsInRoomPage = () => {
	return (
		<WaitRemote>
			<RemoteAdminInstrumentsInRoomPage />
		</WaitRemote>
	)
}

const RemoteAdminPage = lazy(() => import('../pages/AdminPage'))
export const LazyAdminPage = () => {
	return (
		<WaitRemote>
			<RemoteAdminPage />
		</WaitRemote>
	)
}

const RemoteNewStoreAdminPage = lazy(
	() => import('../pages/NewStoreAdminPage/NewStoreAdminPage'),
)
export const LazyNewStoreAdminPage = () => {
	return (
		<WaitRemote>
			<RemoteNewStoreAdminPage />
		</WaitRemote>
	)
}

const RemoteNewOrEditPanelPage = lazy(
	() => import('../pages/NewOrEditPanelPage/NewOrEditPanelPage'),
)
export const LazyNewOrEditPanelPage = () => {
	return (
		<WaitRemote>
			<RemoteNewOrEditPanelPage />
		</WaitRemote>
	)
}

const RemotePracticeAdminPage = lazy(
	() => import('../pages/PracticeAdminPage/PracticeAdminPage'),
)
export const LazyPracticeAdminPage = () => {
	return (
		<WaitRemote>
			<RemotePracticeAdminPage />
		</WaitRemote>
	)
}

const RemoteUserAdminPage = lazy(() => import('../pages/UserAdminPage'))
export const LazyUserAdminPage = () => {
	return (
		<WaitRemote>
			<RemoteUserAdminPage />
		</WaitRemote>
	)
}

const RemoteNewUserPage = lazy(() => import('../pages/NewUserPage'))
export const LazyNewUserPage = () => {
	return (
		<WaitRemote>
			<RemoteNewUserPage />
		</WaitRemote>
	)
}

const RemoteAdminStoreReferralDoctorPage = lazy(
	() => import('../pages/AdminStoreReferralDoctorPage'),
)
export const LazyAdminStoreReferralDoctorPage = (
	props: AdminStoreReferralDoctorPageProps,
) => {
	return (
		<WaitRemote>
			<RemoteAdminStoreReferralDoctorPage {...props} />
		</WaitRemote>
	)
}

const RemoteStoreAdminPage = lazy(
	() => import('../pages/StoreAdminPage/StoreAdminPage'),
)
export const LazyStoreAdminPage = () => {
	return (
		<WaitRemote>
			<RemoteStoreAdminPage />
		</WaitRemote>
	)
}

/*const RemoteNewOrEditDiangosiPlanPage = lazy(
	() => import('../pages/DiagnosiPlansAdminPages/NewOrEditDiagnosiPlanPage'),
)
export const LazyNewOrEditDiangosiPlanPage = () => {
	return (
		<WaitRemote>
			<RemoteNewOrEditDiangosiPlanPage />
		</WaitRemote>
	)
}*/

const RemoteProfilePage = lazy(() => import('../pages/ProfilePage'))
export const LazyProfilePage = () => {
	return (
		<WaitRemote>
			<RemoteProfilePage />
		</WaitRemote>
	)
}

const RemotePatientAlertsPage = lazy(
	() => import('../pages/PatientAlertsPageFromRoute'),
)
export const LazyPatientAlertsPage = () => {
	return (
		<WaitRemote>
			<RemotePatientAlertsPage showHeader={true} />
		</WaitRemote>
	)
}
