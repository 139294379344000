import { memoize } from 'lodash'

import { ContactLensesCatalogData } from '../../model/exam'
import { RootState } from '../../model/model'

export const selectContactLensesCatalog = (
	state: RootState,
): ContactLensesCatalogData[] => state.contactLenses.catalog

export const selectContactLensData = memoize(
	(catalogId: string) => (state: RootState) =>
		state.contactLenses.catalog.find(({ _id }) => _id === catalogId),
)

export const selectSameProductBothEyesEnabledByTab = (state: RootState) => state.contactLenses.sameProductBothEyesEnabledByTab
