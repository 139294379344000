import { Field, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../../components/Header'
import StepperNavigaton from '../../components/StepperNavigation'
import { useTeloParams } from '../../features/router/useTeloParams'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { newAppointmentTypes } from '../../libs/appointments'
import { padTo2Digits } from '../../libs/time'
import { TimeSlotFhir } from '../../model/appointment'
import { useTeloNavigate } from '../../routing/teloNavigate'
import {
	BiggerSubtitle,
	FieldsWrapperGrid,
	PageWrapperFlex,
	PrevButtonFooter,
	SmallerPageTitle,
	StyledForm,
	TitleWrapperWithBorder,
} from '../../styleguide/CommonPageComponents'
import CheckboxGroup from '../../styleguide/forms/CheckboxGroup'
import Select from '../../styleguide/forms/Select'
import Grid from '../../styleguide/Grid'
import ChevronLeft from '../../styleguide/icons/ChevronLeft'
import CloseIcon from '../../styleguide/icons/CloseIcon'
import Stepper from '../../styleguide/Stepper'
import theme from '../../styleguide/theme'
import AppointmentPatientForm from './AppointmentPatientFrom'

const Text = styled.div`
	color: ${theme.palette.grey[500]};
	margin-bottom: 1.5rem;
`
const TimeSlotsButtons = styled(CheckboxGroup)`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
	grid-column-gap: ${theme.spacing(3)};
	grid-row-gap: ${theme.spacing(2)};
	${theme.breakpoints.down('xl')} {
		grid-column-gap: ${theme.spacing(1.5)};
		grid-row-gap: ${theme.spacing(1)};
	}
	.MuiButtonBase-root {
		width: 100%;
	}

	/* give every label the same with no matter if the hour is single or douple digit - fix 554 */
	.button--label {
		min-width: 4.25em;
	}
`

const StyledFieldsWrapperGrid = styled(FieldsWrapperGrid)`
	width: 100%;
`

const STEP_REASONWHY = 0
const STEP_DATESTEP = 1
const STEP_FIRSTDATA = 2

const NewAppointmentPage = () => {
	const { storeId } = useTeloParams<{
		storeId: string
	}>()
	const { t } = useTranslation()
	const navigate = useTeloNavigate()

	const reasonsWhyConst = newAppointmentTypes.map(value => ({
		value,
		label: t(`appointment.reasonsWhy.${value}`, {
			defaultValue: value,
		}),
	}))

	const [searchParams, setSearchParams] = useSearchParams()

	const appType = searchParams.get('appType')

	const activeStep = searchParams.get('step')
		? Number(searchParams.get('step'))
		: 0
	const setActiveStep = (step: number) => {
		searchParams.set('step', step.toString())
		setSearchParams(searchParams, { replace: true })
	}

	const selectedSlot = searchParams.get('slot')
	const setSelectedSlot = (slotValue: string) => {
		searchParams.set('slot', slotValue)
		setSearchParams(searchParams, { replace: true })
	}

	const { formatDate } = useDateFormatter()

	const FirstStepBackBtn = (
		<PrevButtonFooter
			type="button"
			variant="text"
			onClick={() => navigate(`/store/${storeId}/worklist`)}
			startIcon={<CloseIcon />}
		>
			{t('app.close')}
		</PrevButtonFooter>
	)

	const availableSlots = new Array(24).fill(undefined).map((_, index) => {
		return {
			h: 9 + Math.floor(index / 2),
			m: index % 2 === 0 ? 0 : 30,
		}
	})

	const buildDate = (slot: TimeSlotFhir) => {
		return `${padTo2Digits(slot.h % 12)}:${padTo2Digits(slot.m)} ${
			slot.h < 12 ? 'am' : 'pm'
		}`
	}

	const slots = availableSlots.map(slot => ({
		value: `${slot.h}.${slot.m}`,
		label: buildDate(slot),
	}))

	return (
		<PageWrapperFlex>
			<Header />
			<TitleWrapperWithBorder>
				<SmallerPageTitle>
					{t('appointment.newAppointmentPageTitle')}
				</SmallerPageTitle>
			</TitleWrapperWithBorder>
			<Stepper
				activeStep={activeStep}
				steps={[
					t('app.reasonWhy'),
					t('app.dateStep'),
					t('appointment.demographicData'),
				]}
			/>

			{activeStep === STEP_REASONWHY && (
				<Formik
					initialValues={{ appType: appType || '' }}
					validateOnChange={false}
					validateOnBlur={true}
					onSubmit={() => {
						setActiveStep(activeStep + 1)
					}}
				>
					{() => (
						<StyledForm noValidate>
							<BiggerSubtitle>{t('app.reasonWhy')}</BiggerSubtitle>
							<StyledFieldsWrapperGrid container>
								<Grid item md={4}>
									<Field
										name="appType"
										component={Select}
										fullWidth
										label={t('appointment.appointmentType')}
										options={reasonsWhyConst}
										onChangeValue={(selectedValue: string) => {
											searchParams.set('appType', selectedValue)
											setSearchParams(searchParams, { replace: true })
										}}
									/>
								</Grid>
							</StyledFieldsWrapperGrid>
							<StepperNavigaton
								activeStep={activeStep}
								numberOfSteps={3}
								disableNext={!appType}
								setActiveStep={setActiveStep}
								FirstStepBackBtn={FirstStepBackBtn}
							/>
						</StyledForm>
					)}
				</Formik>
			)}
			{activeStep === STEP_DATESTEP && (
				<Formik
					initialValues={{ timeSlot: [] }}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={() => {
						setActiveStep(activeStep + 1)
					}}
				>
					{({ values }) => (
						<StyledForm noValidate>
							<BiggerSubtitle>
								{t('appointment.date')}: {formatDate(new Date())}
							</BiggerSubtitle>

							<StyledFieldsWrapperGrid container>
								<Grid item md={12}>
									<Text>{t('appointment.slotsAvailable')}</Text>

									<TimeSlotsButtons
										name="timeSlot"
										multiSelectEntries={false}
										selectedEntries={values.timeSlot}
										totalEntries={slots}
										onSelectValue={e => {
											setSelectedSlot(e.value)
										}}
									/>
								</Grid>
							</StyledFieldsWrapperGrid>
							<StepperNavigaton
								activeStep={activeStep}
								numberOfSteps={3}
								disableNext={!selectedSlot}
								setActiveStep={setActiveStep}
								StepBackInheritBtn={
									<PrevButtonFooter
										type="button"
										variant="text"
										onClick={() => {
											setActiveStep(activeStep - 1)
										}}
										startIcon={<ChevronLeft />}
									>
										{t('app.back')}
									</PrevButtonFooter>
								}
							/>
						</StyledForm>
					)}
				</Formik>
			)}
			{activeStep === STEP_FIRSTDATA && (
				<AppointmentPatientForm
					renderSubmit={props => (
						<StepperNavigaton
							activeStep={activeStep}
							numberOfSteps={3}
							setActiveStep={setActiveStep}
							nextLabel={t('appointment.confirmAppointment')}
							{...props}
						/>
					)}
				/>
			)}
		</PageWrapperFlex>
	)
}

export default NewAppointmentPage
