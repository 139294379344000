import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from '../../../styleguide/buttons/Button'
import Dialog from '../../../styleguide/dialog/Dialog'
import DialogActions from '../../../styleguide/dialog/DialogActions'
import DialogContent from '../../../styleguide/dialog/DialogContent'

const DialogSubmit = styled(Button)`
	display: none;
`
type Props = {
	baseUrl: string
	rxInput: string
	show: boolean
	onClose: () => void
	onSave?: () => void
}
export const NewCropModal: React.FC<Props> = ({
	baseUrl,
	rxInput,
	show,
	onClose,
	onSave,
}) => {
	const { t } = useTranslation()

	const submitRef = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		setTimeout(() => {
			submitRef.current?.click()
		}, 1000)
	}, [])
	return (
		<>
			<Dialog open={show} fullScreen>
				<DialogContent>
					<form
						id="FormSubmitterAction"
						action={`${baseUrl}/ux2/InterfaceV7/RxEntry.aspx`}
						method="post"
						target="ext_iframe"
					>
						<input type="hidden" name="AdditionalAppearanceClass" value="" />
						<input type="hidden" name="RxInput" value={rxInput} />
						<input
							type="hidden"
							name="PostURL"
							value={`${baseUrl}/ux2/InterfaceV7/RxEntry.aspx`}
						/>
						<DialogSubmit type="submit" ref={submitRef}></DialogSubmit>
					</form>
					<iframe
						title="newCrop"
						name="ext_iframe"
						id="ext_iframe"
						width="100%"
						height="100%"
					></iframe>
				</DialogContent>
				<DialogActions justifycontent="center">
					<Button type="button" variant="text" onClick={() => onClose()}>
						{t('app.close')}
					</Button>
					{onSave !== undefined ? (
						<Button type="submit" variant="text" onClick={() => onSave()}>
							{t('app.save')}
						</Button>
					) : null}
				</DialogActions>
			</Dialog>
		</>
	)
}
