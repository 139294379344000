import React, { ReactNode, Suspense } from 'react'

import { StyledEngineProvider, ThemeProvider } from '@mui/material'

import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { SnackbarProvider } from 'notistack'

import DialogBox from './features/dialog/DialogBox'
import ErrorBoundary from './features/errors/ErrorBoundary'
import UIErrors from './features/errors/UIErrors'
import { queueSnackbarClasses } from './features/notifications/constants'
import NotificationArea from './features/notifications/NotificationArea'
import TeloRoutes from './Routes'
import { TeloNavigationContext } from './routing/teloNavigate'
import store from './store'
import CircularProgress from './styleguide/CircularProgress'
import Container from './styleguide/Container'
import GlobalStyle from './styleguide/globalStyle'
import theme from './styleguide/theme'
import { TeloLocalization } from './TeloLocalization'

const FullHeightContainer = styled(Container)`
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${theme.palette.grey[100]};

	~ .${queueSnackbarClasses.containerAnchorOriginTopRight} {
		top: 100px;
	}

	> div {
		&:first-child {
			height: 100%;
			padding: 0;
		}
	}
`

const queryClient = new QueryClient()

export const TeloApp = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<StyledEngineProvider injectFirst>
					<StyledThemeProvider theme={theme}>
						<ThemeProvider theme={theme}>
							<SnackbarProvider
								classes={{
									containerAnchorOriginTopRight:
										queueSnackbarClasses.containerAnchorOriginTopRight,
								}}
							>
								<GlobalStyle />
								<Suspense fallback={<CircularProgress />}>
									<FullHeightContainer maxWidth={false} disableGutters={true}>
										<TeloLocalization>
											<BrowserRouter>
												<WithTeloNavigation>
													<ErrorBoundary>
														<UIErrors>
															<TeloRoutes />
															<DialogBox />
														</UIErrors>
													</ErrorBoundary>
												</WithTeloNavigation>
											</BrowserRouter>
											<NotificationArea />
										</TeloLocalization>
									</FullHeightContainer>
								</Suspense>
							</SnackbarProvider>
						</ThemeProvider>
					</StyledThemeProvider>
				</StyledEngineProvider>
			</Provider>
		</QueryClientProvider>
	)
}

const WithTeloNavigation = ({ children }: { children?: ReactNode }) => {
	// this is the injection point, so we must use useNavigate
	// eslint-disable-next-line telo/no-use-navigate
	const navigate = useNavigate()
	return (
		<TeloNavigationContext.Provider value={navigate}>
			{children}
		</TeloNavigationContext.Provider>
	)
}
