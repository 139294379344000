import { InstrumentType } from '../model/instrumentsApi'
import { Id } from '../model/model'
import {
	useGetInstrumentData,
	usePollingAssessments,
	usePollingExamById,
	usePollingInstrumentsInRoom,
	usePollingStages,
} from './usePolling'
import {
	useSocketAssessments,
	useSocketExamById,
	useSocketExams,
	useSocketExamsInPanelStores,
	useSocketGetWarnings,
	useSocketInstrument,
	useSocketInstrumentsInRoom,
	useSocketPanel,
	useSocketStore,
	useSocketStoreExams,
	useSocketUser,
} from './useSocket'

export const useSyncDataUser = (username: string) => {
	useSocketUser(username)
}

export const useSyncDataStore = () => {
	usePollingStages()
	useSocketStore()
}

export const useSyncDataAssessments = (
	disableFetchAssessments: boolean = false,
) => {
	usePollingAssessments(disableFetchAssessments)
	useSocketAssessments(disableFetchAssessments)
}

export const useSyncDataExamById = (examId?: Id) => {
	usePollingExamById(examId)
	useSocketExamById(examId)
}

export const useSyncDataInstrument = (
	examId: Id,
	examLoaded: boolean,
	autoUpdate: boolean,
	instrumentType: InstrumentType,
) => {
	useGetInstrumentData(examId, examLoaded, autoUpdate, instrumentType)
	useSocketInstrument(examId, examLoaded, autoUpdate, instrumentType)
	useSocketGetWarnings(examId)
}

export const useSyncDataInstrumentsInRoom = (
	storeId: string,
	stageId: Id,
	roomId: Id,
) => {
	usePollingInstrumentsInRoom(storeId, stageId, roomId)
	useSocketInstrumentsInRoom(storeId, stageId, roomId)
}

export const useSyncWorklist = (storeId?: Id) => {
	useSocketStoreExams(storeId)
	useSocketExamsInPanelStores()
	useSocketPanel()
}
