import { RootState } from '../../model/model'

export const selectIsExamPage = (state: RootState) =>
	state.router.location.pathname.includes('/exam') ||
	state.router.location.pathname.includes('/call')

export const selectIsStoreSelectionPage = (state: RootState) => {
	return state.router.location.pathname.includes('/store-selection')
}
export const selectIsRenewalApp = (state: RootState) => {
	return state.router.location.pathname.includes('/renewal')
}
export const selectIsStoreSettingsPage = (state: RootState) =>
	state.router.location.pathname.includes('/store') &&
	state.router.location.pathname.includes('/settings')

export const selectIsWorklistPage = (state: RootState) =>
	state.router.location.pathname.includes('worklist')

export const selectRouterParams = (state: RootState) => state.router.params

export const selectIsAdminPage = (state: RootState) =>
	state.router.location.pathname.includes('/admin')

export const selectIsProfilePage = (state: RootState) =>
	state.router.location.pathname.includes('/profile')
