import qs from 'qs'

import { ExamQuery } from '../model/exam'

export enum ServiceBaseUrl {
	ExamsV1 = `v1/exams`,
	ExamsV3 = `v3/exams`,
	CDAV3 = `v3/cda`,
	AIV3 = `v3`,
}

export const serviceApi = {
	getExams: (query: ExamQuery) =>
		`${ServiceBaseUrl.ExamsV1}?${qs.stringify(query)}`,
	checkInExam: (examId: string) =>
		`${ServiceBaseUrl.ExamsV3}/${examId}/checkin`,
	lockExam: (examId: string) => `${ServiceBaseUrl.ExamsV3}/${examId}/lock`,
	getExamCodes: (examId: string) => `${ServiceBaseUrl.ExamsV3}/${examId}/codes`,
}
