import { AccordionDetails, AccordionSummary } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import RefractionAlert from '../../components/DoctorRefractionTab/RefractionAlert'
import { pxToRem } from '../../libs/style'
import Button from '../buttons/Button'
import {
	EditDiscardButtonWrapper,
	pageWrapperMargin,
	pageWrapperPadding,
	UnderlinedButtonLabel,
} from '../CommonPageComponents'
import ChevronDownIcon from '../icons/ChevronDownIcon'
import CollapseIcon from '../icons/Collapse'
import ThickIcon from '../icons/ThickIcon'
import theme from '../theme'

import { TeloAccordionPanel } from './model'
import Accordion from './MuiAccordionStyled'
import { TeloAccordionSubtitle } from './TeloAccordionSubTitle'

const BiggerSubtitleStyle = css`
	font-size: 1.6rem;
	margin-bottom: ${theme.spacing(3)};
	padding-right: ${theme.spacing(4)};
`

const EditDiscardButtonWrapperRight = styled(EditDiscardButtonWrapper)<{
	fullWidthStyle?: boolean
}>`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	float: right;
	z-index: 1;
	margin-bottom: ${theme.spacing(1)};

	${props =>
		!props.fullWidthStyle
			? pageWrapperMargin
			: `margin-bottom: ${pxToRem(15)}rem`}

	margin-left: 0;
	margin-right: 0;

	${theme.breakpoints.down('lg')} {
		margin-left: 0;
		margin-right: 0;
	}
`

const WrapperClear = styled.div`
	clear: both;
`

const Wrapper = styled.div<{
	smallpadding?: boolean
	onlyRightPadding?: boolean
	noPadding?: boolean
}>`
	${props =>
		props.noPadding === true
			? ''
			: props.smallpadding
			? props.onlyRightPadding
				? `padding-right: ${pxToRem(16)}rem;`
				: `padding: 0 ${pxToRem(16)}rem;`
			: pageWrapperPadding}
`

const AccordionStyled = styled(Accordion)`
	overflow: hidden;
	border-color: ${theme.palette.grey[500]};

	&.MuiAccordion-root.Mui-expanded {
		border-color: ${theme.palette.primary.main};
	}
`

const AccordionTitle = styled.div<{ custom: boolean }>`
	${props =>
		props.custom
			? `
		display: flex;
		flex-direction: column-reverse;
		padding: 0 1rem;
	`
			: ''}
`

const TitleAdditionalContentWrapper = styled.div`
	display: inline-block;
	padding: 3px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
`

const WrapperBiggerSubtitle = styled.div<{
	applySubtitleStyle?: boolean
	fullWidth?: boolean
}>`
	${p => (p.applySubtitleStyle === true ? BiggerSubtitleStyle : '')}
	display: flex;
	justify-content: space-between;
	${props =>
		!props.fullWidth
			? `
		flex: 1;
		float: left;
	`
			: ''}
`

const ButtonAnimIcon = styled(Button)`
	.up,
	.down {
		transition: transform 0.2s;
	}

	.up {
		transform-origin: 25% 75%;
	}

	.down {
		transform-origin: 75% 25%;
	}

	.invert {
		.up {
			transform: rotate(180deg);
		}

		.down {
			transform: rotate(-180deg);
		}
	}
`

const TitleWithTagWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: ${theme.spacing(2)};
	width: 100%;
`

const TagNew = styled.span`
	display: flex;
	width: 46px;
	height: 20px;
	border-radius: 9px;
	background-color: ${theme.palette.common.black};
	color: ${theme.palette.common.white};
	align-items: center;
	justify-content: center;
	margin-left: ${theme.spacing(4)};
	font-size: 0.5rem;
`

const TitleAccordionStyled = styled.span<{
	capitalize?: boolean
	normalCase?: boolean
	flexTitle?: boolean
}>`
	text-transform: lowercase;
	display: inline-block;
	&::first-letter {
		text-transform: uppercase;
	}

	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	font-family: 'Neue Haas Grotesk Text Std';
	color: ${theme.palette.primary.main};
	line-height: 140%; /* 1.4rem */
	letter-spacing: -0.03rem;
	width: auto;

	${props =>
		props.normalCase &&
		`
		text-transform: initial;
	`}
	${props =>
		props.capitalize &&
		`
		text-transform: capitalize;
	`}
  ${props =>
		props.flexTitle &&
		`
		flex: 1;
	`}
`

const CompletedIconWrapper = styled.div`
	display: inline-block;
	padding: 3px;
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;

	svg {
		fill: ${theme.palette.success.main};
	}
`

const StyledEmptyState = styled.p`
	font-size: ${pxToRem(14)}rem;
	color: #82888a;
	font-style: italic;
	text-align: center;
`

interface TeloAccordionProps {
	panels: TeloAccordionPanel[]
	className?: string
	bigTitle?: (props: any) => ReactElement
	applySubtitleStyle?: boolean
	defaultExpanded?: boolean
	removeExpandAllButton?: boolean
	closeOtherPanels?: boolean
	capitalize?: boolean
	smallPadding?: boolean
	onlyRightPadding?: boolean
	fullWidthTitleStyle?: boolean
	titleResetCase?: boolean
	emptyState?: boolean
	resetCustoms?: () => void
	children?: React.ReactNode
}

const TeloAccordion = ({
	panels,
	className,
	bigTitle,
	applySubtitleStyle,
	defaultExpanded = false,
	removeExpandAllButton,
	closeOtherPanels = false,
	capitalize,
	smallPadding = false,
	onlyRightPadding = false,
	fullWidthTitleStyle = false,
	titleResetCase = false,
	emptyState = false,
	resetCustoms,
}: TeloAccordionProps) => {
	const panelsKey = panels.map(({ id }, index) => `${id}_${index}`)
	const [flip, setFlip] = useState(false)
	const [expanded, setExpanded] = useState<string[]>(
		!!defaultExpanded ? panelsKey : [],
	)

	const { t } = useTranslation()
	const handleAccordionChange =
		(panelId: string) =>
		(event: React.ChangeEvent<{}>, isExpanded: boolean) => {
			const key = panelId.split('_')[0]
			const panel = panels.find(p => p.id === key)

			if (panel && panel.toogleCustomExpanded !== undefined) {
				panel.toogleCustomExpanded(event, false)
			}
			resetCustoms?.()

			!closeOtherPanels
				? setExpanded(
						isExpanded
							? expanded.concat(panelId)
							: expanded.filter(id => id !== panelId),
				  )
				: setExpanded(isExpanded ? [panelId] : [''])
		}

	useEffect(() => {
		const flag = expanded.length
			? false
			: !panels.some(({ customExpanded }) => customExpanded)
		setFlip(flag)
	}, [expanded, panels, setFlip])

	const toogleAction = (evt: any) => {
		resetCustoms?.()
		panels.forEach(p => {
			if (p && p.toogleCustomExpanded !== undefined) {
				p.toogleCustomExpanded(evt, false)
			}
		})
		setExpanded(flip ? panelsKey : [])
	}

	return (
		<>
			{!removeExpandAllButton ? (
				<AccordionTitle custom={fullWidthTitleStyle}>
					<WrapperBiggerSubtitle
						applySubtitleStyle={applySubtitleStyle}
						fullWidth={fullWidthTitleStyle}
					>
						{bigTitle && bigTitle({})}
					</WrapperBiggerSubtitle>
					<EditDiscardButtonWrapperRight fullWidthStyle={fullWidthTitleStyle}>
						<ButtonAnimIcon
							startIcon={<CollapseIcon flip={flip} />}
							type="button"
							variant="text"
							color="secondary"
							onClick={event => toogleAction(event)}
						>
							<UnderlinedButtonLabel>
								{flip ? t('app.expandAll') : t('app.collapseAll')}
							</UnderlinedButtonLabel>
						</ButtonAnimIcon>
					</EditDiscardButtonWrapperRight>
				</AccordionTitle>
			) : null}
			<WrapperClear>
				<Wrapper
					noPadding={true}
					smallpadding={smallPadding}
					onlyRightPadding={onlyRightPadding}
				>
					<div className={className}>
						{panels.map(
							(
								{
									id,
									dataTestId,
									title,
									subtitle,
									alerts,
									content,
									newTag,
									chips,
									customExpanded,
									flexTitle,
									normalCase,
									completedTag,
									titleAdditionalContent,
								},
								index,
							) => {
								const key = `${id}_${index}`

								return (
									<AccordionStyled
										key={key}
										expanded={expanded.includes(key) || !!customExpanded}
										onChange={handleAccordionChange(key)}
										id={id}
										data-testid={dataTestId}
									>
										<AccordionSummary
											expandIcon={
												<ChevronDownIcon fill={theme.palette.primary.main} />
											}
											aria-controls={`${id}-content`}
											id={`${id}-header`}
											className={`${id.toLowerCase()}-header`}
										>
											<TitleWithTagWrap>
												<TitleAccordionStyled
													capitalize={capitalize}
													normalCase={titleResetCase || normalCase}
													flexTitle={flexTitle}
													className={'title'}
												>
													{title}
												</TitleAccordionStyled>
												{subtitle && (
													<TeloAccordionSubtitle $subtitle={subtitle} />
												)}
												{newTag && <TagNew>NEW</TagNew>}
												{chips ? chips : <></>}
												{alerts?.length &&
													alerts
														.filter(alert => alert.isAlertActive === true)
														.map((alert, index) => {
															return (
																<RefractionAlert
																	key={index}
																	alertMessage={alert.alertMessage}
																	onClick={alert.onClick}
																/>
															)
														})}
												{completedTag && (
													<CompletedIconWrapper>
														<ThickIcon />
													</CompletedIconWrapper>
												)}
												{titleAdditionalContent && (
													<TitleAdditionalContentWrapper>
														{titleAdditionalContent}
													</TitleAdditionalContentWrapper>
												)}
											</TitleWithTagWrap>
										</AccordionSummary>
										<AccordionDetails
											id={`${id}-content`}
											className={`${id.toLowerCase()}-content`}
										>
											{content}
										</AccordionDetails>
									</AccordionStyled>
								)
							},
						)}
						{panels.length === 0 && emptyState && (
							<StyledEmptyState>{t('app.noResultsFound')}</StyledEmptyState>
						)}
					</div>
				</Wrapper>
			</WrapperClear>
		</>
	)
}

export default TeloAccordion
