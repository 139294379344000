import { Spinner as CircularProgress } from '../styleguide/icons/Spinner'
import React from 'react'
import styled from 'styled-components'
import { ChildrenProps } from './utilityTypes'

const WaiterContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

type ChildrenOrError = { renderChildrenWhen: boolean; error?: boolean }

type WaiterProps = ChildrenProps & ChildrenOrError
export const Waiter = ({
	children,
	renderChildrenWhen,
	error,
}: WaiterProps) => {
	return error ? null : renderChildrenWhen ? (
		<>{children}</>
	) : (
		<WaiterContainer>
			<CircularProgress />
		</WaiterContainer>
	)
}
