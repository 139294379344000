import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { SystemCodeCPT } from '../model/cpt'
import { FilterParams, unversionedApiUrl } from './common'

const CPT_TAG = 'cpt'
const CPT_FAVOURITE = 'cpt-favourite'

type CptCode = string

type FilterCptParams = FilterParams & {
	category: string
	filterCategory?: string
}

export const cptApi = createApi({
	reducerPath: 'cptApi',
	tagTypes: [CPT_TAG, CPT_FAVOURITE],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getCptByCode: builder.query<SystemCodeCPT, CptCode>({
			query: code => ({
				url: `/system/v2/cpt/find/code/${code}`,
			}),
			providesTags: [CPT_TAG],
		}),
		getMultipleCptByCode: builder.query<SystemCodeCPT[], CptCode>({
			query: codes => ({
				url: `/system/v2/cpt?codes=${codes}`,
			}),
			providesTags: [CPT_TAG],
		}),
		getFilteredCpt: builder.query<SystemCodeCPT[], FilterCptParams>({
			query: ({
				filter,
				filterCategory,
				category,
				propNames = ['code', 'description'],
				favourites,
			}: FilterCptParams) => ({
				url: !favourites
					? `/system/v2/cpt/paginated?q=${encodeURIComponent(
							filter,
					  )}&category=${category}&propNames=${propNames.join(
							',',
					  )}&filter=${filterCategory}`
					: `/system/v2/cpt/favourites?category=${category}`,
			}),
			providesTags: (result, error, query) => [
				query.favourites ? CPT_FAVOURITE : CPT_TAG,
			],
		}),

		setCptFavourite: builder.mutation<
			SystemCodeCPT,
			{ code: string; favourite: boolean }
		>({
			query: ({ code, favourite }) => ({
				url: `/v1/users/cpt/${code}/favourite`,
				method: 'PUT',
				body: {
					favourite,
				},
			}),
			invalidatesTags: [CPT_TAG, CPT_FAVOURITE],
		}),
	}),
})

export const {
	useGetCptByCodeQuery,
	useGetFilteredCptQuery,
	useSetCptFavouriteMutation,
	useGetMultipleCptByCodeQuery,
} = cptApi
