import styled from 'styled-components'

import { TextField } from '@mui/material'

import { pxToRem } from '../libs/style'

import theme from './theme'
import { defaultInputStyles } from './defaultStyles'
import { pulseAnimation } from '../formFields/commonStyle'

const Input = styled(TextField).attrs(props => ({
	variant: props.variant || 'outlined',
}))<{ $isLoading?: boolean; value?: unknown }>`
	/*fix: https://abstractsrl.atlassian.net/browse/TEL-3334 safari ignores some colors on disabled inputs
	https://stackoverflow.com/questions/262158/disabled-input-text-color-on-ios */

	.MuiInputBase-root,
	.MuiOutlinedInput-root {
		border-radius: 12px;
	}

	.MuiInputBase-root.Mui-disabled:not(:focus) {
		-webkit-text-fill-color: ${theme.palette.greyCustom.main};
	}

	.MuiOutlinedInput-notchedOutline > legend {
		font-size: calc(0.85 * ${pxToRem(14)}rem);
	}

	.MuiOutlinedInput-root > textarea + fieldset > legend {
		font-size: calc(0.85 * ${pxToRem(14)}rem);
	}

	.MuiFormLabel-root.Mui-disabled
		+ .MuiOutlinedInput-root
		> textarea
		+ fieldset
		> legend {
		font-size: calc(0.85 * ${pxToRem(15)}rem);
	}

	.MuiInputBase-root.Mui-disabled {
		background-color: ${theme.palette.grey[100]};

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid ${theme.palette.greyCustom.main} !important;
		}
	}

	.MuiFormLabel-root::first-letter {
		text-transform: uppercase;
	}

	.MuiFormHelperText-root::first-letter {
		text-transform: uppercase;
	}

	.MuiInputLabel-outlined:not(.Mui-disabled, .Mui-error) {
		color: ${theme.palette.greyCustom.main};
	}

	.MuiFormLabel-root.Mui-disabled {
		color: ${theme.palette.greyCustom.main};
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-background-clip: text;
	}

	${defaultInputStyles}

	${pulseAnimation}

	animation: ${({ $isLoading }) => ($isLoading ? '2s pulse infinite' : 'none')};
`

export default Input
