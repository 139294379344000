import { createSelector } from '@reduxjs/toolkit'

import {
	ComponentName,
	COMPONENT_NAME_LIST,
	RootState,
	UIState,
} from '../../model/model'
import { InstrumentInRoom } from '../../model/instruments'
import { selectIsDoctor } from '../users/selectors'
import { ExamStep } from '../../model/exam'

export const selectUI = (state: RootState): UIState => state.ui

export const selectDoctorExamPageActiveStep = (state: RootState) =>
	state.ui.doctorExamPageActiveStep

export const selectDoctorExamPageActivePanel = createSelector<
	[(state: RootState) => UIState],
	InstrumentInRoom | string
>(selectUI, ({ doctorExamPageActivePanel }) => doctorExamPageActivePanel || '')

export const selectPageEditingStatus =
	(component: ComponentName) => (state: RootState) =>
		!!state.ui.isComponentEditing[component]

export const selectPageEditingStatusAggregated = () => (state: RootState) => {
	return (
		COMPONENT_NAME_LIST.filter(cName => !!state.ui.isComponentEditing[cName])
			.length > 0
	)
}

export const selectGlobalEditingStatus = (state: RootState) => {
	return Object.values(state.ui.isComponentEditing || {}).some(value => value)
}

const _selectShowCallWidget = (state: RootState) => state.ui.showCallWidget

export const selectShowCallWidget = createSelector(
	selectIsDoctor,
	_selectShowCallWidget,
	(isDoctor, showCallWidget) => !isDoctor || showCallWidget,
)

export const selectOverrideCallWidget = (state: RootState) =>
	state.ui.overrideCallWidget

export const selectIsCallActive = (exam: string) => (state: RootState) =>
	state.ui.currentCalling === exam

export const selectCurrentChatInfo = (state: RootState) =>
	state.ui.currentChatInfo

export const selectControlPanelActiveStep = (state: RootState) =>
	state.ui.controlPanelActiveStep

export const selectClientAppOpened = (state: RootState) =>
	state.ui.clientAppOpened

export const selectConsultationSelectedStep = (state: RootState) =>
	state.ui.consultationSelectedStep

export const selectConsultationSelectedStepId = (state: RootState) =>
	state.ui.consultationSelectedStepId

export const selectIsConsultationPrescriptionPage = (state: RootState) =>
	state.ui.consultationSelectedStepId === 'prescriptions' &&
	state.ui.doctorExamPageActiveStep === ExamStep.Consultation

export const selectPrescriptionAssets = () => (state: RootState) =>
	state.ui.prescriptionAssets ? state.ui.prescriptionAssets.glasses : null

export const selectDiseaseAndTreatmentsAssets = () => (state: RootState) =>
	state.ui.diseaseAndTreatmentAsset ? state.ui.diseaseAndTreatmentAsset : null

export const selectSelectedPrescriptionAsset = (state: RootState) =>
	state.ui.selectedPrescriptionAsset

export const selectOpenPrimaryChiefCompCategory = (state: RootState) =>
	state.ui.openPrimaryChiefCompCategory

export const selectShowDoctorExamViewFooter = (state: RootState) =>
	state.ui.showDoctorExamViewFooter
