import React from 'react'
import Button from '../styleguide/buttons/Button'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'
import DialogActions from '../styleguide/dialog/DialogActions'
import { useTranslation } from 'react-i18next'
import dialogActions from '../features/dialog/actions'
import styled from 'styled-components'
import { pxToRem } from '../libs/style'
import appointmentsActions from '../features/appointments/actions'
import { getAppointmentIdFromUrl } from '../libs/url'
import { useTeloDispatch } from '../store'
import { useTeloNavigate } from '../routing/teloNavigate'

const DialogContentTextStyled = styled(DialogContentText)`
	min-width: ${pxToRem(299)}rem;
`

const DeleteAppointmentDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const navigate = useTeloNavigate()

	return (
		<>
			<DialogContent>
				<DialogContentTextStyled>
					{t('appointment.deleteQuestion')}
				</DialogContentTextStyled>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					onClick={() => {
						const appId = getAppointmentIdFromUrl() || ''
						dispatch(
							appointmentsActions.deleteAppointmentAction(appId, navigate),
						)
						dispatch(dialogActions.closeDialog())
					}}
					autoFocus
					variant="contained"
				>
					{t('appointment.yesDelete')}
				</Button>
			</DialogActions>
		</>
	)
}

export default DeleteAppointmentDialog
