import { FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query'

import { getIntersessionAppLoginData, getSessionId } from './intersession'

export const staggeredBaseQueryWithBailOut = (baseUrl: string) =>
	retry(
		async (args: string | FetchArgs, api, extraOptions) => {
			const loginData = getIntersessionAppLoginData()
			const token = loginData?.token || ''
			const sessionId = getSessionId() || ''
			const result = await fetchBaseQuery({
				baseUrl,
				prepareHeaders: headers => {
					headers.append('Content-Type', 'application/json')
					headers.append('Authorization', `Bearer ${token}`)
					headers.append('session-id', sessionId)
					return headers
				},
			})(args, api, extraOptions)

			// retry only if the error is on the server
			if (result.error && result.error.status < 500) {
				// for testing
				// if (result.error?.status === 500) {
				retry.fail(result.error)
			}

			return result
		},
		{
			maxRetries: 3,
		},
	)
