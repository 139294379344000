import config from '../config'

export type FilterParams = {
	filter: string
	propNames?: string[]
	caseSignificanceId?: string
	favourites?: boolean
}

export const unversionedApiUrl = config.apiUrl.replace('/v1', '')
