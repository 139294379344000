import { CP_DRAWER_TABS } from '../model/drawer'
import { Id } from '../model/model'
import {
	Privilege,
	PrivilegeFormRecord,
	Role,
	StoreCooperation,
	User,
	UserStorePayload,
} from '../model/users'
import i18n from 'i18next'

export const operativeRoles: Role[] = [
	'Technician',
	'Refractionist',
	'Doctor',
	'FrontDesk',
]

export const adminRoles: Role[] = ['TechnicalAdmin', 'GlobalAdmin']

export const userRoles: Role[] = [
	'TechnicalAdmin',
	'GlobalAdmin',
	'Technician',
	'Refractionist',
	'Doctor',
	'FrontDesk',
	'Guest',
]

export const isRole = (role: any): role is Role => userRoles.includes(role)

export const inStoreUserRoles: Role[] = [
	'TechnicalAdmin',
	'GlobalAdmin',
	'Technician',
]

export const privileges: Privilege[] = [
	'StoreManager',
	'ReportManager',
	'SetupManager',
]

export const privilegesEnabledForStores: Privilege[] = [
	'StoreManager',
	'SetupManager',
]

export const privilegesEnabledForUsers: Privilege[] = []

export const privilegesEnabledForReport: Privilege[] = ['ReportManager']

export const getIsDoctor = (userRole: Role) => userRole === 'Doctor'

export const getIsAdmin = (userRole: Role) => adminRoles.includes(userRole)

export const checkHasInStoreStores = (userRole: Role) => {
	const allowedRoles: Role[] = ['Technician', 'Doctor']
	return allowedRoles.some(role => userRole === role)
}

export const checkHasRemoteStores = (userRole: Role) =>
	userRole === 'Doctor' || userRole === 'Refractionist'

export const joinInStoreStoresAndPrivileges = (
	inStoreStores: Id[],
	privileges: PrivilegeFormRecord,
): UserStorePayload[] => {
	return inStoreStores.map(storeId => ({
		inStore: true,
		remote: false,
		store: storeId,
		privileges: Object.entries(privileges[storeId] || {})
			.filter(([, enabled]) => enabled)
			.map(([name]) => name as Privilege),
	}))
}

export const userPrivileges = (stores: StoreCooperation[]): Privilege[] => {
	const privileges = stores.flatMap(s => s.privileges || [])

	return [...new Set(privileges)]
}

export const userRoleAndPrivileges = (user?: User): (Role | Privilege)[] => {
	const privileges: (Role | Privilege)[] = []
	if (!user) return privileges

	privileges.push(user.role)
	return privileges.concat(userPrivileges(user.stores))
}

export const azureCommunicationReceiverRoles: Role[] = ['Technician']

export const getControlPanelTabs = (
	privileges: (Privilege | Role)[],
	isPracticeManager: boolean,
	withLabel?: boolean,
) =>
	CP_DRAWER_TABS.filter(t => {
		const isPracticeTabAndUserIsPracticeManager =
			t.tab === 'practice' && isPracticeManager

		const isUsersTabAndUserIsPracticeManager =
			t.tab === 'users' && isPracticeManager

		return (
			t.enabledUser.some(r => privileges.includes(r)) ||
			isPracticeTabAndUserIsPracticeManager ||
			isUsersTabAndUserIsPracticeManager
		)
	}).map(i => {
		return withLabel
			? {
					...i,
					label: i18n.t(i.label),
			  }
			: i.tab
	})

export const getPracticeTabs = (isPracticeDefine: boolean) => {
	const steps = [
		{
			stepId: 'generalSettings',
			label: i18n.t('legalEntity.generalSettings'),
		},
	]

	if (isPracticeDefine) {
		steps.push({
			stepId: 'orderConfiguration',
			label: i18n.t('legalEntity.orderConfiguration'),
		})
		steps.push({
			stepId: 'reports',
			label: i18n.t('admin.reportTemplates.title'),
		})
		steps.push({
			stepId: 'alertsConfiguration',
			label: i18n.t('admin.alertCategory.tabs.practiceTitle'),
		})
		steps.push({
			stepId: 'tasksConfiguration',
			label: i18n.t('admin.tasks.tabs.practiceTitle'),
		})
		steps.push({
			stepId: 'surgeryConfiguration',
			label: i18n.t('legalEntity.surgeryConfiguration.title'),
		})
		steps.push({
			stepId: 'visDocumentsConfiguration',
			label: i18n.t('admin.visDocuments.tabs.title'),
		})
	}

	return steps
}
