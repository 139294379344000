import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import SignatureCanvas from 'react-signature-canvas'

import styled from 'styled-components'
import { selectAppLang, selectStoreId } from '../features/app/selectors'
import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import signDocumentsActions from '../features/signDocuments/actions'
import storesActions from '../features/stores/actions'
import {
	selectPrivacyPolicyDocumentBlob,
	selectPrivacyPolicyDocuments,
} from '../features/stores/selectors'
import { pxToRem } from '../libs/style'
import { getLocalizedDate } from '../libs/time'
import { PrivacyPolicyDialogType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import IconButton from '../styleguide/buttons/IconButton'
import Dialog from '../styleguide/dialog/Dialog'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'
import DialogTitle from '../styleguide/dialog/DialogTitle'
import CloseIcon from '../styleguide/icons/CloseIcon'
import ThickIcon from '../styleguide/icons/ThickIcon'
import {
	SignatureDialogActions,
	SignatureDialogContent,
	SignatureDialogContentText,
	SignatureDialogPDFDocumentWrapper,
	SignBlockFooter,
	SignBlockNote,
	SignBlockTitle,
	SignBlockWrapper,
} from '../styleguide/SignatureDialog.style'
import theme from '../styleguide/theme'

const DialogContentTextStyled = styled(DialogContentText)`
	margin: 0 ${theme.spacing(6)} ${theme.spacing(3)};
	color: ${theme.palette.grey[500]};
	font-size: ${pxToRem(14)}rem;
	line-height: ${pxToRem(18)}rem;
`

const DialogContentDocument = styled(DialogContent)`
	max-height: ${pxToRem(684)}rem;
	overflow-y: auto;
	padding: 0;
	margin-top: ${theme.spacing(5)};
`

const SignatureCanvasWrapper = styled.div`
	justify-content: center;
	border: solid 1px ${theme.palette.grey[300]};
	width: ${pxToRem(392)}rem;
	margin: ${theme.spacing(3)} auto ${theme.spacing(8)};
`

const SignaturePlaceholder = styled.div`
	border-top: solid 1px ${theme.palette.grey[300]};
	width: 95%;
	text-align: center;
	margin: 0 auto;
	line-height: ${pxToRem(21)}rem;
	font-style: italic;
	font-size: ${pxToRem(10)}rem;
	color: ${theme.palette.grey[500]};
`

const SignatureTitle = styled.span`
	display: block;
	width: 100%;
	text-align: center;
	font-weight: 300;
	line-height: 1.5;
`

const ButtonAgree = styled(Button)`
	margin-top: ${pxToRem(43)}rem;
	margin-bottom: ${pxToRem(43)}rem;
`

const ButtonClear = styled(Button)`
	float: right;
	color: ${theme.palette.secondary.main};
`

const CANVAS_WRAPPER = 'signature-canvas-wrapper'

const PDFDocumentWrapper = styled.div`
	width: ${pxToRem(935)}rem;
	max-width: 100%;
	//margin: 0 ${pxToRem(48)}rem ${pxToRem(28)}rem;
	margin-bottom: ${pxToRem(28)}rem;
	.react-pdf__Document {
		overflow: hidden;
	}
	canvas {
		max-width: 100%;
		margin: 0 auto;
	}
`
const isPrivacyPolicyDialog = (
	dialog: any,
): dialog is PrivacyPolicyDialogType =>
	typeof dialog === 'object' && dialog.documentID

const PrivacyPolicyDocumentDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()

	const dialog = useTeloSelector(selectDialog)
	const storeId = useTeloSelector(selectStoreId)
	const selectedPrivacyPolicyDocuments = useTeloSelector(
		selectPrivacyPolicyDocuments,
	)
	const isOpen = isPrivacyPolicyDialog(dialog)

	const lang = useTeloSelector(selectAppLang)

	const [disabledButton, setDisabledButton] = useState(true)
	const [clearButtonVisible, setClearButtonVisible] = useState(false)
	const [numPages, setNumPages] = useState(0)

	const signPad = useRef<SignatureCanvas>(null)

	const blobDocUrl = useTeloSelector(selectPrivacyPolicyDocumentBlob)

	const onDocumentLoadSuccess = (numPages: number) => setNumPages(numPages)

	pdfjs.GlobalWorkerOptions.workerSrc = pdfjs.GlobalWorkerOptions.workerSrc =
		pdfjsWorker

	const selectedDocument = selectedPrivacyPolicyDocuments.find(
		({ _id }) => isPrivacyPolicyDialog(dialog) && dialog.documentID === _id,
	)

	const fileRef = selectedDocument?.ref
	const documentName = selectedDocument?.name
	useEffect(() => {
		fileRef &&
			documentName &&
			dispatch(storesActions.getPrivacyPolicyPdf(documentName, fileRef))
	}, [dispatch, fileRef, documentName])

	const isPdf =
		selectedDocument &&
		!!selectedDocument.path &&
		selectedDocument.path.length > 0

	const signatureDate = getLocalizedDate(new Date(), lang)

	useEffect(() => {
		const timeout = setTimeout(() => {
			const canvasElements = document.querySelectorAll(
				`.${CANVAS_WRAPPER} canvas`,
			) as NodeListOf<Element>

			canvasElements.forEach(inputElement => {
				inputElement.setAttribute(
					'data-testid',
					'privacy-policy-signature-canvas',
				)
			})
		}, 1)
		return () => {
			clearTimeout(timeout)
		}
	}, [])

	return isPrivacyPolicyDialog(dialog) ? (
		<Dialog open={isOpen}>
			<DialogTitle>
				{t('privacyPolicy.title')}
				<IconButton
					aria-label="close"
					onClick={() => {
						dispatch(dialogActions.closeDialog())
						dispatch(storesActions.clearPrivacyPolicyFileUrl())
					}}
					sx={{
						position: 'absolute',
						right: 16,
						top: 8,
						color: theme => theme.palette.common.white,
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<SignatureDialogContent>
				{isPdf && blobDocUrl && (
					<SignatureDialogPDFDocumentWrapper>
						<Document
							file={blobDocUrl}
							onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
						>
							{Array.from({ length: numPages }, (_, index) => {
								const pageNumber = index + 1
								return (
									<Page
										key={`page_${pageNumber}`}
										pageNumber={pageNumber}
										devicePixelRatio={1}
									/>
								)
							})}
						</Document>
					</SignatureDialogPDFDocumentWrapper>
				)}
				{!isPdf && (
					<SignatureDialogContentText>
						{selectedDocument?.text}
					</SignatureDialogContentText>
				)}
				<SignBlockTitle>{t('privacyPolicy.agree')}</SignBlockTitle>
				<SignBlockWrapper className={CANVAS_WRAPPER}>
					<SignatureCanvas
						penColor="black"
						onBegin={() => setClearButtonVisible(true)}
						onEnd={() => setDisabledButton(false)}
						ref={signPad}
					/>
					<SignBlockFooter>
						<time>{signatureDate}</time>
						<Button
							onClick={() => {
								signPad.current && signPad.current.clear()
								setDisabledButton(true)
								setClearButtonVisible(false)
							}}
							endIcon={<CloseIcon />}
							variant="text"
							underlined={true}
							hidden={!clearButtonVisible}
						>
							{t('app.clear')}
						</Button>
					</SignBlockFooter>
				</SignBlockWrapper>
				<SignBlockNote>{t('privacyPolicy.signaturePlaceholder')}</SignBlockNote>
			</SignatureDialogContent>
			<SignatureDialogActions>
				<Button
					data-testid="privacy-policy-signature-agree"
					className="ott-checkInModal-SignButton"
					onClick={() => {
						selectedDocument &&
							dispatch(
								signDocumentsActions.setSignDocumentByExam({
									examId: dialog.examId,
									document: {
										name: selectedDocument.name,
										storeId: storeId,
										documentId: selectedDocument._id,
										signatureImg:
											(signPad.current &&
												signPad.current
													?.getTrimmedCanvas()
													.toDataURL('image/png')) ||
											'',
										date: new Date().getTime().toString(),
										mandatory: selectedDocument.active || false,
									},
								}),
							)
						dispatch(storesActions.clearPrivacyPolicyFileUrl())
						dispatch(dialogActions.closeDialog())
					}}
					endIcon={<ThickIcon />}
					disabled={disabledButton}
				>
					{t('privacyPolicy.agreeAndSign')}
				</Button>
			</SignatureDialogActions>
		</Dialog>
	) : null
}

export default PrivacyPolicyDocumentDialog
