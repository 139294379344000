import React, { useState, useEffect } from 'react'
import { i18nLoadCompleted } from '../i18n'
import { ChildrenProps } from './utilityTypes'
import { Waiter } from './Waiter'

export const I18nLoader = ({ children }: ChildrenProps) => {
	const [i18nLoaded, setI18nLoaded] = useState(false)
	useEffect(() => {
		if (i18nLoaded) {
			return
		}
		const waitForI18n = async () => {
			await i18nLoadCompleted
			setI18nLoaded(true)
		}
		waitForI18n()
	}, [i18nLoaded])

	return <Waiter renderChildrenWhen={i18nLoaded}>{children}</Waiter>
}
