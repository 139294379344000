// list of known search params to read from URL
type KnownParam =
	| 'fhirAppointmentId'
	| 'fhirLocationId'
	| 'examId'
	| 'redirect-url'
	| 'currentExamId'
	| 'internalPatientId'
	| 'storeId'
	| 'panelId'

// a search param can be decorated with options
type TeloParamWithOptions = {
	name: KnownParam
	required: boolean
}

// input can either be a bare KnownParam or a param decorated with options
type TeloParam = KnownParam | TeloParamWithOptions

/*
	The result is an object which fields are only the ones listed as input.
	
	Examples with no compile error:
	- const { examId } = readTeloParams("examId")
	- const { examId, currentExamId, storeId } = readTeloParams("examId",
		{name: "currentExamId", required: true},
		{name: "storeId", required: false})

	Examples with compile error:
	- const { examId, currentExamId } = readTeloParams("examId") --> currentExamId not found!
*/
type AllowedResultField<K extends TeloParam> = K extends TeloParamWithOptions
	? Extract<KnownParam, K['name']>
	: Extract<KnownParam, K>

/*
	Determines whether the field is required or not.
	If T is a WithOptions object, then is required if T["required"] is true.
	Otherweise, it is required by default
*/
type IsRequiredField<T extends TeloParam> = T extends TeloParamWithOptions
	? T['required']
	: true

/*
	Determines field value according to its being required or not.

	Examples:
	- const { examId } = readTeloParams("examId") -> type of examId is "string"
	- const { examId } = readTeloParams({name: "examId", required: true}) -> type of examId is "string"
	- const { examId } = readTeloParams({name: "examId", required: false}) -> type of examId is "string | null"
*/
type FieldValue<T extends TeloParam> = IsRequiredField<T> extends true
	? string
	: string | null

/*
	The result of readTeloParams function.
	Contains only the fields listed in input and their value is:
	- string if the field is mandatory
	- string | null if the field is not mandatory
*/
type ReadTeloParamResult<T extends TeloParam[]> = {
	[K in T[number] as AllowedResultField<K>]: FieldValue<K>
}

/**
 * Reads the specified query params from the URL.
 *
 * A param can be specified directly and is considered required.
 *
 * ```
 * // `examId` is of type `string`, throws error if `examId` param is not found in URL
 * const { examId } = readTeloParams("examId")
 * ```
 *
 * A param can also be specified as object with options, to dictate if it is required or not
 *
 * ```
 * // `examId` is of type `string`, throws error if `examId` param is not found in URL
 * const { examId } = readTeloParams({ name: "examId", required: true })
 *
 * // `examId` is of type `string | null`, if `examId` param is not found in URL then null is returned
 * const { examId } = readTeloParams({ name: "examId", required: false })
 * ```
 *
 * Input can be mixed, for each field the behaviors described above apply
 *
 * ```
 * const { examId, currentExamId, storeId } =
 *  readTeloParams(
 *    "examId",
 *    { name: "currentExamId", required: true },
 *    { name: "storeId", required: false }
 *  )
 * ```
 *
 * @param teloParams the list of input fields, with or without options
 * @returns an object with only the fields listed in input with their value being "string" if required or "string | null" if not required
 */
export const readTeloParams = <T extends TeloParam[]>(
	...teloParams: T
): ReadTeloParamResult<T> => {
	if (!teloParams || teloParams.length === 0) {
		throw new Error('Specify at least one param to read from URL')
	}

	const urlParamsRaw = new URLSearchParams(window.location.search)
	const urlParams = toLowerCaseParamNames(urlParamsRaw)

	return (
		[...teloParams]
			// normalize input creating all WithOptions objects
			.map(tp =>
				typeof tp === 'string'
					? { name: tp, required: true }
					: (tp as TeloParamWithOptions),
			)
			// read param values and create proper output object
			.map(({ name: tpName, required }) => {
				const paramValue = urlParams.get(tpName.toLowerCase())
				const validParamValue = paramValue != null && paramValue !== ''
				if (required && !validParamValue) {
					throw new Error(`Parameter '${tpName}' not found`)
				}
				return !validParamValue
					? { tpName, value: null }
					: { tpName, value: paramValue }
			})
			// create proper result object
			.reduce((prev, { tpName, value }) => {
				prev[tpName] = value
				return prev
			}, {} as any)
	)
}

function toLowerCaseParamNames(params: URLSearchParams) {
	const newParams = new URLSearchParams()
	for (const [name, value] of params) {
		newParams.append(name.toLowerCase(), value)
	}
	return newParams
}
