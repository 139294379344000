export enum ServicesReducerPath {
	ExamsApi = 'examsApi',
	CdaApi = 'cdaApi',
	AIApi = 'aiApi',
}

export enum ServicesApiTag {
	Cda = 'cda',
	Ai = 'ai',
}
