import { Exam } from '../model/exam'
import { InstrumentInRoom, InstrumentsData } from '../model/instruments'
import { InstrumentType } from '../model/instrumentsApi'
import { Id } from '../model/model'
import { Room, Stage } from '../model/store'
import { useGetBasePathInfoQuery } from '../services/fieldsDataMeta'
import { isNotUndefined, keys } from './utils'
import { isObject } from 'lodash'

export const instrumentsStepperKeys: InstrumentInRoom[][] = [
	[InstrumentInRoom.RetinalImaging],
	[InstrumentInRoom.Oct],
	[InstrumentInRoom.Lensmeter],
	[InstrumentInRoom.Autorefraction, InstrumentInRoom.Keratometer],
	[InstrumentInRoom.Tonometer],
	[InstrumentInRoom.VisualFields],
	[InstrumentInRoom.SlitLamp],
	[InstrumentInRoom.Phoropter],
]

export const instrumentStepperWithLabels = instrumentsStepperKeys.map(key => ({
	key,
	label: `pretest.stepper.${key[0]}`,
}))

export const getDisabledStepsIndexes = (examRoom?: Room) => {
	const roomKeys = keys(examRoom || ({} as Room))

	const disabledStepsIndexes = instrumentsStepperKeys
		.map((keys, index) =>
			keys.some(key => roomKeys.includes(key)) ? undefined : index,
		)
		.filter(isNotUndefined)
	return disabledStepsIndexes
}

// TODO: reduce cognitive complexity
export const isNotEmpty = (obj: object): boolean => {
	// debugger
	const keys = Object.keys(obj)
	return Object.values(obj).some((value, index) => {
		const key = keys && keys[index]
		// debugger
		const notEmpty =
			key && ['accuracy'].includes(key)
				? false
				: Array.isArray(value)
				? value.some(item => isNotEmpty(item))
				: value === null
				? false
				: typeof value === 'object'
				? isNotEmpty(value)
				: typeof value === 'string'
				? value.length !== 0 && value !== '0'
				: Boolean(value)
		return notEmpty
	})
}

export const getCompletedStepsIndexes = (exam?: Exam) => {
	if (!exam) {
		return []
	}

	const isStepCompleted = (keys: (keyof Room)[]) => {
		const completed = keys.some(stepperKey => {
			const notEmpty = isNotEmpty(
				exam.preTest.instruments[stepperKey as keyof InstrumentsData] || {},
			)
			return notEmpty
		})
		return completed
	}

	const completedStepsIndexes = instrumentsStepperKeys
		.map((stepperkeys, index) =>
			isStepCompleted(stepperkeys) ? index : undefined,
		)
		.filter(isNotUndefined)

	return completedStepsIndexes
}

export const useGetCompletedStepsIndexes = (exam?: Exam) => {
	const lensometryQuery = useGetBasePathInfoQuery(
		{
			examId: exam?._id || '',
			basePath: 'lensometry',
		},
		{ skip: !exam },
	)

	const completedStepsIndexes = getCompletedStepsIndexes(exam)

	const thereIsLensometryValues = lensometryQuery.data?.hasValues

	if (thereIsLensometryValues) {
		const lensometryStepIndex = instrumentsStepperKeys.findIndex(instruments =>
			instruments.includes(InstrumentInRoom.Lensmeter),
		)
		completedStepsIndexes.push(lensometryStepIndex)
	}

	return completedStepsIndexes
}

export const preTestDataEmpty = (
	obj: object,
	omittedKeys: string[],
): boolean => {
	return Object.entries(obj).every(([key, value]) => {
		if (omittedKeys.includes(key)) return true
		return isObject(value) ? preTestDataEmpty(value, omittedKeys) : !value
	})
}

export const useGetCompletedStepperSteps = (
	exam: Exam | undefined,
	steps: { key: (keyof Room)[] }[],
): (keyof Room)[][] => {
	const lensometryQuery = useGetBasePathInfoQuery(
		{
			examId: exam?._id || '',
			basePath: 'lensometry',
		},
		{ skip: !exam },
	)

	if (!exam) return []

	const hasLensometryStep = Boolean(
		steps.filter(({ key }) => key.includes(InstrumentInRoom.Lensmeter))?.length,
	)

	const initialResult: (keyof Room)[][] = []
	if (hasLensometryStep && lensometryQuery?.data?.hasNonEmptyValues)
		initialResult.push([InstrumentInRoom.Lensmeter])

	return steps.reduce((result, { key }) => {
		const isStepCompleted = key.some(
			instrument =>
				!preTestDataEmpty(
					exam.preTest.instruments[instrument as keyof InstrumentsData],
					['accuracy'],
				),
		)
		isStepCompleted && result.push(key)
		return result
	}, initialResult)
}

export const isNewStageNameUnique = (stages: Stage[], name: string) => {
	const existingNames = stages.map(({ name }) => name)
	return !existingNames.includes(name)
}

export const isNewRoomNameUnique = (
	stages: Stage[],
	stageId: Id,
	name: string,
) => {
	const stage = stages.find(({ _id }) => _id === stageId)
	const existingNames = stage?.rooms.map(({ name }) => name) || []
	return !existingNames.includes(name)
}

export const mapInstrumentTypeToRoomKey: {
	[k in InstrumentType]: InstrumentInRoom
} = {
	AR: InstrumentInRoom.Autorefraction,
	KM: InstrumentInRoom.Keratometer,
	LM: InstrumentInRoom.Lensmeter,
	NT: InstrumentInRoom.Tonometer,
	OCT: InstrumentInRoom.Oct,
	PH: InstrumentInRoom.Phoropter,
	RI: InstrumentInRoom.RetinalImaging,
	SL: InstrumentInRoom.SlitLamp,
	VF: InstrumentInRoom.VisualFields,
}

export const convertInstrumentsToDeleteToApiPayload = (
	instrumentsToDelete: InstrumentType[],
) =>
	instrumentsToDelete.reduce((result, type) => {
		const key = mapInstrumentTypeToRoomKey[type]
		result[key] = true
		return result
	}, {} as { [k in InstrumentInRoom]: boolean })
