import {
	Call,
	CallAgent,
	CallClient,
	CallEndReason,
	CallState,
	CollectionUpdatedEvent,
	DeviceManager,
	Features,
	IncomingCall,
	LocalVideoStream,
	MediaDiagnosticChangedEventArgs,
	NetworkDiagnosticChangedEventArgs,
	RemoteParticipant,
	RemoteVideoStream,
	StartCallOptions,
} from '@azure/communication-calling'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { AzureLogger, setLogLevel } from '@azure/logger'
import React, { MutableRefObject } from 'react'
import {
	getAzureCommunicationTokenApi,
	getUserOnlineStatusApi,
	logAzureCallApi,
} from '../../apiCalls'
import appConfig from '../../config'
import { completedExamStatus } from '../../libs/exams'
import {
	delIntersessionAzureCommunicationData,
	setIntersessionAzureCommunicationData,
} from '../../libs/intersession'
import { isIOS, isSafari } from '../../libs/utils'
import {
	AzureCommunicationData,
	AzureLogger as AzureLoggerModel,
} from '../../model/azureCommunication'
import { ExamApi, StrippedExam } from '../../model/exam'
import { AzureStatusIndicator, Id } from '../../model/model'
import { Platform } from '../../model/users'
import {
	AppThunk,
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
} from '../../store'
import errorsActions from '../errors/actions'
import notificationsActions from '../notifications/actions'
import { selectNotification } from '../notifications/selectors'
import { selectAzureCommunicationData, selectCallee } from './selectors'
import { slice } from './slice'
import {
	CallEndReasonCode,
	CALL_END_REASON_CODES,
} from './utils/callEndReasonCodes'
import { UfdMessage, UFD_MESSAGES } from './utils/disagnosticMessages'
import { CALL_AGENT_INIT_STATE, Device } from './utils/models'

type LogArgs = {
	action: string
	store: string
	callId?: string
	displayName: string
	examId?: Id
	error?: Error
}

type CommonArgs = {
	callId?: string
	displayName: string
	examId?: Id
	setCallKnownError: (error?: CallEndReasonCode) => void
	setCallUnknownError: (error?: string) => void
}

type DeviceManagerArgs = {
	setCameraDeviceOptions: (devices: Device[]) => void
	setMicrophoneDeviceOptions: (devices: Device[]) => void
	setSelectedCameraDeviceId: (id: string) => void
	setSelectedMicrophoneDeviceId: (id: string) => void
	setSelectedSpeakerDeviceId: (id: string) => void
	setSpeakerDeviceOptions: (devices: Device[]) => void
}

type InitAzureCallServiceArgs = CommonArgs & {
	callRef: MutableRefObject<Call | undefined>
	examId?: string
	store: string
	ufdMessages: UfdMessage[]
	userToken: string
	setCall: (call?: Call) => void
	setCallAgent: (ca?: CallAgent) => void
	setIsCallAgentInitProcedureEnded: (state: CALL_AGENT_INIT_STATE) => void
	setDeviceManager: (dm?: DeviceManager) => void
	setIncomingCall: (call?: IncomingCall) => void
	setUfdMessages: (messages: UfdMessage[]) => void
}

type HandleStartCallArgs = CommonArgs &
	DeviceManagerArgs & {
		callAgent: CallAgent
		deviceManager: DeviceManager
		store: string
		userIdToCall: string
		withVideo: boolean
	}

type HandleCallOptionsArgs = DeviceManagerArgs & {
	deviceManager: DeviceManager
	withVideo: boolean
	setCallUnknownError: (error?: string) => void
}

type DysplayCallEndReasonArgs = {
	callEndReason: CallEndReason
	setCall: (call?: Call) => void
	setCallKnownError: (error?: CallEndReasonCode) => void
	setIncomingCall: (incomingCall?: IncomingCall) => void
}

export type ParticipantStreamTuple = {
	stream: RemoteVideoStream
	participant: RemoteParticipant
	streamRendererComponentRef: React.RefObject<HTMLDivElement>
}

type HandleSubscribeToRemoteParticipantArgs = {
	allRemoteParticipantStreams: MutableRefObject<ParticipantStreamTuple[]>
	participant: RemoteParticipant
	remoteParticipants: MutableRefObject<RemoteParticipant[]>
	setAllRemoteParticipantStreams: (streams: ParticipantStreamTuple[]) => void
	setRemoteParticipants: (participants: RemoteParticipant[]) => void
}

type HandleCallStateChangeArgs = {
	allRemoteParticipantStreams: MutableRefObject<ParticipantStreamTuple[]>
	call: Call
	remoteParticipants: MutableRefObject<RemoteParticipant[]>
	setAllRemoteParticipantStreams: (streams: ParticipantStreamTuple[]) => void
	setCallState: (state: CallState) => void
	setHandleChangeDone: (done: boolean) => void
	setRemoteParticipants: (participants: RemoteParticipant[]) => void
}

const logAzure = ({
	action,
	callId,
	displayName,
	error,
	examId,
	store,
}: LogArgs) => {
	const log: AzureLoggerModel = {
		action,
		callId: callId || '',
		error: error ? error.message : '',
		examId: examId || '',
		log: window.acsLogBuffer || [],
		platform: 'teleoptometry',
		store,
		userAgent: navigator.userAgent,
		username: displayName,
	}

	logAzureCallApi(log).then(() => {
		window.acsLogBuffer = []
	})
}

const cleanUpAzureCommunication =
	(exams: void | (StrippedExam | ExamApi)[]): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const azureConnection = selectAzureCommunicationData(state)
		if (azureConnection.relatedToExam) {
			const exam = !exams
				? null
				: exams.find(e => e._id === azureConnection.relatedToExam)
			if (!exam || completedExamStatus.includes(exam.status)) {
				dispatch(resetAzureCommunication())
			}
		}
	}

const setAzureCommunication =
	(data: AzureCommunicationData): AppThunk =>
	(dispatch: TeloDispatch) => {
		setIntersessionAzureCommunicationData(data)
		dispatch(slice.actions.setAzureConnectionAuth(data))
	}

const resetAzureCommunication = (): AppThunkPromise => async dispatch => {
	delIntersessionAzureCommunicationData()
	dispatch(slice.actions.delAzureConnectionAuth())
}

const getAzureCommunicationToken = (): AppThunk => (dispatch: TeloDispatch) => {
	dispatch(slice.actions.setAzureConnectionStatus('logging in'))
	getAzureCommunicationTokenApi()
		.then(response => {
			if (!response) {
				dispatch(
					errorsActions.setLoginError({
						message: 'invalid azure connection',
						details: 'Azure Communication login error. Invalid response.',
					}),
				)
				return
			}

			const { token, expiresIn, communicationUserId } = response
			if (token) {
				const loginData = {
					token,
					expiresIn,
					communicationUserId,
				}
				setIntersessionAzureCommunicationData(loginData)
				dispatch(slice.actions.setAzureConnectionAuth(loginData))
			} else {
				dispatch(
					errorsActions.setLoginError({
						message: 'azure connection missing token',
						details: 'Azure Communication login error.',
					}),
				)
			}
		})
		.catch(err => dispatch(errorsActions.setUiError(err)))
}

const displayCallEndReason = ({
	callEndReason,
	setCall,
	setCallKnownError,
	setIncomingCall,
}: DysplayCallEndReasonArgs) => {
	if (callEndReason.code !== 0 || callEndReason.subCode !== 0) {
		const issue = CALL_END_REASON_CODES.find(e => e.code === callEndReason.code)
		if (issue) {
			setCallKnownError(issue)
		}
	}
	setCall(undefined)
	setIncomingCall(undefined)
}

const getCallOptions = async ({
	deviceManager,
	withVideo,
	setCameraDeviceOptions,
	setCallUnknownError,
	setSelectedCameraDeviceId,
	setSelectedMicrophoneDeviceId,
	setSelectedSpeakerDeviceId,
	setMicrophoneDeviceOptions,
	setSpeakerDeviceOptions,
}: HandleCallOptionsArgs) => {
	await deviceManager.askDevicePermission({
		audio: true,
		video: true,
	})

	let callOptions: StartCallOptions = {
		videoOptions: {
			localVideoStreams: undefined,
		},
		audioOptions: {
			muted: false,
		},
	}

	let warnings = {
		camera: '',
		speaker: '',
		microphone: '',
	}
	try {
		const cameras = await deviceManager.getCameras()
		const cameraDevice = cameras[0]

		const camerasOptions = cameras.map(camera => {
			return { id: camera.id, name: camera.name }
		})
		setSelectedCameraDeviceId(cameraDevice?.id)
		setCameraDeviceOptions(camerasOptions)

		if (withVideo) {
			if (!cameraDevice || cameraDevice?.id === 'camera:') {
				throw new Error('No camera devices found.')
			}
			if (cameraDevice) {
				callOptions.videoOptions = {
					localVideoStreams: [new LocalVideoStream(cameraDevice)],
				}
			}
		}
	} catch (error) {
		warnings.camera = (error as Error).message
	}

	try {
		// Known issue: https://bit.ly/3GEi9fF
		const speakers = await deviceManager.getSpeakers()
		const speakerDevice = speakers[0]

		if (!speakerDevice || speakerDevice.id === 'speaker:') {
			throw new Error('No speaker devices found.')
		}

		await deviceManager.selectSpeaker(speakerDevice)
		const speakersOptions = speakers.map(speaker => {
			return { id: speaker.id, name: speaker.name }
		})
		setSelectedSpeakerDeviceId(speakerDevice.id)
		setSpeakerDeviceOptions(speakersOptions)
	} catch (error) {
		if (!isIOS && !isSafari) {
			warnings.speaker = (error as Error).message
		}
	}

	try {
		const microphones = await deviceManager.getMicrophones()
		const microphoneDevice = microphones[0]
		if (!microphoneDevice || microphoneDevice.id === 'microphone:') {
			throw new Error('No microphone devices found.')
		}
		await deviceManager.selectMicrophone(microphoneDevice)
		const microphonessOptions = microphones.map(microphone => {
			return { id: microphone.id, name: microphone.name }
		})
		setSelectedMicrophoneDeviceId(microphoneDevice.id)
		setMicrophoneDeviceOptions(microphonessOptions)
	} catch (error) {
		warnings.microphone = (error as Error).message
	}

	if (warnings.camera || warnings.microphone || warnings.speaker) {
		const msg = `${warnings.camera || ''} ${warnings.microphone || ''} ${
			warnings.speaker || ''
		}`
		setCallUnknownError('Videocall, error selecting devices: ' + msg)
	}

	return callOptions
}

const subscribeToRemoteParticipant = ({
	allRemoteParticipantStreams,
	participant,
	remoteParticipants,
	setAllRemoteParticipantStreams,
	setRemoteParticipants,
}: HandleSubscribeToRemoteParticipantArgs) => {
	const rp = remoteParticipants.current.find(p => p === participant)

	if (!rp) {
		setRemoteParticipants([...remoteParticipants.current, participant])
	}

	const addToListOfAllRemoteParticipantStreams = (
		participantStreams: readonly RemoteVideoStream[],
	) => {
		if (!participantStreams || participantStreams.length === 0) {
			return
		}

		let participantStreamTuples = participantStreams.map(stream => {
			return {
				stream,
				participant,
				streamRendererComponentRef: React.createRef<HTMLDivElement>(),
			}
		})

		const updatedStreams = participantStreamTuples.reduce((prev, tuple) => {
			const exists = prev.some(t => t === tuple)
			if (!exists) {
				return [...prev, tuple]
			}

			return prev
		}, allRemoteParticipantStreams.current)

		setAllRemoteParticipantStreams(updatedStreams)
	}

	const removeFromListOfAllRemoteParticipantStreams = (
		participantStreams: RemoteVideoStream[],
	) => {
		participantStreams.forEach(streamToRemove => {
			const tupleToRemove = allRemoteParticipantStreams.current.find(
				t => t.stream === streamToRemove,
			)
			if (tupleToRemove) {
				setAllRemoteParticipantStreams(
					allRemoteParticipantStreams.current.filter(t => t !== tupleToRemove),
				)
			}
		})
	}

	const handleVideoStreamsUpdated: CollectionUpdatedEvent<
		RemoteVideoStream
	> = event => {
		addToListOfAllRemoteParticipantStreams(event.added)
		removeFromListOfAllRemoteParticipantStreams(event.removed)
	}

	addToListOfAllRemoteParticipantStreams(participant.videoStreams)
	participant.on('videoStreamsUpdated', handleVideoStreamsUpdated)
}

const handleCallStateChange = ({
	call,
	allRemoteParticipantStreams,
	remoteParticipants,
	setAllRemoteParticipantStreams,
	setCallState,
	setHandleChangeDone,
	setRemoteParticipants,
}: HandleCallStateChangeArgs) => {
	call.on('stateChanged', () => {
		setCallState(call.state)
	})
	call.remoteParticipants.forEach(participant =>
		subscribeToRemoteParticipant({
			allRemoteParticipantStreams,
			participant,
			remoteParticipants,
			setAllRemoteParticipantStreams,
			setRemoteParticipants,
		}),
	)

	call.on('remoteParticipantsUpdated', e => {
		e.added.forEach(participant =>
			subscribeToRemoteParticipant({
				allRemoteParticipantStreams,
				participant,
				remoteParticipants,
				setAllRemoteParticipantStreams,
				setRemoteParticipants,
			}),
		)

		e.removed.forEach(p => {
			const remoteParticipantsUpdated = remoteParticipants.current.filter(
				remoteParticipant => remoteParticipant !== p,
			)
			setRemoteParticipants(remoteParticipantsUpdated)
		})
	})

	setHandleChangeDone(true)
}

const initAzureCallService = async ({
	userToken,
	displayName,
	examId,
	callRef,
	store,
	ufdMessages,
	setCall,
	setCallUnknownError,
	setIsCallAgentInitProcedureEnded,
	setIncomingCall,
	setDeviceManager,
	setCallAgent,
	setCallKnownError,
	setUfdMessages,
}: InitAzureCallServiceArgs) => {
	try {
		window.callId = undefined
		setIsCallAgentInitProcedureEnded('START')
		setLogLevel('info')

		const callClient = new CallClient({
			diagnostics: {
				appName: 'teleoptometry',
				appVersion: appConfig.app.version,
			},
		})
		const tokenCredential = new AzureCommunicationTokenCredential(userToken)
		const callAgent = await callClient.createCallAgent(tokenCredential, {
			displayName,
		})

		window.callAgent = callAgent
		window.acsLogBuffer = []

		AzureLogger.log = (...args) => {
			window.acsLogBuffer.push(...args)
			if (args[0].startsWith('azure:ACS:error')) {
				logAzure({
					action: 'AZURE_LOGGER_ERROR',
					store,
					callId: window.callId || '-',
					examId,
					displayName,
				})
			}
		}

		callAgent.on('callsUpdated', e => {
			e.added.forEach(call => {
				setCall(call)
				const diagnosticChangedListener = (
					diagnosticInfo:
						| MediaDiagnosticChangedEventArgs
						| NetworkDiagnosticChangedEventArgs,
				) => {
					// Ignore 'healty' messages
					if (diagnosticInfo.value === false || diagnosticInfo.value === 1) {
						return
					}

					const message = UFD_MESSAGES.find(
						d =>
							d.diagnostic === diagnosticInfo.diagnostic &&
							d.value === diagnosticInfo.value,
					)
					if (!message) {
						return
					}

					const messages = [...ufdMessages, message]
					setUfdMessages(messages)
				}

				call
					.feature(Features.UserFacingDiagnostics)
					.media.on('diagnosticChanged', diagnosticChangedListener)
				call
					.feature(Features.UserFacingDiagnostics)
					.network.on('diagnosticChanged', diagnosticChangedListener)
			})

			e.removed.forEach(async call => {
				if (
					callRef.current &&
					callRef.current === call &&
					callRef.current.callEndReason
				) {
					displayCallEndReason({
						callEndReason: callRef.current.callEndReason,
						setCall,
						setCallKnownError,
						setIncomingCall,
					})
				}
			})
		})

		callAgent.on('incomingCall', async e => {
			const incomingCall = e.incomingCall
			if (callRef.current) {
				incomingCall.reject()
				return
			}
			setIncomingCall(incomingCall)

			incomingCall.on('callEnded', args => {
				displayCallEndReason({
					callEndReason: args.callEndReason,
					setCall,
					setCallKnownError,
					setIncomingCall,
				})
			})
		})

		setCallAgent(callAgent)
		const deviceManager = await callClient.getDeviceManager()
		const grants = await deviceManager.askDevicePermission({
			audio: true,
			video: true,
		})
		if (grants.audio === false || grants.video === false) {
			const issue = CALL_END_REASON_CODES.find(e => e.code === 701)
			if (issue) {
				setCallKnownError(issue)
			}
		}

		setDeviceManager(deviceManager)
		setIsCallAgentInitProcedureEnded('END')
	} catch (error) {
		setIsCallAgentInitProcedureEnded('ERROR')
		setCallUnknownError(
			'Videocall, error during call agent init: ' + (error as Error).message,
		)
		logAzure({
			action: 'INIT_AZURE_CALL_SERVICE',
			store,
			callId: '',
			examId,
			displayName,
			error: error as Error,
		})
	}
}

const startCall = async ({
	callAgent,
	deviceManager,
	displayName,
	examId,
	store,
	userIdToCall,
	withVideo,
	setCallKnownError,
	setCallUnknownError,
	setCameraDeviceOptions,
	setSelectedCameraDeviceId,
	setSelectedMicrophoneDeviceId,
	setSelectedSpeakerDeviceId,
	setMicrophoneDeviceOptions,
	setSpeakerDeviceOptions,
}: HandleStartCallArgs) => {
	try {
		setCallKnownError(undefined)
		const callOptions = await getCallOptions({
			deviceManager,
			withVideo,
			setCallUnknownError,
			setCameraDeviceOptions,
			setSelectedCameraDeviceId,
			setSelectedMicrophoneDeviceId,
			setSelectedSpeakerDeviceId,
			setMicrophoneDeviceOptions,
			setSpeakerDeviceOptions,
		})
		const newCall = callAgent.startCall(
			[
				{
					communicationUserId: userIdToCall,
				},
			],
			callOptions,
		)
		window.callId = newCall.id
		logAzure({
			action: 'START_CALL_OK',
			store,
			callId: newCall.id,
			examId,
			displayName,
		})
	} catch (error) {
		setCallUnknownError(
			'Videocall, failed to start the call: ' + (error as Error).message,
		)
		logAzure({
			action: 'HANDLE_START_CALL',
			store,
			callId: '-',
			examId,
			displayName,
			error: error as Error,
		})
	}
}

const setStatusIndicator =
	(payload: AzureStatusIndicator): AppThunk =>
	(dispatch: TeloDispatch) =>
		dispatch(slice.actions.setStatusIndicator(payload))

const setTeloCallStatus =
	(payload: 'NONE' | 'STARTED' | 'ENDED' | 'FORCE_ENDED'): AppThunk =>
	(dispatch: TeloDispatch) =>
		dispatch(slice.actions.setTeloCallStatus(payload))

const fetchCalleeOnlineStatus =
	(platform: Platform, username?: string): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const currentCalleeOnlineStatus = selectCallee(state)
		if (!username) {
			username !== currentCalleeOnlineStatus.username &&
				dispatch(
					slice.actions.setCalleeOnlineStatus({ username: '', online: false }),
				)
			return
		}

		getUserOnlineStatusApi(username, platform).then(online => {
			online !== undefined &&
				online !== currentCalleeOnlineStatus.online &&
				dispatch(slice.actions.setCalleeOnlineStatus({ username, online }))
		})
	}

const showUnknownError =
	(callUnknownError: string): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const notificationId = 'AzureCallUnknownErroor'
		const errorNotification = selectNotification(notificationId)(state)
		if (!errorNotification) {
			dispatch(
				notificationsActions.addNotification({
					id: notificationId,
					type: 'error',
					message: `Videocall: ${callUnknownError}`,
					messageIsLabelKey: true,
					autoClose: false,
				}),
			)
		}
	}

const azureCommunicationActions = {
	...slice.actions,
	cleanUpAzureCommunication,
	getAzureCommunicationToken,
	getCallOptions,
	handleCallStateChange,
	initAzureCallService,
	resetAzureCommunication,
	setAzureCommunication,
	setStatusIndicator,
	setTeloCallStatus,
	showUnknownError,
	startCall,
	subscribeToRemoteParticipant,
	fetchCalleeOnlineStatus,
}

export default azureCommunicationActions
