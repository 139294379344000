import { BasePath, Path } from '../../../formFields/model'
import { AddNotificationPayload } from '../../notifications/actions'
import {
	AddNotificationFn,
	ExamIdData,
	ExamsInStoreUpdatedData,
	FetchExamFn,
	InvalidateWorklistCacheFn,
} from '../teloSocketTypes'
import { checkOrigin } from '../teloSocketUtils'
import {
	AssessmentMessageData,
	FetchAssessmentFn,
	FetchExamWarningsFn,
	FetchFieldsDataFn,
	FetchInstrumentDataFn,
	FetchInstrumentsInRoomFn,
	FetchStagesFn,
	FetchUserFn,
	InstrumentInRoomMessageData,
	InstrumentMessageData,
	StageMessageData,
	UserMessageData,
} from './roomSocketTypes'

type AssessmentMessageArgs = {
	data: AssessmentMessageData
	sessionId: string
	fetchAssessment: FetchAssessmentFn
}

export const onAssessmentMessage = (args: AssessmentMessageArgs) => {
	const { fetchAssessment, data } = args
	// if (!checkOrigin(sessionId, data)) {
	// 	return
	// }

	if (!data?.assessmentId) {
		throw new Error('assessmentId missing')
	}
	fetchAssessment(data.assessmentId)
}

type ExamMessageArgs = {
	data: ExamIdData
	sessionId: string
	fetchExam: FetchExamFn
}

export const onExamMessage = (args: ExamMessageArgs) => {
	const { sessionId, fetchExam, data } = args
	const sameOrigin = checkOrigin(sessionId, data)
	if (sameOrigin) {
		return
	}

	if (!data?.examId) {
		throw new Error('examId missing')
	}
	fetchExam(data.examId, true)
}

type StageMessageArgs = {
	data: StageMessageData
	fetchStages: FetchStagesFn
	sessionId: string
}

export const onStageMessage = (args: StageMessageArgs) => {
	const { fetchStages, data } = args
	// if (!checkOrigin(sessionId, data)) {
	// 	return
	// }
	if (!data?.storeId) {
		throw new Error('storeId missing')
	}
	fetchStages(data.storeId)
}

const NEW_EXAM_NOTIFICATION_DATA: AddNotificationPayload = {
	autoClose: true,
	playSound: true,
	colored: true,
	type: 'info',
	message: 'exam.notifications.newExamRequest',
	messageIsLabelKey: true,
}

type UserMessageArgs = {
	data: UserMessageData
	sessionId: string
	fetchUser: FetchUserFn
	addNotification: AddNotificationFn
}

export const onUserMessage = (args: UserMessageArgs) => {
	const { fetchUser, addNotification, data } = args
	// if (!checkOrigin(sessionId, data)) {
	// 	return
	// }
	const { username, examId, examMode } = data
	if (!username) {
		throw new Error('username missing')
	}
	fetchUser(username)
	if (examId && examMode === 'remote') {
		addNotification(NEW_EXAM_NOTIFICATION_DATA)
	}
}

type WarningMessageArgs = {
	data: ExamIdData
	sessionId: string
	fetchExamWarnings: FetchExamWarningsFn
}

export const onWarningMessage = (args: WarningMessageArgs) => {
	const { fetchExamWarnings, data } = args

	// if (!checkOrigin(sessionId, data)) {
	// 	return
	// }
	if (!data?.examId) {
		throw new Error('examId missing')
	}

	fetchExamWarnings(data.examId)
}

type InstrumentMessageArgs = {
	data: InstrumentMessageData
	sessionId: string
	fetchInstrumentData: FetchInstrumentDataFn
}

export const onInstrumentMessage = (args: InstrumentMessageArgs) => {
	const { fetchInstrumentData, sessionId, data } = args
	const sameOrigin = checkOrigin(sessionId, data)
	if (sameOrigin === true) {
		return
	}

	const { examId, instrumentType } = data
	if (!examId) {
		throw new Error('examId missing')
	}
	if (!instrumentType) {
		throw new Error('instrumentType missing')
	}
	fetchInstrumentData(data)
}

type InstrumentInRoomMessageArgs = {
	data: InstrumentInRoomMessageData
	sessionId: string
	fetchInstrumentsInRoom: FetchInstrumentsInRoomFn
}

export const onInstrumentInRoomMessage = (
	args: InstrumentInRoomMessageArgs,
) => {
	const { fetchInstrumentsInRoom, data } = args
	// if (!checkOrigin(sessionId, data)) {
	// 	return
	// }
	const { storeId, stageId, roomId } = data
	if (!storeId) {
		throw new Error('storeId missing')
	}
	if (!stageId) {
		throw new Error('stageId missing')
	}
	if (!roomId) {
		throw new Error('roomId missing')
	}
	fetchInstrumentsInRoom({ storeId, stageId, roomId })
}

type ExamsInStoreMessageArgs = {
	data: ExamsInStoreUpdatedData
	sessionId: string
	invalidateWorklistCache: InvalidateWorklistCacheFn
}

export const onExamsInStoreMessage = (args: ExamsInStoreMessageArgs) => {
	const { invalidateWorklistCache } = args
	invalidateWorklistCache()
}

type FieldsUpdatesMessageArgs = {
	data:
		| {
				examId: string
				updatedBy: string
				fields: { path: Path; index?: number }[]
				type: 'fields-updated'
		  }
		| {
				examId: string
				updatedBy: string
				basePath: BasePath
				index: number
				type: 'index-deleted'
		  }
	fetchFieldsData: FetchFieldsDataFn
}

export const onFieldsUpdatesMessage = ({
	data,
	fetchFieldsData,
}: FieldsUpdatesMessageArgs) => {
	fetchFieldsData(data)
}
