import React from 'react'

import { FormControlLabelProps, Radio as RadioBase } from '@mui/material'

import FormControlLabel from './FormControlLabel'
import styled from 'styled-components'
import theme from './theme'
import { pulseAnimation } from '../formFields/commonStyle'
import { pxToRem } from '../libs/style'

interface RadioProps extends Omit<FormControlLabelProps, 'control'> {
	isLoading?: boolean
	helperText?: string
}

const RADIO_ICON_SIZE = `${theme.spacing(2)}`

const RadioIconStyled = styled.span<{ checked?: boolean }>`
	height: ${RADIO_ICON_SIZE};
	width: ${RADIO_ICON_SIZE};
	border: ${pxToRem(2)}rem solid ${theme.palette.grey[600]};
	border-radius: 50%;
	z-index: 1;
	outline-offset: ${pxToRem(1)}rem;

	${props =>
		props.checked &&
		`border: ${pxToRem(5)}rem solid ${theme.palette.primary.main};`}
	&:hover {
		border-color: ${theme.palette.violet[800]};
	}
`

const RadioBaseStyled = styled(RadioBase)`
	padding: 0;

	input {
		:focus {
			+ span {
				outline: ${pxToRem(2)}rem solid ${theme.palette.secondary.light};
			}
		}

		&[disabled] {
			+ span {
				border-color: ${theme.palette.greyCustom.main};
			}
		}
	}
`

const FormControlLabelStyled = styled(FormControlLabel)<
	Pick<RadioProps, 'helperText'>
>`
	${pulseAnimation};

	column-gap: ${theme.spacing(1)};
	min-height: ${theme.spacing(3)};
	margin: 0;

	.MuiTypography-root {
		font-size: ${theme.typography.pxToRem(14)};
		line-height: 1;
	}

	&.is-loading {
		animation: 2s pulse infinite;
	}

	.MuiFormControlLabel-label.Mui-disabled {
		color: ${theme.palette.greyCustom.main};
	}

	${({ helperText }) =>
		helperText &&
		`
		display: inline-grid;
		grid-template-areas: 'icon label' 'empty helperText';
		grid-template-columns: ${RADIO_ICON_SIZE} auto;
		grid-template-rows:  ${theme.spacing(3)} auto;
		row-gap: ${theme.spacing(0.5)};
	
		&::after {
    	content: '${helperText}';
    	grid-area: helperText;
    	font-size: ${theme.typography.pxToRem(12)};
    	line-height: 1.5;
    	color: ${theme.palette.grey[600]};
  	}
  	
  	.MuiButtonBase-root {
			grid-area: icon;
		}
		
		.MuiTypography-root {
			grid-area: label;
		}
	`}
`

const Radio: React.FC<RadioProps> = ({
	checked,
	disabled,
	helperText,
	isLoading,
	value,
	onChange,
	...props
}) => {
	return (
		<FormControlLabelStyled
			className={isLoading ? 'is-loading' : ''}
			control={
				<RadioBaseStyled
					checked={checked}
					checkedIcon={<RadioIconStyled checked />}
					disabled={disabled}
					disableRipple={true}
					icon={<RadioIconStyled />}
					value={value}
					onChange={onChange}
				/>
			}
			helperText={helperText}
			{...props}
		/>
	)
}

export default Radio
