import { createApi } from '@reduxjs/toolkit/query/react'
import config from '../config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { ContactLensesBase, ContactLensesCatalogData } from '../model/exam'

const CL_TAG = 'cl'

export const clApi = createApi({
	reducerPath: 'clApi',
	tagTypes: [CL_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(config.apiUrl),
	endpoints: builder => ({
		getContactLenses: builder.query<
			ContactLensesCatalogData[],
			{ filter?: string }
		>({
			query: ({ filter }) => ({
				url: `/contactLenses${
					filter !== undefined && filter.length > 0 ? '?q=' + filter : ''
				}`,
			}),
			providesTags: [CL_TAG],
		}),

		createContactLens: builder.mutation<void, ContactLensesBase>({
			query: body => ({
				url: `/contactLenses`,
				method: 'POST',
				body: JSON.stringify(body),
			}),
			invalidatesTags: [CL_TAG],
		}),

		updateContactLens: builder.mutation<void, ContactLensesCatalogData>({
			query: body => ({
				url: `/contactLenses/${body._id}`,
				method: 'PUT',
				body: JSON.stringify(body),
			}),
			invalidatesTags: [CL_TAG],
		}),

		enableContactLens: builder.mutation<void, { id: string; value: boolean }>({
			query: ({ id, value }) => ({
				url: `/contactLenses/${id}`,
				method: 'PATCH',
				body: JSON.stringify({
					path: `enabled`,
					value,
				}),
			}),
			invalidatesTags: [CL_TAG],
		}),
	}),
})

export const {
	useGetContactLensesQuery,
	useCreateContactLensMutation,
	useUpdateContactLensMutation,
	useEnableContactLensMutation,
} = clApi
