import {
	createPrescriptionApi,
	getExamDocumentsFromReferenceApi,
	getPrescriptionApi,
	getUserByUsernameApi,
	sendPrescriptionDocumentApi,
	updatePrescriptionApi,
} from '../../apiCalls'
import notificationsActions from '../../features/notifications/actions'
import i18n from 'i18next'
import { formatName } from '../../libs/localization'
import { convertDateForBody, isValidDate } from '../../libs/time'
import { Base64Image, Id } from '../../model/model'
import {
	PrescriptionDataTempPayload,
	Prescription,
	PrescriptionContactLenses,
	PrescriptionData,
} from '../../model/prescription'
import {
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
	AppThunk,
} from '../../store'
import { selectUsername } from '../auth/selectors'
import cockpitActions from '../cockpit/actions'
import { selectExam } from '../exams/selectors'
import { selectUser } from '../users/selectors'
import { slice } from './slice'

export const createPrescription =
	({
		examId,
		prescriptionData,
		contactLensesSignature,
	}: {
		examId: Id
		prescriptionData: PrescriptionData
		contactLensesSignature?: Base64Image
	}): AppThunkPromise<{ payload: Prescription; type: string } | void> =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const username = selectUsername(state)
		return getUserByUsernameApi(username.toLowerCase()).then(user => {
			if (!user) {
				throw new Error(`No user found for username ${username}`)
			}
			const exam = selectExam(examId)(state)
			if (!exam) {
				return
			}

			const patient = {
				fullname: formatName(exam.internalPatient),
				birthDate: exam.internalPatient.birthDate
					? convertDateForBody(new Date(exam.internalPatient.birthDate))
					: undefined,
				email: exam.internalPatient.email || '',
			}

			return createPrescriptionApi({
				examId,
				userId: user._id,
				appointment: exam.externalAppointment,
				prescriptionData,
				contactLensesSignature,
				patient,
				signed: true,
			}).then(
				prescription =>
					prescription &&
					dispatch(slice.actions._loadPrescription(prescription)),
			)
		})
	}

export const updatePrescription =
	({
		contactLensesSignature,
		examId,
		prescriptionData,
		prescriptionId,
		send = false,
	}: {
		contactLensesSignature?: Base64Image
		examId: Id
		prescriptionData: PrescriptionData
		prescriptionId: Id
		send: boolean
	}): AppThunkPromise<{ payload: Prescription; type: string } | void> =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const user = selectUser(state)
		const exam = selectExam(examId)(state)

		if (!user || !exam) {
			return Promise.reject()
		}

		const examDate = exam.history[0].statusUpdatedAt
		let prescriptionDate = ''

		if (isValidDate(examDate)) {
			prescriptionDate = examDate.toISOString()
		} else if (typeof examDate === 'string') {
			prescriptionDate = examDate
		}

		const patient = {
			fullname: formatName(exam.internalPatient),
			birthDate: exam.internalPatient.birthDate
				? convertDateForBody(new Date(exam.internalPatient.birthDate))
				: undefined,
			email: exam.internalPatient.email || '',
		}

		return updatePrescriptionApi({
			examId,
			userId: user._id,
			appointment: exam?.externalAppointment,
			prescriptionData,
			contactLensesSignature,
			prescriptionId,
			signature: true,
			send,
			patient,
			date: prescriptionDate,
		}).then(prescription => {
			if (prescription) {
				dispatch(slice.actions._loadPrescription(prescription))
			}
			if (prescription && !!prescription.sent) {
				dispatch(
					notificationsActions.addNotification({
						message: i18n.t('doctor.signNotification'),
						autoClose: true,
						type: 'success',
					}),
				)
			}
		})
	}

const fetchPrescription =
	(examId: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		getPrescriptionApi(examId).then(
			prescription =>
				prescription && dispatch(slice.actions._loadPrescription(prescription)),
		)
	}

const saveDataTemp =
	(payload: PrescriptionDataTempPayload): AppThunk =>
	(dispatch: TeloDispatch) => {
		if (payload.data) {
			payload.data = {
				...payload.data,
				Legal: {
					...payload.data.Legal,
					ApprovedforContactLenses: !!payload.data.ContactLenses.length,
				},
			}

			if (payload.data?.ContactLenses) {
				payload.data.ContactLenses = payload.data.ContactLenses.map(cl => {
					if (cl.useSameProduct) {
						return {
							...cl,
							OS: {
								...cl.OD,
								AdditionalParameters: cl.OS.AdditionalParameters,
								Measure: cl.OS.Measure,
							},
						}
					}
					return cl
				})
			}
		}
		dispatch(
			slice.actions._loadDataTemp({ id: payload.id, data: payload.data }),
		)
	}

const updateClDataTemp =
	(contactLenses: PrescriptionContactLenses[], examId: string): AppThunk =>
	(dispatch: TeloDispatch, getState) => {
		const prevTempData = getState().prescriptions.dataTemp?.[examId]
		let newTempData
		if (prevTempData) {
			newTempData = {
				...prevTempData,
				ContactLenses: contactLenses,
				Legal: {
					...prevTempData.Legal,
					ExpirationDateGlassesLensRx: prevTempData.Legal
						.ExpirationDateGlassesLensRx
						? new Date(
								prevTempData.Legal.ExpirationDateGlassesLensRx,
						  ).toISOString()
						: prevTempData.Legal.ExpirationDateGlassesLensRx,
					ExpirationDateContactLensRx: prevTempData.Legal
						.ExpirationDateContactLensRx
						? new Date(
								prevTempData.Legal.ExpirationDateContactLensRx,
						  ).toISOString()
						: prevTempData.Legal.ExpirationDateContactLensRx,
				},
			}
			dispatch(slice.actions._loadDataTemp({ data: newTempData, id: examId }))
		}
	}

const setInDisplay =
	(key?: string, index?: number, skipClear?: boolean): AppThunk =>
	(dispatch: TeloDispatch) => {
		if (!skipClear) {
			dispatch(cockpitActions.clearCockpitDisplay())
		}

		const rxSharingKey =
			key && index !== undefined ? `${key}${index}` : undefined
		dispatch(slice.actions.setInShare(rxSharingKey))
		dispatch(cockpitActions._setSharingOn(key ? key : ''))
	}

const clearPrescriptionDisplay = (): AppThunk => (dispatch: TeloDispatch) => {
	dispatch(slice.actions.setInShare())
}

const sendPrescriptionDocument =
	({
		examId,
		prescriptionId,
		send,
	}: {
		examId: Id
		prescriptionId: Id
		send: boolean
	}): AppThunkPromise<{ payload: Prescription; type: string } | void> =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const username = selectUsername(state)
		return getUserByUsernameApi(username.toLowerCase()).then(user => {
			if (!user) {
				throw new Error(`No user found for username ${username}`)
			}
			const exam = selectExam(examId)(state)
			if (!exam) {
				return
			}

			return sendPrescriptionDocumentApi(prescriptionId, {
				send,
			}).then(
				prescription =>
					prescription &&
					dispatch(slice.actions._loadPrescription(prescription)),
			)
		})
	}

const getPrescriptionBlob =
	(prescriptionId: string, fileRef: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		return getExamDocumentsFromReferenceApi(fileRef, 'ppd').then(doc => {
			dispatch(
				slice.actions._savePdfDocument({
					prescriptionId,
					doc: window.URL.createObjectURL(doc),
				}),
			)
		})
	}

const setIsDirty =
	(isDirty: boolean): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions._setIsDirty(isDirty))
	}

const setIsDemographicChanged =
	(isDemographicChanged: boolean): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions._setDemographicChanged(isDemographicChanged))
	}

const setHasErrors =
	(hasErrors: boolean): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions._setHasErrors(hasErrors))
	}

const setInitialFollowUpValues =
	(currentExamId: string, oldExamId: string): AppThunk =>
	(dispatch: TeloDispatch) =>
		getPrescriptionApi(oldExamId).then(rx => {
			if (rx?.data.ContactLenses) {
				dispatch(
					slice.actions._setFollowUpData({
						data: rx.data.ContactLenses,
						examId: currentExamId,
					}),
				)
			}
		})

const prescriptionsActions = {
	updatePrescription,
	createPrescription,
	fetchPrescription,
	saveDataTemp,
	updateClDataTemp,
	setInDisplay,
	clearPrescriptionDisplay,
	clearDataTemp: slice.actions.clearDataTemp,
	sendPrescriptionDocument,
	getPrescriptionBlob,
	setIsDirty,
	setHasErrors,
	setIsDemographicChanged,
	setInitialFollowUpValues,
}

export default prescriptionsActions
