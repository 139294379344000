import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import usersActions from '../features/users/actions'
import { formatName } from '../libs/localization'
import { pxToRem } from '../libs/style'
import { DeleteUserType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'

const DialogContentTextStyled = styled(DialogContentText)`
	min-width: ${pxToRem(299)}rem;
`

const isDeleteUserDialog = (dialog: any): dialog is DeleteUserType =>
	dialog.type === 'deleteUser'

const DeleteUserDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)

	return !isDeleteUserDialog(dialog) ? null : (
		<>
			<DialogContent>
				<DialogContentTextStyled>
					{t('admin.deleteUserQuestion').replace(
						'%%1',
						formatName(dialog.user),
					)}
				</DialogContentTextStyled>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					className={'confirm-delete-user-button'}
					onClick={() => {
						dispatch(usersActions.deleteUser(dialog.user)).finally(() =>
							dispatch(dialogActions.closeDialog()),
						)
					}}
					autoFocus
					variant="contained"
				>
					{t('app.yesDelete')}
				</Button>
			</DialogActions>
		</>
	)
}

export default DeleteUserDialog
