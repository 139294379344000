import React, { FC, useState } from 'react'

import {
	Box,
	Chip,
	FormControl,
	FormControlProps,
	FormHelperText,
	InputLabel,
	InputProps,
	ListSubheader,
	MenuItem,
	MenuProps,
	SelectChangeEvent,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import get from 'lodash/get'

import { pxToRem } from '../../libs/style'
import { FieldProps } from '../../model/types'
import UnconnectedSelect from '../Select'
import theme from '../theme'
import Checkbox from '../Checkbox'

import { InputHelperTextWithIcon } from './InputHelperTextWithIcon'
import CancelIcon from '../icons/CloseIcon'

const PREFIX = 'Select'

const classes = {
	root: `${PREFIX}-root`,
	fixedMenuListSmall: `${PREFIX}-fixedMenuListSmall`,
	ipadLanguageSelect: `${PREFIX}-ipadLanguageSelect`,
}

interface OptionsProps {
	value: string
	label: string
	disabled?: boolean
	group?: string
}

export interface SelectProps extends FormControlProps, FieldProps {
	anchorMenu?: boolean
	errorKey?: string
	fixedMenuListSmall: boolean
	inputProps: InputProps['inputProps']
	isLanguage?: boolean
	label: string
	multiple?: boolean
	showAsChips?: boolean
	checkmarks?: boolean
	selectAll?: boolean
	onChangeValue?(selectedValue: any): void
	options: OptionsProps[]
	dataTestId?: string
	helperText?: React.ReactNode
}

const StyledFormControl = styled(FormControl)<{ checkmarks?: boolean }>`
	.MuiFormControlLabel-root {
		${({ checkmarks }) => checkmarks && 'display: none;'}
	}

	.MuiFormLabel-root::first-letter {
		text-transform: uppercase;
	}
`

const ColorLabel = styled(InputLabel)`
	color: ${theme.palette.greyCustom.main};

	&.error {
		color: ${theme.palette.error.main};
	}

	&.Mui-disabled {
		color: ${theme.palette.greyCustom.main};
	}

	&:not(.MuiFormLabel-filled):not(.MuiInputLabel-shrink) {
		right: 3rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`

const MenuItemStyled = styled(MenuItem)`
	.MuiFormControlLabel-root {
		margin-left: 0;
	}
`

const MenuItemSelectAll = styled(MenuItemStyled)`
	margin-bottom: ${theme.spacing(2)};
`

const StyledStyledFormControl = styled(StyledFormControl)({
	[`& .${classes.root}`]: {
		maxHeight: `${1.5 * 10 + pxToRem(12 * 10)}em`,
	},
	[`& .${classes.fixedMenuListSmall}`]: {
		maxHeight: `${1.5 * 6 + pxToRem(12 * 7)}em`,
	},
	[`& .${classes.ipadLanguageSelect}`]: {
		position: 'absolute',
		top: '375px !important',
	},
})

export const StyledChip = styled(Chip)`
	margin-right: ${theme.spacing(2)};
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
`

const Select: FC<SelectProps> = ({
	form: { touched, errors, setFieldValue },
	anchorMenu = true,
	children,
	errorKey,
	field,
	fixedMenuListSmall = true,
	inputProps,
	isLanguage,
	label,
	multiple = false,
	showAsChips = false,
	checkmarks = false,
	selectAll = false,
	onChangeValue,
	options,
	dataTestId = 'select-ddm',
	helperText,
	...props
}) => {
	const { t } = useTranslation()
	// TODO: fix nested ternary
	const value = (
		!field.value
			? !multiple
				? ''
				: []
			: !multiple
			? field.value.toString()
			: field.value
	) as any
	const [multipleValues, setMultipleValues] = useState<string[]>(value)

	const menuProps = (
		anchorMenu: boolean,
		fixedMenuListSmall: boolean,
	): Partial<MenuProps> => {
		if (anchorMenu) {
			return {
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left',
				},
				transformOrigin: {
					vertical: 'top',
					horizontal: 'left',
				},
				sx: {
					...(fixedMenuListSmall && {
						maxHeight: `${2.5 * 6 + pxToRem(12 * 7)}em`,
					}),
				},
				classes: fixedMenuListSmall
					? { paper: classes.fixedMenuListSmall }
					: {},
				variant: fixedMenuListSmall ? 'menu' : 'selectedMenu',
			}
		} else {
			return {}
		}
	}

	const renderMenuList = () => {
		const optGrps = options.reduce((prev, acc) => {
			const group = acc.group ? acc.group : ''
			if (prev[group]) {
				prev[group].push(acc)
			} else {
				prev[group] = [acc]
			}
			return prev
		}, {} as { [k: string]: OptionsProps[] })

		return Object.keys(optGrps).map(group => {
			const isAllSelected =
				optGrps[group].length > 0 &&
				multipleValues.length === optGrps[group].length
			const selectAllItem = (
				<MenuItemSelectAll key="selectAll" value="selectAll">
					{checkmarks ? (
						<>
							<Checkbox marginRight="1" checked={isAllSelected} label="" />
							{t('forms.selectAll')}
						</>
					) : (
						t('forms.selectAll')
					)}
				</MenuItemSelectAll>
			)
			const items = optGrps[group].map(
				({ value, label, disabled = false }, id) => (
					<MenuItemStyled key={value || id} value={value} disabled={disabled}>
						{checkmarks ? (
							<>
								<Checkbox
									marginRight="1"
									checked={multipleValues.indexOf(value) > -1}
									label=""
									name={label}
								/>
								{label}
							</>
						) : (
							label
						)}
					</MenuItemStyled>
				),
			)

			const options = selectAll && multiple ? [selectAllItem, ...items] : items

			return [<ListSubheader key={group}>{group}</ListSubheader>, options]
		})
	}

	const fieldError = get(errors, field.name)
	const fieldTouched = get(touched, field.name)

	return (
		<StyledStyledFormControl
			variant="outlined"
			checkmarks={checkmarks}
			{...props}
		>
			<ColorLabel
				id={`${field.name}-label`}
				className={!!fieldTouched && !!fieldError ? 'error' : ''}
			>
				{label}
			</ColorLabel>
			<UnconnectedSelect
				data-testid={dataTestId}
				sx={props.sx}
				labelId={`${field.name}-label`}
				id={field.name}
				name={field.name}
				inputProps={inputProps}
				value={multiple ? multipleValues : value}
				onChange={(e: SelectChangeEvent<any>) => {
					let valueOnChange: any
					if (
						['string', 'number'].includes(typeof e.target.value) ||
						multiple === true
					) {
						valueOnChange = e.target.value
					} else {
						valueOnChange = ''
					}

					if (multiple) {
						if (
							selectAll &&
							valueOnChange[valueOnChange.length - 1] === 'selectAll'
						) {
							setMultipleValues(
								multipleValues.length === options.length
									? []
									: options.map(o => o.value),
							)
						} else {
							setMultipleValues(valueOnChange)
						}
					} else {
						setFieldValue(field.name, valueOnChange)
					}
					onChangeValue?.(valueOnChange)
				}}
				error={!!get(errors, field.name) && get(touched, field.name)}
				onBlur={field.onBlur}
				onClose={() => {
					if (multiple) {
						setFieldValue(field.name, multipleValues)
					}
				}}
				label={label}
				multiple={multiple}
				MenuProps={menuProps(anchorMenu, fixedMenuListSmall)}
				renderValue={
					showAsChips && multiple
						? values => (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{(values as string[]).map(id => {
										const item = options.find(i => i.value === id)
										return (
											<StyledChip
												key={`StyledChip-${id}`}
												label={item?.label}
												color={'secondary'}
												deleteIcon={
													<CancelIcon
														onMouseDown={event => event.stopPropagation()}
													/>
												}
												onDelete={e => {
													e.preventDefault()
													const _v: string[] = [...value]
													_v.splice(
														_v.findIndex(i => i === id),
														1,
													)
													setMultipleValues(_v)
													onChangeValue?.(_v)
												}}
											/>
										)
									})}
								</Box>
						  )
						: undefined
				}
			>
				{renderMenuList()}
			</UnconnectedSelect>
			{helperText
				? helperText
				: !!fieldTouched &&
				  !!fieldError && (
						<FormHelperText sx={{ position: 'static', mx: 0 }} error={true}>
							<InputHelperTextWithIcon>
								{t(errorKey || 'errors.fieldRequired')}
							</InputHelperTextWithIcon>
						</FormHelperText>
				  )}
		</StyledStyledFormControl>
	)
}

export default Select
