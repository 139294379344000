import React, { ForwardedRef, forwardRef } from 'react'
import styled from 'styled-components'

import MAlert, { AlertProps } from '@mui/material/Alert'
import { Color } from '@mui/material'
import {
	Report as ReportIcon,
	Warning as WarningIcon,
	CheckCircle as CheckCircleIcon,
	Info as InfoIcon,
} from '@mui/icons-material'

import theme from './theme'
import Button from './buttons/Button'

type AlertAction = {
	title: string
	onAction: () => void
}

type TeloAlertColor = 'neutral' | 'info' | 'success' | 'warning' | 'error'

type TeloAlertProps = Omit<AlertProps, 'severity'> & {
	severity: TeloAlertColor
	actions?: {
		primary?: AlertAction
		secondary?: AlertAction
	}
}

const TeloAlert = forwardRef(
	(
		{ children, severity, ...props }: TeloAlertProps,
		ref: ForwardedRef<HTMLDivElement>,
	) => {
		return (
			<MAlert
				{...props}
				severity={severity === 'neutral' ? 'info' : severity}
				ref={ref}
			>
				{children}
			</MAlert>
		)
	},
)

const AlertActions = styled.div`
	margin-top: ${theme.spacing(2)};
	& > button:last-child {
		margin-left: ${theme.spacing(2)};
	}
`

const StyledAlert = styled(TeloAlert)`
	background-color: ${props => {
		switch (props.severity as TeloAlertColor) {
			case 'neutral':
				return theme.palette.grey[200]
			case 'info':
				return (theme.palette.secondary as unknown as Color)[100]
			case 'success':
				return (theme.palette.success as unknown as Color)[100]
			case 'warning':
				return (theme.palette.warning as unknown as Color)[100]
			case 'error':
				return (theme.palette.error as unknown as Color)[100]
			default:
				return '-'
		}
	}};
	color: ${theme.palette.greyCustom.main};
	border-color: ${props => {
		switch (props.severity as TeloAlertColor) {
			case 'neutral':
				return theme.palette.grey[500]
			case 'info':
				return (theme.palette.secondary as unknown as Color)[500]
			case 'success':
				return (theme.palette.success as unknown as Color)[500]
			case 'warning':
				return (theme.palette.warning as unknown as Color)[500]
			case 'error':
				return (theme.palette.error as unknown as Color)[500]
			default:
				return '-'
		}
	}};

	.MuiAlertTitle-root {
		color: ${props => {
			switch (props.severity as TeloAlertColor) {
				case 'neutral':
				case 'info':
					return theme.palette.primary.main
				case 'success':
					return theme.palette.success.dark
				case 'warning':
					return theme.palette.warning.dark
				case 'error':
					return theme.palette.error.dark
				default:
					return '-'
			}
		}};
	}

	.MuiAlert-icon {
		color: ${props => {
			switch (props.severity as TeloAlertColor) {
				case 'neutral':
					return theme.palette.greyCustom.main
				case 'info':
					return theme.palette.secondary.main
				case 'success':
					return theme.palette.success.main
				case 'warning':
					return theme.palette.warning.main
				case 'error':
					return theme.palette.error.main
				default:
					return '-'
			}
		}};
	}

	.MuiAlert-message {
		padding: ${theme.spacing(1)} ${theme.spacing(0.5)};
		&::first-letter {
			text-transform: uppercase;
		}
	}
`
const Alert = forwardRef(
	(
		{ children, actions, severity, ...props }: TeloAlertProps,
		ref: ForwardedRef<HTMLDivElement>,
	) => {
		const icons = {
			info: <InfoIcon />,
			error: <ReportIcon />,
			warning: <WarningIcon />,
			success: <CheckCircleIcon />,
		}
		return (
			<StyledAlert
				iconMapping={icons}
				variant="outlined"
				severity={severity}
				{...props}
				ref={ref}
			>
				{children}
				{actions && (
					<AlertActions>
						{actions.primary && (
							<Button onClick={actions.primary.onAction}>
								{actions.primary.title}
							</Button>
						)}
						{actions.secondary && (
							<Button
								variant="text"
								underlined
								onClick={actions.secondary.onAction}
							>
								{actions.secondary.title}
							</Button>
						)}
					</AlertActions>
				)}
			</StyledAlert>
		)
	},
)

export default Alert
