import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CockpitContent } from '../../model/cockpit'

import { ComponentName, Id, UIState } from '../../model/model'
import { CommunicationProviderTypes } from '../../model/store'
import { InstrumentInRoom } from '../../model/instruments'
import { ExamStep } from '../../model/exam'

const initialState: UIState = {
	doctorExamPageActiveStep: ExamStep.Summary,
	controlPanelActiveStep: 0,
	isComponentEditing: {},
	showCallWidget: false,
	overrideCallWidget: null,
	clientAppOpened: false,
	currentChatInfo: {
		examId: '',
		internalPatientId: '',
	},
	consultationSelectedStep: 0,
	consultationSelectedStepId: '',
	prescriptionAssets: {
		glasses: undefined,
	},
	diseaseAndTreatmentAsset: undefined,
	selectedPrescriptionAsset: undefined,
	openPrimaryChiefCompCategory: false,
	showDoctorExamViewFooter: true,
	notistackKeysTriggers: {},
}

export const slice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setDoctorExamPageActiveStep: (
			state,
			{ payload }: PayloadAction<ExamStep>,
		) => {
			state.doctorExamPageActiveStep = payload
		},
		setDoctorExamPageActivePanel: (
			state,
			{ payload: panel }: PayloadAction<InstrumentInRoom>,
		) => {
			state.doctorExamPageActivePanel = panel
		},
		setControlPanelActiveStep: (state, { payload }: PayloadAction<number>) => {
			state.controlPanelActiveStep = payload
		},
		setComponentEditing: (
			state,
			{
				payload,
			}: PayloadAction<{ component: ComponentName; isEditing: boolean }>,
		) => {
			state.isComponentEditing[payload.component] = payload.isEditing
		},
		clearEditing: (state, { payload }: PayloadAction<void>) => {
			state.isComponentEditing = {}
		},
		setShowCallWidget: (state, { payload }: PayloadAction<boolean>) => {
			// when client app is opened (by now webex is the only desktop app we use), call widget shouldn't be opened
			state.showCallWidget = state.clientAppOpened === true ? false : payload
			if (!payload) {
				state.overrideCallWidget = null
				delete state.currentCalling
			}
		},
		setClientAppOpened: (state, { payload }: PayloadAction<boolean>) => {
			state.clientAppOpened = payload
		},
		setOverrideCallWidget: (
			state,
			{ payload }: PayloadAction<CommunicationProviderTypes | null>,
		) => {
			state.overrideCallWidget = payload
		},
		setCurrentChatInfo: (
			state,
			{ payload }: PayloadAction<{ examId: Id; internalPatientId: Id }>,
		) => {
			state.currentChatInfo = payload
		},
		clearCurrentChatInfo: (state, { payload }: PayloadAction<void>) => {
			state.currentChatInfo = initialState.currentChatInfo
		},
		setIsCallActive: (
			state,
			{ payload }: PayloadAction<{ visibility: boolean; exam?: string }>,
		) => {
			if (payload.visibility) state.currentCalling = payload.exam
			else delete state.currentCalling
		},
		setConsultationSelectedStep: (
			state,
			{ payload }: PayloadAction<number>,
		) => {
			state.consultationSelectedStep = payload
		},
		setConsultationSelectedStepId: (
			state,
			{ payload }: PayloadAction<string>,
		) => {
			state.consultationSelectedStepId = payload
		},
		setPrescriptionAssets: (
			state,
			{ payload }: PayloadAction<{ glasses?: CockpitContent }>,
		) => {
			state.prescriptionAssets = payload
		},
		setSelectedPrescritpionAsset: (
			state,
			{ payload }: PayloadAction<string>,
		) => {
			state.selectedPrescriptionAsset = payload
		},
		setDiseaseAndTreatmentAssets: (
			state,
			{ payload }: PayloadAction<CockpitContent | undefined>,
		) => {
			state.diseaseAndTreatmentAsset = payload
		},
		setOpenPrimaryChiefCompCategory: (
			state,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.openPrimaryChiefCompCategory = payload
		},
		setShowDoctorExamViewFooter: (
			state,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.showDoctorExamViewFooter = payload
		},
	},
})

export default slice.reducer
