import React from 'react'

import { Grid as MGrid, GridProps } from '@mui/material'

const Grid: React.FC<GridProps & { isFlex?: boolean; justify?: string }> = ({
	children,
	isFlex,
	justify,
	...props
}) => {
	const style = {
		...(isFlex ? { display: 'flex', justify: justify || 'flex-start' } : {}),
	}

	return (
		<MGrid spacing={props.container ? 3 : undefined} {...props} style={style}>
			{children}
		</MGrid>
	)
}

export default Grid
