import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id, PrescriptionsState } from '../../model/model'
import {
	PrescriptionDataTempPayload,
	Prescription,
	PrescriptionFollowUpDataPayload,
} from '../../model/prescription'

const initialState: PrescriptionsState = {
	list: {},
	dataTemp: undefined,
	inShare: undefined,
	isDirty: false,
	hasErrors: true,
	demographicChanged: false,
	followUpData: undefined,
}

export const slice = createSlice({
	name: 'prescriptions',
	initialState,
	reducers: {
		_loadPrescription: (state, { payload }: PayloadAction<Prescription>) => {
			const oldPrescription = state.list[payload._id]
			state.list[payload._id] = {
				...payload,
				blobDocumentUrl: oldPrescription?.blobDocumentUrl,
			}
		},
		_loadDataTemp: (
			state,
			{ payload }: PayloadAction<PrescriptionDataTempPayload>,
		) => {
			if (state.dataTemp) {
				state.dataTemp[payload.id] = payload.data
			} else {
				state.dataTemp = { [payload.id]: payload.data }
			}
		},
		clearDataTemp: state => {
			state.dataTemp = undefined
		},
		setInShare: (state, { payload }: PayloadAction<string | undefined>) => {
			state.inShare = payload
		},
		_setIsDirty: (state, { payload }: PayloadAction<boolean>) => {
			state.isDirty = payload
		},
		_setHasErrors: (state, { payload }: PayloadAction<boolean>) => {
			state.hasErrors = payload
		},
		_savePdfDocument: (
			state,
			{ payload }: PayloadAction<{ prescriptionId: Id; doc: string }>,
		) => {
			const { prescriptionId, doc } = payload
			state.list[prescriptionId] = {
				...state.list[prescriptionId],
				blobDocumentUrl: doc,
			}
		},
		_setDemographicChanged: (state, { payload }: PayloadAction<boolean>) => {
			state.demographicChanged = payload
		},
		_setFollowUpData: (
			state,
			{ payload }: PayloadAction<PrescriptionFollowUpDataPayload>,
		) => {
			if (state.followUpData) {
				state.followUpData[payload.examId] = payload.data
			} else {
				state.followUpData = { [payload.examId]: payload.data }
			}
		},
	},
})

export default slice.reducer
