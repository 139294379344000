import { AddNotificationPayload } from '../../notifications/actions'
import { Notification } from '../../../model/model'

const NOTIFICATION_ID = 'realtimeConnectionError'
const MAX_DONWTIME_MS = 20000
const TIMEOUT_ERROR_NOTIFICATION: AddNotificationPayload = {
	id: NOTIFICATION_ID,
	type: 'error',
	message: 'errors.realtimeConnectionError',
	messageIsLabelKey: true,
	autoClose: false,
}

let connectionTimeoutHandle = 0

type OnConnectErrorArgs = {
	findNotification: (notificationId: string) => Notification | null
	addNotification: (notification: AddNotificationPayload) => void
	isSocketConnected: () => boolean
}

export const onConnectError = ({
	findNotification,
	addNotification,
	isSocketConnected,
}: OnConnectErrorArgs): void => {
	const errorNotificationPresent = findNotification(NOTIFICATION_ID)
	if (!connectionTimeoutHandle && !errorNotificationPresent) {
		connectionTimeoutHandle = window.setTimeout(() => {
			if (!isSocketConnected()) {
				addNotification(TIMEOUT_ERROR_NOTIFICATION)
			}
			connectionTimeoutHandle = 0
		}, MAX_DONWTIME_MS)
	}
}

export const onConnect = (
	removeNotification: (notificationId: string) => void,
): void => {
	clearTimeout(connectionTimeoutHandle)
	removeNotification(NOTIFICATION_ID)
	connectionTimeoutHandle = 0
}
