import React from 'react'

import AbortDialog from '../../components/AbortDialog'
import ChangeRoomDialog from '../../components/ChangeRoomDialog'
import ChangeStatusWarningDialog from '../../components/ChangeStatusWarningDialog'
import DeleteDocumentDialog from '../../components/DeleteDocumentDialog'
import DeleteRoomDialog from '../../components/DeleteRoomDialog'
import DeleteStageDialog from '../../components/DeleteStageDialog'
import DeleteStoreDialog from '../../components/DeleteStoreDialog'
import DeleteUserDialog from '../../components/DeleteUserDialog'
import DeleteAppointmentDialog from '../../components/DeleteAppointmentDialog'
import LogoutDialog from '../../components/LogoutDialog'
import PrivacyPolicyDocumentDialog from '../../components/PrivacyPolicyDocumentDialog'
import QuitWithPrescribedDialog from '../../components/QuitWithPrescriptionDialog'
import QuitWithTrialDialog from '../../components/QuitWithTrialDialog'
import RemoveInstrumentDialog from '../../components/RemoveInstrumentDialog'
import TestExamDialog from '../../components/TestExamDialog'
import UncompletePrescriptionDialog from '../../components/UncompletePrescriptionDialog'
import UnlockDialog from '../../components/UnlockDialog'
import VisualSimulationShareDialog from '../../components/VisualSimulation/VisualSimulationShareDialog'
import VisualSimulationViewerDialog from '../../components/VisualSimulation/VisualSimulationViewerDialog'
import Dialog from '../../styleguide/dialog/Dialog'
import dialogActions from './actions'
import { selectDialog, selectDialogType } from './selectors'
import { useTeloSelector, useTeloDispatch } from '../../store'
import LoadingDialog from '../../components/LoadingDialog'
import DoctorEndsWithoutRxModal from '../../components/DoctorEndsWithoutRxModal'
import DialogTitle from '../../styleguide/dialog/DialogTitle'
import FirstLetterCapital from '../../styleguide/typography/FirstLetterCapital'
import { useTranslation } from 'react-i18next'
import storesActions from '../stores/actions'

const DialogBox = () => {
	const dialogType = useTeloSelector(selectDialogType)
	const dialog = useTeloSelector(selectDialog)
	const dispatch = useTeloDispatch()
	const { t } = useTranslation()

	return (
		<Dialog
			open={dialogType !== 'none'}
			onClose={() => dispatch(dialogActions.closeDialog())}
		>
			<DialogTitle
				onClose={() => dispatch(dialogActions.closeDialog())}
			></DialogTitle>
			{dialogType === 'abort' && <AbortDialog />}
			{dialogType === 'changeRoom' && <ChangeRoomDialog />}
			{dialogType === 'changeStatusWarningDialog' && (
				<ChangeStatusWarningDialog />
			)}
			{dialogType === 'deleteAppointment' && <DeleteAppointmentDialog />}
			{dialogType === 'deleteDocument' && <DeleteDocumentDialog />}
			{dialogType === 'deleteRoom' && <DeleteRoomDialog />}
			{dialogType === 'deleteStage' && <DeleteStageDialog />}
			{dialogType === 'deleteStore' && <DeleteStoreDialog />}
			{dialogType === 'deleteUser' && <DeleteUserDialog />}
			{dialogType === 'logout' && <LogoutDialog />}
			{dialogType === 'privacyPolicyDocument' && (
				<PrivacyPolicyDocumentDialog />
			)}
			{dialogType === 'quitWithPrescribed' && <QuitWithPrescribedDialog />}
			{dialogType === 'quitWithTrial' && <QuitWithTrialDialog />}
			{dialogType === 'doctorEndsWithoutRx' && <DoctorEndsWithoutRxModal />}
			{dialogType === 'removeInstrument' && <RemoveInstrumentDialog />}
			{dialogType === 'testExamDialog' && <TestExamDialog />}
			{dialogType === 'uncompletedPrescription' && (
				<UncompletePrescriptionDialog />
			)}
			{dialogType === 'unlockDialog' && <UnlockDialog />}
			{dialogType === 'visualSimulationShare' && (
				<VisualSimulationShareDialog />
			)}
			{dialogType === 'visualSimulationViewer' && (
				<VisualSimulationViewerDialog />
			)}
			{dialog.type === 'loading' && <LoadingDialog message={dialog.message} />}
		</Dialog>
	)
}

export default DialogBox
