import React from 'react'
import styled, {
	FlattenSimpleInterpolation,
	css,
} from 'styled-components/macro'
import { pxToRem } from '../libs/style'
import { CircleLoader } from './Orders/styles'

const Wrapper = styled.div<{
	customCss?: FlattenSimpleInterpolation
	isFullPage?: boolean
}>`
	background-color: white;
	padding: ${pxToRem(24)}rem;

	${({ isFullPage }) =>
		isFullPage &&
		css`
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: ${Number.MAX_SAFE_INTEGER};
			background-color: rgba(255, 255, 255, 0.5);
			display: flex;
			align-items: center;
			justify-content: center;
		`}

	${({ customCss }) => customCss}
`

const Message = styled.p`
	font-family: Avenir;
	font-size: ${pxToRem(16)}rem;
	font-weight: 500;
	line-height: ${pxToRem(24)}rem;
	letter-spacing: 0.2666666805744171px;
	color: #1f2327;
`

const LoaderWrapper = styled.div<{ usePadding: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;

	${({ usePadding }) =>
		usePadding &&
		css`
			padding: ${pxToRem(30)}rem 0 ${pxToRem(10)}rem;
		`}
`

const StyledLoader = styled(CircleLoader)`
	width: ${pxToRem(75)}rem;
	height: ${pxToRem(75)}rem;
	border: 7.5px solid #e9effc;
	border-top: 7.5px solid #3664e6;
`

type Props = {
	message?: string
	customCss?: FlattenSimpleInterpolation
	isFullPage?: boolean
}

const LoadingDialog: React.FC<Props> = ({ message, customCss, isFullPage }) => {
	return (
		<Wrapper customCss={customCss} isFullPage={isFullPage}>
			{message && (
				<Message className="loading-dialog-message">{message}</Message>
			)}
			<LoaderWrapper
				className="loading-dialog-loader-wrapper"
				usePadding={!!message}
			>
				<StyledLoader className="loading-dialog-loader" />
			</LoaderWrapper>
		</Wrapper>
	)
}

export default LoadingDialog
