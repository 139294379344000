import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	createSessionId,
	getIntersessionInStore,
	getIntersessionOttMode,
	getSessionId,
	setIntersessionInStore,
	setIntersessionOttMode,
	setIntersessionRoomDevice,
} from '../../libs/intersession'
import { getStoreIdFromUrl } from '../../libs/url'
import { checkStoreIdFormat } from '../../libs/utils'
import { AppState, MediaGrants } from '../../model/model'
import { getIntersessionRoomDevice } from './../../libs/intersession'

const initialState: AppState = {
	lang: 'en-US',
	storeId: getStoreIdFromUrl() || '',
	practiceExternalId: '',
	inStore: getIntersessionInStore(),
	mediaGranted: 'unknown',
	sessionId: getSessionId() || createSessionId(),
	online: true,
	roomDeviceAvailable: getIntersessionRoomDevice(),
	ottMode: getIntersessionOttMode(),
	ottIndex: 0,
	runOTT: false,
	isAppGuideActive: true,
	ottStepsSelected: [],
	newCropStatusCount: 0,
	queueAvailable: true,
}

export const slice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setLang: (state, { payload }: PayloadAction<string>) => {
			state.lang = payload
		},
		setStoreId: (state, { payload }: PayloadAction<string>) => {
			checkStoreIdFormat(payload, 'setStoreId')
			state.storeId = payload
		},
		setPracticeExternalId: (state, { payload }: PayloadAction<string>) => {
			state.practiceExternalId = payload
		},
		setInStore: (state, { payload }: PayloadAction<boolean>) => {
			setIntersessionInStore(payload)
			state.inStore = payload
		},
		setMediaGrants: (state, { payload }: PayloadAction<MediaGrants>) => {
			state.mediaGranted = payload
		},
		setOnline: (state, { payload }: PayloadAction<boolean>) => {
			state.online = payload
		},
		setRoomDeviceAvailable: (state, { payload }: PayloadAction<boolean>) => {
			setIntersessionRoomDevice(payload)
			state.roomDeviceAvailable = payload
		},
		setOttMode: (state, { payload }: PayloadAction<boolean>) => {
			setIntersessionOttMode(payload)
			state.ottMode = payload
		},
		setOttIndex: (state, { payload }: PayloadAction<number>) => {
			state.ottIndex = payload
		},
		setRunOTT: (state, { payload }: PayloadAction<boolean>) => {
			state.runOTT = payload
		},
		setIsAppGuideActive: (state, { payload }: PayloadAction<boolean>) => {
			state.isAppGuideActive = payload
		},
		addOTTSelectedStep: (state, { payload }: PayloadAction<number>) => {
			if (!state.ottStepsSelected.includes(payload)) {
				state.ottStepsSelected = state.ottStepsSelected.concat([payload])
			}
		},
		resetOttStepsSelected: state => {
			state.ottStepsSelected = []
		},
		setNewCropStatusCount: (state, { payload }: PayloadAction<number>) => {
			state.newCropStatusCount = isNaN(payload) ? 0 : payload
		},
		setQueueAvailable: (state, { payload }: PayloadAction<boolean>) => {
			state.queueAvailable = payload
		},
	},
})

export default slice.reducer
