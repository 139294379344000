import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import routerActions from './actions'

export const useSyncParamsToRedux = () => {
	const dispatch = useDispatch()
	// eslint-disable-next-line telo/no-use-params
	const params = useParams()

	/*
		Wrapping this dispatch in a useEffect, will execute the action AFTER rendering,
		but params are requried DURING rendering.
	*/

	dispatch(routerActions.setParams(params))
}
